import { AlchemyNFT } from '@swarm/types/tokens'
import { GetNftsForOwnerOptions, OwnedNftsResponse } from 'alchemy-sdk'
import { useCallback, useMemo } from 'react'

import useRequest from '@core/hooks/async/useRequest'
import { updateBalanceKey } from '@core/observables/watcher'
import AlchemyAPI from '@core/services/alchemy'
import { TokenType } from '@core/shared/enums'
import { big } from '@core/shared/utils/helpers'
import { isGoldByAddress } from '@core/shared/utils/tokens/gold'
import { unifyAddressToId, useReadyState } from '@core/web3'

interface UseNftsForOwnerOptions extends GetNftsForOwnerOptions {
  account?: string
}

const useNftsForOwner = (options: UseNftsForOwnerOptions) => {
  const ready = useReadyState()

  const { account, ...getNftsOptions } = options

  const { data, refetch, loading, error } = useRequest<
    (
      account?: string,
      getNftsOptions?: GetNftsForOwnerOptions,
    ) => Promise<OwnedNftsResponse | null>
  >(
    useCallback(async (_account, _getNftsOptions) => {
      if (!_account) return null
      return AlchemyAPI.getNftsForOwner(_account, _getNftsOptions)
    }, []),
    [account, getNftsOptions],
  )

  const nfts = useMemo<AlchemyNFT[]>(() => {
    if (!data) return []
    const filteredNFTs = data.ownedNfts.filter((nft) => {
      return isGoldByAddress(nft.contract.address) || !AlchemyAPI.isSpam(nft)
    })

    return filteredNFTs.map((nft) => {
      const type = ['ERC721', 'ERC1155'].includes(nft.tokenType)
        ? (nft.tokenType as unknown as TokenType)
        : TokenType.noType
      const contractAddress = unifyAddressToId(nft.contract.address)
      const tokenId = nft.tokenId
      const balance = big(nft.balance)
      if (account) {
        updateBalanceKey({
          account,
          address: contractAddress,
          tokenId,
          value: balance,
        })
      }

      return {
        id: contractAddress.concat('-').concat(tokenId),
        address: contractAddress,
        tokenId: tokenId,
        name: nft.name,
        symbol: nft.contract.symbol,
        type,
        logo: nft.image.originalUrl,
        tokenUri: nft.tokenUri,
        balance: balance,
      } as AlchemyNFT
    })
  }, [account, data])

  return {
    nfts,
    totalCount: data?.totalCount,
    validAt: data?.validAt,
    pageKey: data?.pageKey,
    reloadNfts: refetch,
    loading: !ready || loading,
    error,
  }
}

export default useNftsForOwner
