import { ExpandLess, ExpandMore } from '@rimble/icons'
import { useToggle } from '@swarm/core/hooks/state/useToggle'
import {
  prettifyBalance,
  truncateStringInTheMiddle,
} from '@swarm/core/shared/utils'
import { NormalizedXOffer } from '@swarm/types/normalized-entities/x-offer'
import Grid from '@swarm/ui/presentational/Grid'
import ExplorerLink from '@swarm/ui/swarm/ExplorerLink'
import { BigSource } from 'big.js'
import { useTranslation } from 'react-i18next'
import { Box, Button, Text } from 'rimble-ui'

interface TakeOfferDetailsProps {
  ordersDetails: {
    offer: NormalizedXOffer
    amountPaid: BigSource
    amountIn: BigSource
  }[]
  hideExpandButton?: boolean
}

const TakeOfferDetails = ({
  ordersDetails,
  hideExpandButton,
}: TakeOfferDetailsProps) => {
  const { isOn, toggle } = useToggle(hideExpandButton)
  const { t } = useTranslation('otc')

  return (
    <Box>
      {!hideExpandButton && (
        <Button.Text
          p={0}
          fontSize="15px"
          fontWeight="400"
          onClick={toggle}
          color="primary"
        >
          <Text.span lineHeight="22px">
            {t('details.takeOffer.title')}{' '}
          </Text.span>
          {isOn ? <ExpandLess /> : <ExpandMore />}
        </Button.Text>
      )}
      {isOn && (
        <Grid
          flexDirection="column"
          gridTemplateColumns="repeat(auto-fill, minmax(100px, 1fr))"
        >
          {/* Header: */}
          <Text color="near-black" fontWeight={5}>
            {t('details.takeOffer.amount')}
          </Text>
          <Text color="near-black" fontWeight={5} textAlign="center">
            {t('details.takeOffer.price')}
          </Text>
          <Text color="near-black" fontWeight={5} textAlign="center">
            {t('details.takeOffer.total')}
          </Text>
          <Text color="near-black" fontWeight={5} textAlign="right">
            {t('details.takeOffer.maker')}
          </Text>
        </Grid>
      )}
      {isOn &&
        ordersDetails.map((order) => {
          const { amountIn, amountPaid } = order
          const { depositAsset, withdrawalAsset, maker, price, id } =
            order.offer

          return (
            <Grid
              key={id}
              flexDirection="column"
              gridTemplateColumns="repeat(auto-fill, minmax(100px, 1fr))"
              alignItems="center"
              mt={1}
            >
              <Text color="near-black">
                {`${prettifyBalance(amountIn)} ${depositAsset.symbol}`}
              </Text>
              <Text color="near-black" textAlign="center">
                {`${prettifyBalance(price)} ${withdrawalAsset.symbol}`}
              </Text>
              <Text color="near-black" textAlign="center">
                {`${prettifyBalance(amountPaid)} ${withdrawalAsset.symbol}`}
              </Text>
              <ExplorerLink
                type="address"
                hash={maker}
                label={truncateStringInTheMiddle(maker)}
              />
            </Grid>
          )
        })}
    </Box>
  )
}

export default TakeOfferDetails
