import useAsyncState from '@swarm/core/hooks/async/useAsyncState'
import useTransactionAlerts from '@swarm/core/hooks/i18n/useTransactionAlerts'
import { useSnackbar } from '@swarm/ui/swarm/Snackbar'
import { BigSource } from 'big.js'
import { useCallback } from 'react'

import { XDotcContract, XDotcContractToken } from 'src/contracts/XDotcContract'

type UseTakeOfferState = {
  txLoading: boolean
}

type TakeOfferFunction = (
  amountPaid: BigSource,
  tokenOut: XDotcContractToken,
) => Promise<void>

type UseTakeOfferTokenReturn = [TakeOfferFunction, UseTakeOfferState]

const useTakeOffer = (offerId: string): UseTakeOfferTokenReturn => {
  const { track } = useTransactionAlerts()
  const { addError } = useSnackbar()
  const [txLoading, setTxLoading] = useAsyncState(false)

  const takeOffer: TakeOfferFunction = useCallback(
    async (amountPaid, tokenOut) => {
      setTxLoading(true)
      try {
        const tx = await XDotcContract.takeOffer(offerId, amountPaid, tokenOut)

        await track(tx, {
          confirm: {
            secondaryMessage: 'Refresh shortly to see the latest offers.',
          },
        })
      } catch (e) {
        addError(e as Error)
      } finally {
        setTxLoading(false)
      }
    },
    [setTxLoading, offerId, track, addError],
  )

  return [takeOffer, { txLoading }]
}

export default useTakeOffer
