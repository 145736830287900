import { ApolloQueryResult, QueryHookOptions, useQuery } from '@apollo/client'
import {
  UniswapTokensFilterQuery,
  UniswapTokenSubgraph,
} from '@swarm/types/subgraph-responses/uniswap'
import { UniswapToken } from '@swarm/types/tokens'
import { useCallback, useMemo } from 'react'

import { getCurrentConfig } from '@core/observables/configForNetwork'
import { UniswapTokensQuery } from '@core/queries'
import client from '@core/services/apollo/uniswap'
import { TokenType } from '@core/shared/enums'
import { isBase, mapBy } from '@core/shared/utils'
import getTokenLogoUrl from '@core/shared/utils/tokens/getTokenLogoUrl'
import { getLastUsedNetworkId } from '@swarm/core/web3'

interface UniswapTokensResponse {
  tokens: UniswapTokenSubgraph[]
}

interface UniswapTokensQueryVariables {
  filter?: UniswapTokensFilterQuery
  orderBy?: string
  orderDirection?: 'asc' | 'desc'
}

export type RefetchUniswapTokensFn = (
  filter?: Partial<UniswapTokensFilterQuery>,
) => Promise<ApolloQueryResult<UniswapTokensResponse>>

const config = getCurrentConfig()

const useUniswapTokens = (
  options?: QueryHookOptions<
    UniswapTokensResponse,
    UniswapTokensQueryVariables
  >,
) => {
  const {
    data,
    error,
    loading,
    refetch: nativeRefetch,
  } = useQuery<UniswapTokensResponse, UniswapTokensQueryVariables>(
    UniswapTokensQuery,
    {
      ...options,
      client,
      skip:
        options?.skip ||
        // added isBase because the Uniswap subgraph doesn't work with the Base network
        isBase(getLastUsedNetworkId()) ||
        !config.uniswapSubgraphUrl,
    },
  )

  const refetch = useCallback<RefetchUniswapTokensFn>(
    (filter: Partial<UniswapTokensFilterQuery> = {}) =>
      nativeRefetch({
        filter,
      }),
    [nativeRefetch],
  )

  const tokens = useMemo<UniswapToken[]>(
    () =>
      data?.tokens.map((token) => ({
        id: token.id,
        name: token.name,
        symbol: token.symbol,
        decimals: Number(token.decimals),
        logo: getTokenLogoUrl(token.id),
        type: TokenType.erc20,
      })) || [],
    [data?.tokens],
  )

  const tokensDictionary: Map<string, UniswapToken> = useMemo(
    () => mapBy(tokens, 'id'),
    [tokens],
  )

  return useMemo(
    () => ({
      tokens,
      tokensDictionary,
      loading,
      error,
      refetch,
    }),
    [error, loading, refetch, tokens, tokensDictionary],
  )
}

export default useUniswapTokens
