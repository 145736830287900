import { isBond, isIndexToken, isStockToken } from '@swarm/core/shared/utils'
import { InvestAssetType } from '@swarm/types/tokens/invest'
import { useTranslation } from 'react-i18next'

const useCardText = (tokenType: InvestAssetType) => {
  const { t } = useTranslation('invest')

  const assetType = { assetType: tokenType }

  if (isStockToken(assetType)) {
    return {
      currentPrice: t('stockTokens.currentStockPrice'),
      description: t('stockTokens.description'),
    }
  }

  if (isBond(assetType)) {
    return {
      currentPrice: t('bonds.currentBondPrice'),
      description: t('bonds.description'),
    }
  }

  if (isIndexToken(assetType)) {
    return {
      currentPrice: t('indexTokens.currentIndexPrice'),
      description: t('indexTokens.description'),
    }
  }

  return {
    currentPrice: '',
    description: '',
  }
}

export default useCardText
