import { useToggle } from '@swarm/core/hooks/state/useToggle'
import useXDotcAssets from '@swarm/core/hooks/subgraph/x-dotc/useXDotcAssets'
import { ChildrenProps } from '@swarm/types'
import { DotcAsset } from '@swarm/types/tokens/dotc'
import { DotcAsset as DotcAssetSubgraph } from '@swarm/types/x-subgraph/graphql'
import TokenInfoPopup from '@swarm/ui/swarm/Popups/TokenInfoPopup'
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

interface DotcContextType {
  tokens: DotcAsset[]
  tokensDictionary: Map<string, DotcAsset>
  tokensLoading: boolean
  selectedTokenInfo: DotcAssetSubgraph | null
  openGoldNftInfo: (token: DotcAssetSubgraph) => void
  closeGoldNftInfo: () => void
  isTokenInfoOpen: boolean
}

export const DotcContext = createContext<DotcContextType>({
  tokens: [],
  tokensDictionary: new Map(),
  tokensLoading: true,
  selectedTokenInfo: null,
  openGoldNftInfo: () => {},
  closeGoldNftInfo: () => {},
  isTokenInfoOpen: false,
})

export const DotcContextProvider = ({ children }: ChildrenProps) => {
  const { tokens, tokensDictionary, loading: tokensLoading } = useXDotcAssets()
  const {
    isOn: isTokenInfoOpen,
    off: closeGoldNftInfoPopup,
    on: openGoldNftInfoPopup,
  } = useToggle()
  const [selectedTokenInfo, setSelectedTokenInfo] =
    useState<DotcAssetSubgraph | null>(null)

  const openGoldNftInfo = useCallback(
    (token: DotcAssetSubgraph) => {
      setSelectedTokenInfo(token)
      openGoldNftInfoPopup()
    },
    [openGoldNftInfoPopup],
  )

  const closeGoldNftInfo = useCallback(() => {
    setSelectedTokenInfo(null)
    closeGoldNftInfoPopup()
  }, [closeGoldNftInfoPopup])

  const value = useMemo<DotcContextType>(
    () => ({
      tokens,
      tokensLoading,
      tokensDictionary,
      selectedTokenInfo,
      openGoldNftInfo,
      closeGoldNftInfo,
      isTokenInfoOpen,
    }),
    [
      tokens,
      tokensLoading,
      tokensDictionary,
      selectedTokenInfo,
      openGoldNftInfo,
      closeGoldNftInfo,
      isTokenInfoOpen,
    ],
  )

  return (
    <>
      <DotcContext.Provider value={value}>{children}</DotcContext.Provider>
      {selectedTokenInfo && (
        <TokenInfoPopup
          token={{
            id: selectedTokenInfo.id,
            address: selectedTokenInfo.address,
            tokenId: selectedTokenInfo.tokenId?.toString() ?? undefined,
            name: selectedTokenInfo.name,
            symbol: selectedTokenInfo.symbol,
          }}
          kya={selectedTokenInfo.asset?.kya}
          isOpen={isTokenInfoOpen}
          onClose={closeGoldNftInfo}
        />
      )}
    </>
  )
}

export const useDotcContext = () => useContext(DotcContext)
