import { AlchemyToken } from '@swarm/types/tokens'
import { isAddress } from 'ethers/lib/utils'
import { useMemo } from 'react'

import config from '@core/config'
import useAsyncMemo from '@core/hooks/async/useAsyncMemo'
import AlchemyAPI from '@core/services/alchemy'
import { TokenType } from '@core/shared/enums'
import {
  injectTokenBalance,
  isErc1155,
  useInjections,
} from '@core/shared/utils/tokens'
import { unifyAddressToId, useAccount } from '@core/web3'

const {
  resources: { iconsCdn: iconsBaseUrl },
} = config

const genericIconSrc = `${iconsBaseUrl}/svg/color/generic.svg`

interface UseTokenMetadataOptions {
  type?: TokenType
}

const useTokenMetadata = (
  address?: string,
  options?: UseTokenMetadataOptions,
) => {
  const account = useAccount()

  const [token, { loading, reload: refetch, error }] = useAsyncMemo(
    async () => {
      if (!address || !isAddress(address)) return null
      return AlchemyAPI.getTokenMetadata(address)
    },
    null,
    [address],
  )

  const extendedToken = useMemo(() => {
    if (token === undefined || token === null || loading || !address) {
      return null
    }

    if (isErc1155(options?.type)) {
      return {
        id: unifyAddressToId(address),
        ...token,
        type: TokenType.erc1155,
      } as AlchemyToken
    }
    return {
      id: unifyAddressToId(address),
      ...token,
      logo: token?.logo || genericIconSrc,
      type: TokenType.erc20,
    } as AlchemyToken
  }, [address, loading, options?.type, token])

  const injectedToken = useInjections<AlchemyToken>(
    extendedToken ? [extendedToken] : [],
    useMemo(
      () => (!isErc1155(options?.type) ? [injectTokenBalance(account)] : []),
      [account, options?.type],
    ),
  )

  return {
    token: injectedToken[0],
    loading,
    refetch,
    error,
  }
}

export default useTokenMetadata
