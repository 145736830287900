import { ArrowForward, SwapHoriz } from '@rimble/icons'
import { getCurrentConfig } from '@swarm/core/observables/configForNetwork'
import { isSameEthereumAddress, useAccount } from '@swarm/core/web3'
import { DotcAsset } from '@swarm/types/tokens/dotc'
import AngledSwitch from '@swarm/ui/presentational/AngledSwitch'
import InfiniteTable from '@swarm/ui/presentational/InfiniteTable'
import ExplorerLink from '@swarm/ui/swarm/ExplorerLink'
import isNil from 'lodash/isNil'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Flex, Text } from 'rimble-ui'
import styled from 'styled-components/macro'
import {
  BooleanParam,
  QueryParamConfig,
  StringParam,
  useQueryParams,
} from 'use-query-params'

import useMyOffers from 'src/hooks/data/useMyOffers'

import { useCreateOfferPopup } from '../CreateOffer'
import { useDotcContext } from '../DotcContext'
import { EditOfferPopupProvider } from '../EditOffer'

import MyOfferRow from './MyOfferRow'
import TokenSelect from './StyledSelect'

const StyledInfiniteTable = styled(InfiniteTable)`
  col {
    width: 130px;
  }
  th {
    &:nth-child(3) {
      font-weight: bold;
      color: black;
    }
  }
  th,
  td {
    &:nth-child(1) {
      text-align: left;
    }
    &:nth-child(3) {
      font-weight: bold;
      background-color: #e8f2fc;
    }
    text-align: center;
  }
  td {
    color: ${(props) => props.theme.colors['near-black']};
  }

  colgroup.actionIcons {
    col {
      width: 30px !important;
    }
  }
`

type QueryStringValues = {
  tokenIn: QueryParamConfig<string | null | undefined>
  tokenOut: QueryParamConfig<string | null | undefined>
  inactive: QueryParamConfig<boolean | null | undefined>
}

const MyOffersTab = () => {
  const { openCreateOfferPopup } = useCreateOfferPopup()

  const { t } = useTranslation('otc')
  const { xDotcAddress } = getCurrentConfig()
  const [isDirectMode, setIsDirectMode] = useState<boolean>(false)
  const [hideInactiveOffers, setHideInactiveOffers] = useState(true)

  const account = useAccount()

  const { tokens, tokensLoading, tokensDictionary } = useDotcContext()
  const [tokenIn, setTokenIn] = useState<DotcAsset | undefined>()
  const [tokenOut, setTokenOut] = useState<DotcAsset | undefined>()
  const [queryParams, setQueryParams] = useQueryParams<QueryStringValues>({
    tokenIn: StringParam,
    tokenOut: StringParam,
    inactive: BooleanParam,
  })

  const [initiated, setInitiated] = useState(false)

  const {
    offers,
    loadingOffers,
    hasMore,
    loadMore,
    refetching,
    refreshOffers,
  } = useMyOffers({
    tokenIn,
    tokenOut,
    skip: tokensLoading || !account,
    hideInactiveOffers,
  })

  useEffect(() => {
    if (!tokensLoading && !initiated) {
      const tokenInAddress = queryParams.tokenIn
      const tokenOutAddress = queryParams.tokenOut
      const inactiveOffers = isNil(queryParams.inactive)
        ? true
        : queryParams.inactive

      const newTokenIn = tokenInAddress
        ? tokensDictionary.get(tokenInAddress)
        : undefined

      const newTokenOut = tokenOutAddress
        ? tokensDictionary.get(tokenOutAddress)
        : undefined

      setTokenIn(newTokenIn)
      setTokenOut(newTokenOut)
      setHideInactiveOffers(inactiveOffers)

      setInitiated(true)
    }
  }, [queryParams, tokensLoading, initiated, setInitiated, tokensDictionary])

  useEffect(() => {
    if (initiated) {
      setQueryParams({
        tokenIn: tokenIn?.id,
        tokenOut: tokenOut?.id,
        inactive: hideInactiveOffers ? undefined : false,
      })
    }
  }, [initiated, hideInactiveOffers, setQueryParams, tokenIn?.id, tokenOut?.id])

  const toggleInactiveOffers = () => {
    setHideInactiveOffers((prev) => !prev)
  }

  const onSwapPrice = () => setIsDirectMode((prev) => !prev)

  const onSwapTokens = () => {
    setTokenIn(tokenOut)
    setTokenOut(tokenIn)
  }

  const head = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    return (
      <tr>
        <th>{t(`th.assets`)}</th>
        <th>{t(`th.offering`)}</th>
        <th>
          <Flex
            justifyContent="center"
            alignItems="center"
            onClick={onSwapPrice}
          >
            {t(`th.atPrice`)} <SwapHoriz color="primary" />
          </Flex>
        </th>
        <th>{t(`th.minimumBuy`)}</th>
        {/* <th>{t('th.taker')}</th> */}
        <th>{t('th.status')}</th>
        <th scope="colgroup">{t(`th.expiresOn`, { timezone })}</th>
        <th> </th>
        <th> </th>
      </tr>
    )
  }

  const resetFilters = () => {
    setTokenIn(undefined)
    setTokenOut(undefined)
  }

  const handleCreateOffer = () => {
    openCreateOfferPopup()
  }

  const showResetFiltersButton =
    queryParams.inactive === false ||
    queryParams.tokenIn ||
    queryParams.tokenOut

  return (
    <>
      <Flex mb={3} alignItems="baseline" flexWrap="wrap">
        <Text.span ml="10px" mr="20px">
          {t('showMyOffers')}
        </Text.span>
        <TokenSelect
          label={t('from')}
          emptyValue={t('any')}
          filter={({ id }) => !isSameEthereumAddress(id, tokenOut?.id)}
          onChange={setTokenIn}
          selectedToken={tokenIn}
          tokens={tokens}
          tokensLoading={tokensLoading}
          groupNFTsByAddress
        />
        <ArrowForward onClick={onSwapTokens} color="primary" mt="6px" />
        <TokenSelect
          label={t('to')}
          emptyValue={t('any')}
          filter={({ id }) => !isSameEthereumAddress(id, tokenIn?.id)}
          onChange={setTokenOut}
          selectedToken={tokenOut}
          tokens={tokens}
          tokensLoading={tokensLoading}
          groupNFTsByAddress
        />
        {showResetFiltersButton && (
          <Button.Text fontWeight="300" ml="10px" onClick={resetFilters}>
            {t('resetFilters')}
          </Button.Text>
        )}
        <Flex ml="auto" mr="16px" alignItems="center">
          <Text.span fontSize={1}>{t('inactiveOffers')}</Text.span>
          &nbsp;
          <AngledSwitch
            checked={hideInactiveOffers}
            onChange={toggleInactiveOffers}
          />
          <Button
            onClick={handleCreateOffer}
            color="primary"
            fontWeight="600"
            lineHeight="20px"
            height="40px"
            fontSize="14px"
            ml="18px"
            px={['4px', '16px']}
          >
            {t('createOffer')}
          </Button>
        </Flex>
        <ExplorerLink type="address" hash={xDotcAddress} iconOnly />
      </Flex>
      <EditOfferPopupProvider>
        <StyledInfiniteTable
          colgroup={
            <>
              <colgroup>
                <col />
              </colgroup>
              <colgroup>
                <col />
              </colgroup>
              <colgroup>
                <col />
              </colgroup>
              <colgroup>
                <col />
              </colgroup>
              <colgroup>
                <col />
              </colgroup>
              <colgroup>
                <col />
              </colgroup>
              <colgroup>
                <col />
              </colgroup>
              <colgroup>
                <col />
              </colgroup>
              <colgroup className="actionIcons">
                <col />
              </colgroup>
            </>
          }
          head={head()}
          loading={loadingOffers}
          hasMore={hasMore}
          loadMore={loadMore}
          totalCols={8}
          noResults={
            <tr>
              <td colSpan={7}>
                <Text.p color="grey" textAlign="center" width="100%">
                  {t('noOffers')}
                </Text.p>
              </td>
            </tr>
          }
        >
          {refetching
            ? []
            : offers.map((offer) => (
                <MyOfferRow
                  key={offer.id}
                  offer={offer}
                  reload={refreshOffers}
                  isDirectMode={isDirectMode}
                />
              ))}
        </StyledInfiniteTable>
      </EditOfferPopupProvider>
    </>
  )
}

export default MyOffersTab
