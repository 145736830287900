import { big } from '@swarm/core/shared/utils/helpers/big-helpers'
import autoRound from '@swarm/core/shared/utils/math/autoRound'
import { ExtendedNativeToken } from '@swarm/types/tokens'
import { InvestToken } from '@swarm/types/tokens/invest'
import Loader from '@swarm/ui/presentational/Loader'
import { BigSource } from 'big.js'
import { Trans, useTranslation } from 'react-i18next'
import { Box, Flex, Text } from 'rimble-ui'
import styled from 'styled-components/macro'

interface AssetTokenDOTCInfoProps {
  loading: boolean
  tokenIn: ExtendedNativeToken
  tokenOut: InvestToken
  averagePrice: BigSource
  amountIn: BigSource
  amountOut: BigSource
  amountOverflowed?: boolean
  noExactOffer: boolean
  noMatching: boolean
  onAmountChange?: (value: number) => void
}

const ClickableText = styled(Text.span)(({ theme }) => ({
  fontSize: '14px',
  color: theme.colors.primary,
  cursor: 'pointer',
  textDecoration: 'underline',
}))

const AssetTokenDOTCInfo = ({
  loading,
  tokenIn,
  tokenOut,
  averagePrice,
  amountOut,
  amountIn,
  amountOverflowed = false,
  onAmountChange,
  noExactOffer = false,
  noMatching = false,
}: AssetTokenDOTCInfoProps) => {
  const { t } = useTranslation(['invest'])

  if (!loading && noMatching) {
    return (
      <>
        <Box mb={2}>
          <Text.span fontSize="14px" color="grey">
            {t('stockTokens.noMatching')}
          </Text.span>
        </Box>
      </>
    )
  }

  if (!loading && noExactOffer) {
    return (
      <>
        <Box mb={2}>
          <Text.span fontSize="14px" color="grey">
            <Trans
              ns="invest"
              i18nKey={
                amountOverflowed
                  ? 'stockTokens.bestAvailable'
                  : 'stockTokens.maxAvailable'
              }
              values={{
                tokenAmountIn: `${autoRound(amountIn)} ${tokenIn.symbol}`,
                tokenAmountOut: `${autoRound(amountOut)} ${tokenOut.symbol}`,
              }}
            >
              <ClickableText
                onClick={() => onAmountChange?.(big(amountIn).toNumber())}
              />
            </Trans>
          </Text.span>
        </Box>
      </>
    )
  }

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center" mb={2}>
        <Text.span color="grey" fontSize="14px">
          {t('stockTokens.youWillReceive')}
        </Text.span>
        <Loader loading={loading} inline>
          <Text.span title={amountOut} fontSize="14px">
            {`≈ ${autoRound(amountOut ?? 0)} ${tokenOut?.symbol}`}
          </Text.span>
        </Loader>
      </Flex>
      <Flex justifyContent="space-between" alignItems="center" mb={2}>
        <Text.span color="grey" fontSize="14px">
          {t('stockTokens.averageTokenPrice')}
        </Text.span>
        <Loader loading={loading || !tokenIn} inline>
          <Text.span title={averagePrice} fontSize="14px">
            {autoRound(averagePrice ?? 0)} {tokenIn?.symbol}
          </Text.span>
        </Loader>
      </Flex>
    </>
  )
}

export default AssetTokenDOTCInfo
