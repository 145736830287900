import Checkbox from '@material-ui/core/Checkbox'
import { ArrowForward, SwapHoriz } from '@rimble/icons'
import useClickAway from '@swarm/core/hooks/dom/useClickAway'
import useGroupSelect from '@swarm/core/hooks/state/useGroupSelect'
import { getCurrentConfig } from '@swarm/core/observables/configForNetwork'
import { isSameEthereumAddress, useAccount } from '@swarm/core/web3'
import { DotcAsset } from '@swarm/types/tokens/dotc'
import AngledSwitch from '@swarm/ui/presentational/AngledSwitch'
import InfiniteTable from '@swarm/ui/presentational/InfiniteTable'
import TextWithTooltip from '@swarm/ui/presentational/Text/TextWithTooltip'
import ExplorerLink from '@swarm/ui/swarm/ExplorerLink'
import { ethers } from 'ethers'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Flex, Text } from 'rimble-ui'
import styled from 'styled-components/macro'
import {
  BooleanParam,
  QueryParamConfig,
  StringParam,
  useQueryParams,
} from 'use-query-params'

import useSharedOffers from 'src/hooks/data/useSharedOffers'

import { useCreateOfferPopup } from '../CreateOffer'
import { useDotcContext } from '../DotcContext'
import { TakeOfferPopupProvider } from '../TakeOffer'

import OfferRow from './OfferRow'
import TokenSelect from './StyledSelect'
import { StyledMenu, StyledOption } from './styled-components'

const StyledInfiniteTable = styled(InfiniteTable)`
  col {
    width: 130px;
  }
  th {
    &:nth-child(3) {
      font-weight: bold;
      color: black;
    }
  }
  th,
  td {
    &:nth-child(1) {
      text-align: left;
    }
    &:nth-child(3) {
      font-weight: bold;
      background-color: #e8f2fc;
    }
    text-align: center;
  }
  td {
    color: ${(props) => props.theme.colors['near-black']};
  }

  colgroup.actionIcons {
    col {
      width: 30px !important;
    }
  }
`

type SharedOffersQueryStringValues = {
  id: QueryParamConfig<string | null | undefined>
  tokenIn: QueryParamConfig<string | null | undefined>
  tokenOut: QueryParamConfig<string | null | undefined>
  showPrivate: QueryParamConfig<boolean | null | undefined>
}

type CompanyName = 'SwarmX' | 'Violet' | 'Quadrata'

const CompaniesList: CompanyName[] = ['SwarmX', 'Violet', 'Quadrata']

const CompaniesAddressMap: Record<CompanyName, string> = {
  SwarmX: '0xA14c0d4f7ca6e6dF266f5B9C4E5002bB9efBfb00',
  Violet: ethers.constants.AddressZero,
  Quadrata: ethers.constants.AddressZero,
}

const SharedOffersTab = () => {
  const { openCreateOfferPopup } = useCreateOfferPopup()

  const { t } = useTranslation('otc')
  const account = useAccount()

  const { xDotcAddress } = getCurrentConfig()
  const [isDirectMode, setIsDirectMode] = useState(false)
  const [isPrivateOffers, setIsPrivateOffers] = useState(false)
  const togglePrivateOffers = () => setIsPrivateOffers((prev) => !prev)

  const {
    ref,
    active: isQualifiedOffers,
    toggle: toggleQualifiedOffers,
    setActive,
  } = useClickAway()

  const { isSelected, toggleSelect, selectedItems, clearSelectedItems } =
    useGroupSelect<CompanyName>([], true)

  const resetQualifiedOffers = () => {
    setActive(false)
    clearSelectedItems()
  }

  const { tokens, tokensLoading, tokensDictionary } = useDotcContext()
  const [tokenIn, setTokenIn] = useState<DotcAsset | undefined>()
  const [tokenOut, setTokenOut] = useState<DotcAsset | undefined>()
  const [queryParams, setQueryParams] =
    useQueryParams<SharedOffersQueryStringValues>({
      id: StringParam,
      tokenIn: StringParam,
      tokenOut: StringParam,
      showPrivate: BooleanParam,
    })

  const [initiated, setInitiated] = useState(false)

  const {
    offers,
    loadingOffers,
    hasMore,
    loadMore,
    refetching,
    refreshOffers,
  } = useSharedOffers({
    tokenIn,
    tokenOut,
    qualifiedMakers: selectedItems.map((key) => CompaniesAddressMap[key]),
    isPrivateOffers,
    skip: tokensLoading,
  })

  useEffect(() => {
    if (!tokensLoading && !initiated) {
      const newTokenIn = queryParams.tokenIn
        ? tokensDictionary.get(queryParams.tokenIn)
        : undefined

      const newTokenOut = queryParams.tokenOut
        ? tokensDictionary.get(queryParams.tokenOut)
        : undefined

      setTokenIn(newTokenIn)
      setTokenOut(newTokenOut)

      const showPrivate = Boolean(queryParams.showPrivate)
      if (account) {
        setIsPrivateOffers(showPrivate)
      } else {
        setQueryParams({ showPrivate: undefined })
      }

      setInitiated(true)
    }
  }, [
    queryParams,
    tokensLoading,
    initiated,
    setInitiated,
    tokensDictionary,
    account,
    setQueryParams,
  ])

  useEffect(() => {
    if (initiated) {
      setQueryParams({
        tokenIn: tokenIn?.id,
        tokenOut: tokenOut?.id,
        showPrivate: isPrivateOffers || undefined,
      })
    }
  }, [initiated, isPrivateOffers, setQueryParams, tokenIn?.id, tokenOut?.id])

  useEffect(() => {
    if (!account) {
      setIsPrivateOffers(false)
    }
  }, [account])

  const onSwapPrice = () => setIsDirectMode((prev) => !prev)

  const onSwapTokens = () => {
    setTokenIn(tokenOut)
    setTokenOut(tokenIn)
  }

  const head = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    return (
      <tr>
        <th>{t(`th.assets`)}</th>
        <th scope="colgroup">{t(`th.offering`)}</th>
        <th>
          <Flex
            justifyContent="center"
            alignItems="center"
            onClick={onSwapPrice}
          >
            {t(`th.atPrice`)} <SwapHoriz color="primary" />
          </Flex>
        </th>
        <th scope="colgroup">{t(`th.minimumBuy`)}</th>
        <th scope="colgroup">
          <TextWithTooltip
            justifyContent="center"
            tooltip={t('tooltips.timelockShared')}
          >
            {t(`th.timelock`)}
          </TextWithTooltip>
        </th>
        <th scope="colgroup">{t(`th.expiresOn`, { timezone })}</th>
        <th> </th>
        <th> </th>
      </tr>
    )
  }

  const resetFilters = () => {
    setTokenIn(undefined)
    setTokenOut(undefined)
    setIsPrivateOffers(false)
    resetQualifiedOffers()
    setQueryParams({ id: undefined }, 'replaceIn')
  }

  const handleCreateOffer = () => {
    openCreateOfferPopup()
  }

  return (
    <>
      <Flex mb={3} alignItems="baseline" flexWrap="wrap">
        <Text.span ml="10px" mr="20px">
          {t('showMeOffers')}
        </Text.span>
        <TokenSelect
          label={t('for')}
          emptyValue={t('any')}
          filter={({ id }) => !isSameEthereumAddress(id, tokenOut?.id)}
          onChange={setTokenIn}
          selectedToken={tokenIn}
          tokens={tokens}
          tokensLoading={tokensLoading}
          groupNFTsByAddress
        />
        <ArrowForward onClick={onSwapTokens} color="primary" mt="6px" />
        <TokenSelect
          label={t('in')}
          emptyValue={t('any')}
          filter={({ id }) => !isSameEthereumAddress(id, tokenIn?.id)}
          onChange={setTokenOut}
          selectedToken={tokenOut}
          tokens={tokens}
          tokensLoading={tokensLoading}
          groupNFTsByAddress
        />
        <Flex ml={10}>
          <Text.span fontSize={1}>{t('privateOffers')}</Text.span>
          &nbsp;
          <AngledSwitch
            disabled={!account}
            checked={isPrivateOffers}
            onChange={togglePrivateOffers}
          />
        </Flex>
        <Flex ml={10}>
          {/* DON'T DELETE THIS */}
          <Text.span fontSize={1}>{t('qualifiedOffers')}</Text.span>
          &nbsp;
          <Box style={{ position: 'relative' }}>
            <AngledSwitch
              checked={isQualifiedOffers || selectedItems.length > 0}
              onChange={toggleQualifiedOffers}
            />
            {isQualifiedOffers && (
              <StyledMenu ref={ref}>
                {CompaniesList.map((value) => (
                  <StyledOption key={value} onClick={() => toggleSelect(value)}>
                    {value}
                    <Checkbox
                      style={{ padding: 0 }}
                      color="primary"
                      checked={isSelected(value)}
                    />
                  </StyledOption>
                ))}
              </StyledMenu>
            )}
          </Box>
        </Flex>
        {(Object.values(queryParams).some(Boolean) ||
          selectedItems.length > 0) && (
          <Button.Text fontWeight="300" ml="10px" onClick={resetFilters}>
            {t('resetFilters')}
          </Button.Text>
        )}
        <Button
          onClick={handleCreateOffer}
          color="primary"
          fontWeight="600"
          lineHeight="20px"
          height="40px"
          fontSize="14px"
          ml="auto"
          mr="16px"
          px={['4px', '16px']}
        >
          {t('createOffer')}
        </Button>
        <ExplorerLink type="address" hash={xDotcAddress} iconOnly />
      </Flex>
      <TakeOfferPopupProvider reload={refreshOffers}>
        <StyledInfiniteTable
          colgroup={
            <>
              <colgroup>
                <col />
              </colgroup>
              <colgroup>
                <col />
              </colgroup>
              <colgroup>
                <col />
              </colgroup>
              <colgroup>
                <col />
              </colgroup>
              <colgroup>
                <col />
              </colgroup>
              <colgroup>
                <col />
              </colgroup>
              <colgroup>
                <col />
              </colgroup>
              <colgroup className="actionIcons">
                <col />
              </colgroup>
            </>
          }
          head={head()}
          loading={loadingOffers}
          hasMore={hasMore}
          loadMore={loadMore}
          totalCols={8}
          noResults={
            <tr>
              <td colSpan={7}>
                <Text.p color="grey" textAlign="center" width="100%">
                  {t('noOffers')}
                </Text.p>
              </td>
            </tr>
          }
        >
          {refetching
            ? []
            : (offers || []).map((offer) => (
                <OfferRow
                  key={offer.id}
                  offer={offer}
                  isDirectMode={isDirectMode}
                />
              ))}
        </StyledInfiniteTable>
      </TakeOfferPopupProvider>
    </>
  )
}

export default SharedOffersTab
