import { ExpandLess, ExpandMore, SwapVert } from '@rimble/icons'
import useAsyncMemo from '@swarm/core/hooks/async/useAsyncMemo'
import useEnableAsset from '@swarm/core/hooks/web3/useEnableAsset'
import { getCurrentConfig } from '@swarm/core/observables/configForNetwork'
import { prettifyBalance } from '@swarm/core/shared/utils'
import { safeDiv } from '@swarm/core/shared/utils/helpers'
import { getInputPrecision } from '@swarm/core/shared/utils/tokens/precision'
import { isSameEthereumAddress } from '@swarm/core/web3'
import { TokenSelectorAsset } from '@swarm/types/tokens'
import AngledSwitch from '@swarm/ui/presentational/AngledSwitch'
import Label from '@swarm/ui/presentational/Form/Label'
import Grid from '@swarm/ui/presentational/Grid'
import TextWithTooltip from '@swarm/ui/presentational/Text/TextWithTooltip'
import SmartButton from '@swarm/ui/swarm/Buttons/SmartButton'
import MaxInput from '@swarm/ui/swarm/MaxInput'
import theme from '@swarm/ui/theme'
import match from 'conditional-expression'
import { addDays, addMinutes, addYears, getUnixTime } from 'date-fns'
import { isAddress } from 'ethers/lib/utils'
import { useCallback, useMemo } from 'react'
import DatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import { Box, Button, Flex, Input, Loader, Select, Text } from 'rimble-ui'

import TokenSelectorManager from 'src/components/TokenSelectorManager'
import { FlipButton } from 'src/components/XDotc/styled-components'
import { XDotcManager } from 'src/contracts/XDotcManager'
import useMakeOffer from 'src/hooks/web3/open-otc/useMakeOffer'

import { useCreateOfferContext } from './CreateOfferContext'
import useCreateOfferForm from './useCreateOfferForm'

// eslint-disable-next-line import/extensions
import 'react-datepicker/dist/react-datepicker.css'
import { useCreateOfferPopup } from '.'

// added 30 minutes for safe transaction time
export const minDate = addMinutes(Date.now(), 30)
// by default dOTCoffer should expire in 2 years
const maxDate = addYears(minDate, 2)

const { xDotcAddress } = getCurrentConfig()

const CreateOfferForm = () => {
  const { t } = useTranslation(['otc', 'errors'])

  const {
    tokens,
    loading: contextLoading,
    allowanceLoading,
    bestPrice,
    tokenIn,
    tokenOut,
    setTokenPair,
    setSearch,
  } = useCreateOfferContext()
  const { closeCreateOfferPopup } = useCreateOfferPopup()

  const [enableAsset, { txLoading: enableTxLoading }] = useEnableAsset()
  const [makeOffer, { txLoading: makeOfferTxLoading }] = useMakeOffer()
  const { state, actions } = useCreateOfferForm()

  const handleTokenSearch = (value: string) => {
    setSearch(value)
  }

  const [fee] = useAsyncMemo(() => XDotcManager.getFee(), 0, [])

  const isDepositText =
    tokenIn?.symbol && tokenOut?.symbol && state.amountOut && state.amountIn

  const handleConfirmButtonClick = useCallback(async () => {
    if (!tokenIn || !tokenOut) return undefined

    if (tokenIn.type && tokenIn.allowance?.lt(state.amountIn)) {
      return enableAsset(tokenIn, xDotcAddress, tokenIn.type)
    }

    const expiresAt = getUnixTime(
      state.isExpiration ? state.selectedDate : maxDate,
    )
    const isFullType = !state.isPartialOrder
    const timelockDays = Number(state.timelockPeriod)
    const timelock = state.timelockPeriod
      ? getUnixTime(addDays(Date.now(), timelockDays))
      : 0
    const specialAddresses = state.takerAddress ? [state.takerAddress] : []

    const receipt = await makeOffer(
      tokenIn,
      tokenOut,
      state.amountIn,
      state.amountOut,
      expiresAt,
      isFullType,
      specialAddresses,
      timelock,
      state.terms,
      state.contact,
    )

    if (receipt?.status) {
      closeCreateOfferPopup()
    }
  }, [tokenIn, tokenOut, state, makeOffer, enableAsset, closeCreateOfferPopup])

  const { isValid, error: validationError } = useMemo(() => {
    const zeroAmount = state.amountIn === 0 || state.amountOut === 0

    const insufficientBalance = !!tokenIn?.balance?.lt(state.amountIn)

    const isTakerAddressValid =
      !state.takerAddress || isAddress(state.takerAddress)

    const isTimelockPeriodValid =
      !state.timelockPeriod || Number(state.timelockPeriod) >= 0

    const isExpired = state.selectedDate < minDate

    return {
      isValid:
        !zeroAmount &&
        !insufficientBalance &&
        isTakerAddressValid &&
        !isExpired,
      error:
        (insufficientBalance &&
          t('errors:insufficientBalance', { symbol: tokenIn?.symbol })) ||
        (!isTakerAddressValid && t('errors:invalidOfferTaker')) ||
        (!isTimelockPeriodValid && t('otc:timelockPeriodIsNotValid')) ||
        (isExpired && t('otc:selectedTimeIsNotValid')),
    }
  }, [tokenIn?.balance, tokenIn?.symbol, state, t])

  const loading = makeOfferTxLoading || contextLoading || enableTxLoading

  const feeAmount = fee * Number(state.amountOut)

  const maxAmountIn = tokenIn?.balance?.toNumber() || 0

  const handleTokenInSelection = (token: TokenSelectorAsset) => {
    setTokenPair({ tokenIn: token })
  }

  const handleTokenOutSelection = (token: TokenSelectorAsset) =>
    setTokenPair({ tokenOut: token })

  const onSwapTokens = () => {
    setTokenPair({
      tokenIn: tokenOut,
      tokenOut: tokenIn,
    })
    actions.setAmountIn(state.amountOut)
    actions.setAmountOut(state.amountIn)
  }

  return (
    <>
      <Grid mt={24} gridTemplateColumns="2fr 150px" gridGap={[2, 3]}>
        <Flex width="100%" flexDirection="column" justifyContent="flex-end">
          <Label mobile style={{ textTransform: 'capitalize' }}>
            {t('offer')}
          </Label>
          <Box position="relative">
            <MaxInput
              onChange={actions.handleAmountInChange}
              value={state.amountIn}
              height="48px"
              px="12px"
              max={maxAmountIn}
              decimalScale={tokenIn && getInputPrecision(tokenIn)}
              showMax
              disabled={!tokenIn}
            />
          </Box>
        </Flex>
        <Flex width="100%" flexDirection="column" justifyContent="flex-end">
          <TokenSelectorManager
            showTokenInfo={(token) => !!token.rwaType}
            onSearch={handleTokenSearch}
            onChange={handleTokenInSelection}
            selected={tokenIn}
            filter={(token) => !isSameEthereumAddress(token.id, tokenOut?.id)}
            tokens={tokens}
            loading={contextLoading}
            orderBy="balance"
            orderDirection="desc"
            withoutPortal
            emptyValue={
              <Text.span color="grey">{t('assets.selectToken')}</Text.span>
            }
          />
        </Flex>
      </Grid>
      <Flex my={3} justifyContent="center" width="100%">
        <FlipButton onlyIcon onClick={onSwapTokens}>
          <SwapVert color="primary" size="26" />
        </FlipButton>
      </Flex>
      <Grid gridTemplateColumns="2fr 150px" gridGap={[2, 3]}>
        <Flex width="100%" flexDirection="column" justifyContent="flex-end">
          <Label mobile style={{ textTransform: 'capitalize' }}>
            {t('for', { ns: 'otc' })}
          </Label>
          <MaxInput
            onChange={actions.handleAmountOutChange}
            value={state.amountOut}
            decimalScale={tokenOut && getInputPrecision(tokenOut)}
            height="48px"
            px="12px"
            showMax={false}
            disabled={true}
          />
        </Flex>
        <Flex width="100%" flexDirection="column" justifyContent="flex-end">
          <TokenSelectorManager
            showTokenInfo={(token) => !!token.rwaType}
            onSearch={handleTokenSearch}
            onChange={handleTokenOutSelection}
            selected={tokenOut}
            filter={(token) => !isSameEthereumAddress(token.id, tokenIn?.id)}
            tokens={tokens}
            loading={contextLoading}
            orderBy="balance"
            orderDirection="desc"
            withoutPortal
            emptyValue={
              <Text.span color="grey">{t('assets.selectToken')}</Text.span>
            }
          />
        </Flex>
      </Grid>
      <Grid gridTemplateColumns="2fr 2fr" gridGap={[2, 3]}>
        <Flex width="100%" flexDirection="column" justifyContent="flex-end">
          <Label mobile>{t('yourPrice', { ns: 'otc' })}</Label>
          <MaxInput
            border={`2px solid ${theme.colors.primary}`}
            onChange={actions.handlePriceChange}
            value={state.price || 0}
            height="48px"
            px="12px"
            disabled={!tokenOut}
            showMax={false}
            decimalScale={tokenOut && getInputPrecision(tokenOut)}
            prefix={tokenIn && tokenOut ? `1 ${tokenIn.symbol} =` : ''}
            postfix={tokenIn && tokenOut ? tokenOut.symbol : ''}
          />
          {tokenIn && tokenOut && (
            <Label>
              <Text.span marginLeft="13px" color="grey" fontSize="12px">
                1 {tokenOut?.symbol} ={' '}
                {prettifyBalance(safeDiv(1, state.price), 4)} {tokenIn?.symbol}
              </Text.span>
            </Label>
          )}
        </Flex>
        <Flex
          width="100%"
          mt={20}
          flexDirection="column"
          justifyContent="flex-end"
        >
          <Label mobile>{t('bestPrice', { ns: 'otc' })}</Label>
          <MaxInput
            value={bestPrice}
            height="48px"
            px="12px"
            disabled
            showMax={false}
            decimalScale={tokenOut && getInputPrecision(tokenOut)}
            prefix={tokenIn && tokenOut ? `1 ${tokenIn?.symbol} =` : ''}
            postfix={tokenIn && tokenOut ? tokenOut.symbol : ''}
          />
          {tokenIn && tokenOut && (
            <Label>
              <Text.span marginLeft="13px" color="grey" fontSize="12px">
                1 {tokenOut?.symbol} ={' '}
                {prettifyBalance(safeDiv(1, bestPrice), 4)} {tokenIn?.symbol}
              </Text.span>
            </Label>
          )}
        </Flex>
      </Grid>
      <Flex justifyContent="space-between" mt={2} color="grey">
        <Text.span fontSize={1} flexShrink={0}>
          {t('makerFee', { ns: 'otc' })}:{' '}
          <Text.span color="black" fontSize={12}>
            {`${feeAmount.toFixed(2)} ${tokenOut?.symbol || ''}`}
          </Text.span>
        </Text.span>
        <Text.span ml="20px" textAlign="right" fontWeight={600} fontSize={1}>
          {t('feeTakenOnlySuccessfullyExecuted', { ns: 'otc' })}
        </Text.span>
      </Flex>
      {isDepositText ? (
        <Text.span color="black" fontWeight={600} mt="24px">
          {`${t('youWillDeposit', { ns: 'otc' })} ${state.amountIn} ${
            tokenIn?.symbol
          } ${t('intoDOTCContract', { ns: 'otc' })}.`}
        </Text.span>
      ) : null}

      <Grid
        mt={16}
        gridTemplateColumns="2fr 2fr"
        gridGap={[2, 3]}
        alignItems="baseline"
      >
        <TextWithTooltip
          color="grey"
          tooltip={t('tooltips.timelock', { ns: 'otc' })}
          justifySelf="flex-start"
        >
          <Label noGap mobile>
            {t('timelock', { ns: 'otc' })}
          </Label>
        </TextWithTooltip>
        <Input
          type="number"
          onChange={actions.handleTimelockPeriodChange}
          bg="white"
          height="30px"
          placeholder={`${t('otc:enterTimelock')}`}
          value={state.timelockPeriod}
        />
      </Grid>
      <Grid
        mt={16}
        gridTemplateColumns="2fr 2fr"
        gridGap={[2, 3]}
        alignItems="baseline"
      >
        <TextWithTooltip
          color="grey"
          tooltip={t('tooltips.terms', { ns: 'otc' })}
          justifySelf="flex-start"
        >
          <Label noGap mobile>
            {t('terms', { ns: 'otc' })}
          </Label>
        </TextWithTooltip>
        <Input
          onChange={actions.handleTermsChange}
          bg="white"
          height="30px"
          placeholder={`${t('otc:enterTerms')}`}
          value={state.terms}
        />
      </Grid>
      <Grid
        mt={16}
        gridTemplateColumns="2fr 2fr"
        gridGap={[2, 3]}
        alignItems="baseline"
      >
        <TextWithTooltip
          color="grey"
          tooltip={t('tooltips.contact', { ns: 'otc' })}
          justifySelf="flex-start"
        >
          <Label noGap mobile>
            {t('contact', { ns: 'otc' })}
          </Label>
        </TextWithTooltip>
        <Input
          onChange={actions.handleContactChange}
          bg="white"
          height="30px"
          placeholder={`${t('otc:enterContact')}`}
          value={state.contact}
        />
      </Grid>

      <Box>
        <SmartButton
          requireInitiated
          requireAccount
          type="button"
          width="100%"
          height={['40px', '52px']}
          fontWeight={4}
          fontSize={[2, 3]}
          mt="24px"
          mainColor={loading ? 'grey' : 'primary'}
          disabled={!isValid || loading || allowanceLoading}
          loading={allowanceLoading || loading}
          onClick={handleConfirmButtonClick}
          loadingText={
            <>
              <Loader mr={2} color="white" />
              {t('assets.loading')}
            </>
          }
        >
          {(tokenIn?.allowance?.lt(state.amountIn) &&
            !allowanceLoading &&
            t('assets.enableToken', { tokenName: tokenIn?.symbol })) ||
            validationError ||
            t('otc:confirm')}
        </SmartButton>
      </Box>

      <Flex mt="24px" justifyContent="center" alignItems="center">
        <Button.Text
          p={0}
          fontSize="15px"
          fontWeight="400"
          onClick={actions.toggleAdvancedSettings}
          color="primary"
        >
          <Text.span lineHeight="22px">
            {t('advanced', { ns: 'otc' })}{' '}
          </Text.span>
          {state.advancedSettingsExpanded ? <ExpandLess /> : <ExpandMore />}
        </Button.Text>
      </Flex>
      {state.advancedSettingsExpanded && (
        <Flex flexDirection="column" mt={24} pb={70}>
          <Flex color="grey" justifyContent="space-between">
            <TextWithTooltip
              tooltip={t('tooltips.partialOrders', { ns: 'otc' })}
            >
              <Label noGap mobile>
                {t('partialOrders', { ns: 'otc' })}
              </Label>
            </TextWithTooltip>
            <AngledSwitch
              checked={state.isPartialOrder}
              onChange={actions.toggleIsPartialOrder}
            />
          </Flex>
          <Flex
            mt={16}
            color="grey"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <TextWithTooltip
              tooltip={t('tooltips.privateOffer', { ns: 'otc' })}
            >
              <Label noGap mobile>
                {t('privateOffer', { ns: 'otc' })}
              </Label>
            </TextWithTooltip>
            <Input
              onChange={actions.handleTakerAddressChange}
              bg="white"
              height="30px"
              placeholder={`${t('otc:enterTakerAddress')}`}
              value={state.takerAddress}
            />
          </Flex>
          <Flex
            mt={16}
            height="35px"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <TextWithTooltip
              color="grey"
              alignSelf="center"
              style={{ whiteSpace: 'pre-wrap' }}
              tooltip={t('tooltips.expiration', { ns: 'otc' })}
            >
              <Label noGap mobile>
                {t('expiration', { ns: 'otc' })}
              </Label>
            </TextWithTooltip>
            <Flex alignItems="center" height="35px">
              {match(state.isExpiration)
                .equals(false)
                .then(
                  <Text.span
                    fontWeight="600"
                    lineHeight="20px"
                    fontSize="14px"
                    mr="16px"
                    px={['4px', '16px']}
                  >
                    {t('untilCancelled', { ns: 'otc' })}
                  </Text.span>,
                )
                .else(
                  <DatePicker
                    autoFocus
                    customInput={
                      <Input
                        maxWidth="180px"
                        height="22px"
                        bg="white"
                        fontSize="14px"
                      />
                    }
                    showTimeSelect
                    dateFormat="dd/MM/yyyy h:mm aa"
                    timeFormat="h:mm aa"
                    minDate={minDate}
                    maxDate={maxDate}
                    filterTime={(date) => date > minDate}
                    selected={state.selectedDate}
                    onChange={actions.handleDateChange}
                  />,
                )}
              <Box alignSelf="center" pl={4}>
                <AngledSwitch
                  checked={state.isExpiration}
                  onChange={actions.toggleIsExpired}
                />
              </Box>
            </Flex>
          </Flex>
          <Flex flexDirection="column" mt={2}>
            {/* DON'T DELETE THIS */}
            <Text color="near-black" fontWeight={5} mb={2}>
              Qualified offer
            </Text>
            <Select
              height="30px"
              placeholder={t('invest:fundsModal.sourceOfIncome.placeholder')}
              options={[
                {
                  label: 'SwarmX',
                  value: 1,
                },
                {
                  label: 'Violet',
                  value: 2,
                },
                {
                  label: 'Quadrata',
                  value: 3,
                },
              ]}
              border="light-gray"
              bg="white"
              style={{ boxShadow: 'none', cursor: 'pointer' }}
            />
          </Flex>
        </Flex>
      )}
    </>
  )
}

export default CreateOfferForm
