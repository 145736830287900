import AbstractContract, {
  ContractInstances,
} from '@swarm/core/contracts/AbstractContract'
import { getCpk } from '@swarm/core/contracts/cpk'
import { getCurrentConfig } from '@swarm/core/observables/configForNetwork'
import { verify } from '@swarm/core/shared/utils'
import { denormalize } from '@swarm/core/shared/utils/helpers/big-helpers'
import { denormalizeTokenType } from '@swarm/core/shared/utils/subgraph/x-dotc'
import { isNFTType } from '@swarm/core/shared/utils/tokens'
import {
  AbstractAsset,
  AbstractToken,
  HasCpkAllowance,
  HasCpkBalance,
  HasType,
} from '@swarm/types/tokens'
import { BigSource } from 'big.js'
import { TransactionResult } from 'contract-proxy-kit'
import { Address } from 'contract-proxy-kit/src/utils/basicTypes'
import { utils } from 'ethers'

import abi from 'src/contracts/abi'
import { Order } from 'src/hooks/useFullFillOrder'

import type {
  AssetStruct,
  XDotc as Contract,
  XDotcInterface as ContractInterface,
  OfferStructStruct,
} from './typechain/XDotc'

const { xDotcAddress } = getCurrentConfig()
const XDotcInterface = new utils.Interface(abi.XDotc) as ContractInterface

export type XDotcContractToken = AbstractAsset & HasType
export type XDotcBatchToken = AbstractToken & HasCpkBalance & HasCpkAllowance

export class XDotcContract extends AbstractContract {
  contract: Contract | undefined

  static instances: ContractInstances<XDotcContract> = {}

  constructor() {
    super(xDotcAddress, abi.XDotc)
  }

  static getInstance = async (): Promise<XDotcContract> => {
    if (!XDotcContract.instances[xDotcAddress]) {
      XDotcContract.instances[xDotcAddress] = new XDotcContract()
      await XDotcContract.instances[xDotcAddress].init()
    }
    return XDotcContract.instances[xDotcAddress]
  }

  private makeOffer = async (
    tokenIn: XDotcContractToken,
    tokenOut: XDotcContractToken,
    amountIn: BigSource,
    amountOut: BigSource,
    expiresAt: number,
    isFullType: boolean,
    specialAddresses: Address[],
    timelock: number,
    terms: string,
    commsLink: string,
  ): Promise<TransactionResult> => {
    this.init()
    this.updateSigner()

    if (this.contract === undefined) {
      throw new Error('DOTC contract is not initialized')
    }

    let depositAsset: AssetStruct
    let withdrawalAsset: AssetStruct

    if (isNFTType<XDotcContractToken>(tokenIn)) {
      depositAsset = {
        assetType: denormalizeTokenType(tokenIn.type),
        assetAddress: tokenIn.address,
        amount: amountIn.toString(),
        tokenId: Number(tokenIn.tokenId),
      }
    } else {
      const denormAmountIn = denormalize(amountIn, tokenIn.decimals)
      depositAsset = {
        assetType: denormalizeTokenType(tokenIn.type),
        assetAddress: tokenIn.id,
        amount: denormAmountIn.toFixed(0),
        tokenId: 0,
      }
    }

    if (isNFTType<XDotcContractToken>(tokenOut)) {
      withdrawalAsset = {
        assetType: denormalizeTokenType(tokenOut.type),
        assetAddress: tokenOut.address,
        amount: amountOut.toString(),
        tokenId: Number(tokenOut.tokenId),
      }
    } else {
      const denormAmountOut = denormalize(amountOut, tokenOut.decimals)
      withdrawalAsset = {
        assetType: denormalizeTokenType(tokenOut.type),
        assetAddress: tokenOut.id,
        amount: denormAmountOut.toFixed(0),
        tokenId: 0,
      }
    }

    const fullType =
      isNFTType(tokenIn) || isNFTType(tokenOut) ? true : isFullType

    const offer: OfferStructStruct = {
      isFullType: fullType,
      specialAddresses,
      expiryTimestamp: expiresAt,
      timelockPeriod: timelock,
      terms,
      commsLink,
    }

    return this.contract.makeOffer(depositAsset, withdrawalAsset, offer)
  }

  private takeOffer = async (
    offerId: string,
    amountToSend: BigSource,
    tokenOut: XDotcContractToken,
  ): Promise<TransactionResult> => {
    this.init()
    this.updateSigner()

    if (this.contract === undefined) {
      throw new Error('DOTC contract is not initialized')
    }

    let denormAmountToSend: BigSource

    if (isNFTType(tokenOut)) {
      denormAmountToSend = amountToSend.toString()
    } else {
      denormAmountToSend = denormalize(amountToSend, tokenOut.decimals).toFixed(
        0,
      )
    }

    const id = parseInt(offerId, 16)

    return this.contract.takeOffer(id, denormAmountToSend)
  }

  private takeOffersBatch = async (
    orders: Order[],
    tokenIn: XDotcBatchToken,
    tokenOut: XDotcBatchToken,
    amountIn: BigSource,
    amountOut: BigSource,
    takerAddress: Address,
  ): Promise<TransactionResult> => {
    this.init()
    this.updateSigner()

    // Verify the CPK is defined
    const cpk = getCpk()
    verify(!!cpk, 'Could not obtain CPK')

    if (this.contract === undefined) {
      throw new Error('DOTC contract is not initialized')
    }

    const denormTotalAmount = denormalize(amountIn, tokenIn.decimals)
    const denormTotalMinimumExpectedAmount = denormalize(
      amountOut,
      tokenOut.decimals,
    )

    if (tokenIn.cpkBalance?.lt(amountIn)) {
      cpk.transferTokenFrom(takerAddress, tokenIn.id, denormTotalAmount)
    }

    cpk?.approveCpkTokenFor(
      tokenIn?.id,
      'erc20',
      xDotcAddress,
      denormTotalAmount,
    )

    orders.forEach((order) => {
      const { offer, amount } = order
      const id = parseInt(offer.id, 16)
      const denormAmount = denormalize(amount, tokenIn.decimals).toFixed(0)

      cpk.patchTxs({
        to: xDotcAddress,
        data: XDotcInterface.encodeFunctionData('takeOffer', [
          id,
          denormAmount,
        ]),
      })
    })

    const tokenOutCpkBalance = denormalize(
      tokenOut?.cpkBalance ?? 0,
      tokenOut.decimals ?? 0,
    )

    const tokenOutAmount =
      denormTotalMinimumExpectedAmount.add(tokenOutCpkBalance)

    // Approve tokenOut to withdraw to user address from CPK
    cpk?.approveCpkTokenFor(tokenOut.id, 'erc20', xDotcAddress, tokenOutAmount)
    // Send purchased token from User CPK to User Account
    cpk?.transferToken(takerAddress, tokenOut.id, tokenOutAmount)

    return cpk?.execStoredTxs()
  }

  static takeOffersBatch = async (
    orders: Order[],
    tokenIn: XDotcBatchToken,
    tokenOut: XDotcBatchToken,
    amountIn: BigSource,
    amountOut: BigSource,
    takerAddress: Address,
  ) => {
    const otcContract = await XDotcContract.getInstance()
    return otcContract.takeOffersBatch(
      orders,
      tokenIn,
      tokenOut,
      amountIn,
      amountOut,
      takerAddress,
    )
  }

  private editOffer = async (
    offerId: string,
    tokenOut: AbstractAsset & HasType,
    amountOut: BigSource,
    expiresAt: number,
    timelock: number,
    isFullType: boolean,
    specialAddresses: Address[],
    terms: string,
    commsLink: string,
  ): Promise<TransactionResult> => {
    this.init()
    this.updateSigner()

    if (this.contract === undefined) {
      throw new Error('DOTC contract is not initialized')
    }

    let denormAmountOut: BigSource

    if (isNFTType(tokenOut)) {
      denormAmountOut = amountOut.toString()
    } else {
      denormAmountOut = denormalize(amountOut, tokenOut.decimals).toFixed(0)
    }

    const id = parseInt(offerId, 16)

    const updatedOffer: OfferStructStruct = {
      isFullType: isFullType,
      specialAddresses,
      expiryTimestamp: expiresAt,
      timelockPeriod: timelock,
      terms,
      commsLink,
    }

    return this.contract.updateOffer(id, denormAmountOut, updatedOffer)
  }

  private cancelOffer = async (offerId: string): Promise<TransactionResult> => {
    this.init()
    this.updateSigner()

    if (this.contract === undefined) {
      throw new Error('DOTCv2 contract is not initialized')
    }

    const id = parseInt(offerId, 16)

    return this.contract.cancelOffer(id)
  }

  static makeOffer = async (
    tokenIn: XDotcContractToken,
    tokenOut: XDotcContractToken,
    amountIn: BigSource,
    amountOut: BigSource,
    expiresAt: number,
    isFullType: boolean,
    specialAddresses: Address[],
    timelock: number,
    terms: string,
    commsLink: string,
  ) => {
    const instance = await XDotcContract.getInstance()

    return instance.makeOffer(
      tokenIn,
      tokenOut,
      amountIn,
      amountOut,
      expiresAt,
      isFullType,
      specialAddresses,
      timelock,
      terms,
      commsLink,
    )
  }

  static takeOffer = async (
    offerId: string,
    amountPaid: BigSource,
    tokenOut: XDotcContractToken,
  ) => {
    const instance = await XDotcContract.getInstance()
    return instance.takeOffer(offerId, amountPaid, tokenOut)
  }

  static editOffer = async (
    offerId: string,
    tokenOut: XDotcContractToken,
    amountOut: BigSource,
    expiresAt: number,
    timelock: number,
    isFullType: boolean,
    specialAddresses: Address[],
    terms: string,
    commsLink: string,
  ) => {
    const instance = await XDotcContract.getInstance()
    return instance.editOffer(
      offerId,
      tokenOut,
      amountOut,
      expiresAt,
      timelock,
      isFullType,
      specialAddresses,
      terms,
      commsLink,
    )
  }

  static cancelOffer = async (offerId: string) => {
    const instance = await XDotcContract.getInstance()
    return instance.cancelOffer(offerId)
  }
}
