import { Add } from '@rimble/icons'
import { useToggle } from '@swarm/core/hooks/state/useToggle'
import { VerificationStatus } from '@swarm/core/shared/enums'
import { getVerificationStep, isEthereum } from '@swarm/core/shared/utils'
import { connect } from '@swarm/core/state/AppContext'
import { SmtContextProvider } from '@swarm/core/state/SmtContext'
import { useNetwork } from '@swarm/core/web3'
import { AppState } from '@swarm/types/state'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from 'rimble-ui'

import StyledButton from '@ui/presentational/StyledButton'

import LinkButton from './Buttons/LinkButton'
import ConnectButton from './ConnectButton'
import NetworkSelect from './NetworkSelect'
import BurnSmtPopup from './Popups/BurnSMTPopup'
import RewardsWalletPopup from './Popups/RewardsWalletPopup'
import SmtRewardsBadge from './SmtRewardsBadge'
import { SwarmApps } from './SwarmApps'

interface HeaderActionsProps extends Record<string, unknown> {
  addLiquidity?: boolean
  currentStep?: number
  smtWidget?: boolean
  appsWidget?: boolean
  showPopup?: boolean
  networkSelect?: boolean
  burnSmt?: boolean
  setShowPopup?: (showPopup: boolean) => void
}

const HeaderActions = ({
  addLiquidity = false,
  smtWidget = true,
  appsWidget = true,
  currentStep = 1,
  showPopup = false,
  networkSelect = true,
  burnSmt = false,
  setShowPopup = () => {},
}: HeaderActionsProps) => {
  const { t } = useTranslation('pools')
  const [showRewardsBalancePopup, setShowRewardsBalancePopup] = useState(false)
  const { isOn, on, off } = useToggle(false)
  const { networkId } = useNetwork()
  const isBurnSmtAllowed = useMemo(() => isEthereum(networkId), [networkId])

  const openModal = useCallback(() => {
    setShowRewardsBalancePopup(true)
    setShowPopup(true)
  }, [setShowPopup])

  const closeModal = useCallback(() => {
    setShowRewardsBalancePopup(false)
    setShowPopup(false)
  }, [setShowPopup])

  return (
    <Flex alignItems="center" display={['none', 'flex']} height="2.5rem">
      {addLiquidity && (
        <LinkButton
          mr="12px"
          pathname="/pools"
          contrastColor="primary"
          mainColor="white"
          size="medium"
          fontWeight={4}
          px={3}
        >
          <Add size="20" mr={2} />
          {t('addLiquidity')}
        </LinkButton>
      )}
      {burnSmt && isBurnSmtAllowed && (
        <StyledButton
          onClick={on}
          mr="12px"
          contrastColor="primary"
          mainColor="white"
          size="medium"
          fontWeight={4}
          px={3}
        >
          {t('burnSmt')}
        </StyledButton>
      )}
      {networkSelect && (
        <Box mr="12px">
          <NetworkSelect />
        </Box>
      )}
      <SmtContextProvider>
        {currentStep > 0 && smtWidget && (
          <SmtRewardsBadge onClick={openModal} />
        )}

        {appsWidget && <SwarmApps />}
        <ConnectButton />

        <RewardsWalletPopup
          isOpen={showRewardsBalancePopup || showPopup}
          onClose={closeModal}
        />
        <BurnSmtPopup isOpen={isOn} onClose={off} />
      </SmtContextProvider>
    </Flex>
  )
}

const mapStateToProps = ({ user }: AppState) => {
  const { verificationStatus = VerificationStatus.notVerified } = user

  return {
    currentStep: getVerificationStep(verificationStatus),
  }
}
export default connect<HeaderActionsProps>(mapStateToProps)(HeaderActions)
