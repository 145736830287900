import { useEffect, useRef } from 'react'

type C = (...args: any) => void

function useDebounce<T extends (...args: any) => any>(
  callback: T,
  delay: number,
): (...args: Parameters<T>) => void {
  const ref = useRef<NodeJS.Timeout>()

  useEffect(() => {
    return () => {
      clearTimeout(ref.current)
    }
  }, [])

  return useRef((...args: Parameters<C>) => {
    clearTimeout(ref.current)

    ref.current = setTimeout(() => {
      callback(...args)
    }, delay)
  }).current
}

export default useDebounce
