import usePopupState from '@swarm/core/hooks/state/usePopupState'
import { InvestToken } from '@swarm/types/tokens/invest'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Flex } from 'rimble-ui'

import InvestAssetInfoPopup from '../InvestAssetInfoPopup'

import useCardText from './useCardText'

interface CollapsedCardProps {
  token: InvestToken
  onExpand?: () => void
}

const CollapsedCard = ({ token, onExpand }: CollapsedCardProps) => {
  const { t } = useTranslation('invest')
  const infoPopup = usePopupState(false)

  const handleBuyClick = useCallback(async () => {
    onExpand?.()
  }, [onExpand])

  const { description } = useCardText(token.assetType)

  return (
    <Flex
      flexDirection="column"
      p={3}
      justifyContent="space-between"
      flexGrow={1}
    >
      <Box color="grey">{description}</Box>

      <Flex mt={4} justifyContent="flex-end" alignItems="center">
        <Flex style={{ gap: '8px' }}>
          <Button.Text
            height="28px"
            fontSize={1}
            px={2}
            onClick={infoPopup.open}
          >
            {t('stockTokens.actions.info')}
          </Button.Text>
          <Button height="28px" fontSize={1} px={2} onClick={handleBuyClick}>
            {t('stockTokens.actions.own', {
              token: token?.symbol,
            })}
          </Button>
        </Flex>
      </Flex>
      <InvestAssetInfoPopup
        isOpen={infoPopup.isOpen}
        onClose={infoPopup.close}
        asset={token}
      />
    </Flex>
  )
}

export default CollapsedCard
