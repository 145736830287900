import { Button, Flex, Icon, Loader } from 'rimble-ui'
import styled from 'styled-components/macro'

import TokenSelector, {
  TokenSelectorProps,
  TokenSelectorViewProps,
} from '../../TokenSelector'

const TokenButton = styled(Button.Text)`
  height: 32px;
  flex-shrink: 0;

  &:hover {
    text-decoration: none;
  }
`
const TokenSelectorView = ({
  onModalOpen,
  emptyValue,
  readonly,
  loading,
  selected,
}: TokenSelectorViewProps) => (
  <TokenButton onClick={onModalOpen} readonly={readonly}>
    {loading && <Loader m="auto" />}
    {!loading && selected && (
      <Flex alignItems="center">
        {selected?.symbol.toLocaleUpperCase() || '--'}
        <Icon name="ExpandMore" />{' '}
      </Flex>
    )}
    {!loading && !selected && emptyValue}
  </TokenButton>
)

const TokenInput = ({ ...props }: Omit<TokenSelectorProps, 'View'>) => (
  <TokenSelector {...props} View={TokenSelectorView} />
)

export default TokenInput
