import React, { ReactNode } from 'react'
import styled from 'styled-components/macro'

interface DisableWrapperProps {
  children: ReactNode
  disabled?: boolean
}

const Wrapper = styled.div<{ disabled: boolean }>`
  position: relative;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'auto')};
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
`

const DisableWrapper: React.FC<DisableWrapperProps> = ({
  children,
  disabled = false,
}) => {
  return (
    <Wrapper disabled={disabled}>
      {children}
      {disabled && <Overlay />}
    </Wrapper>
  )
}

export default DisableWrapper
