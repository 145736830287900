import { useEffect, useRef } from 'react'

import edgeTag from '@core/services/edgeTag'
import {
  useBlotoutLocalStorage,
  useEdgetagLocalStorage,
} from '@core/shared/localStorage/edgetagLocalStorage'

const useInitEdgetag = () => {
  const { value: edgetagEnabled } = useEdgetagLocalStorage()
  const { value: blotoutEnabled } = useBlotoutLocalStorage()

  const initiatedEdgetagInitializeRef = useRef(false)
  const initiatedEdgeTagRef = useRef(false)

  useEffect(() => {
    if (
      (edgetagEnabled || blotoutEnabled) &&
      !initiatedEdgetagInitializeRef.current
    ) {
      edgeTag.initialize()
      initiatedEdgetagInitializeRef.current = true
    }
  }, [blotoutEnabled, edgetagEnabled])

  useEffect(() => {
    if (edgetagEnabled && !initiatedEdgeTagRef.current) {
      edgeTag.tagPageView()
      initiatedEdgeTagRef.current = true
    }
  }, [edgetagEnabled])
}

export default useInitEdgetag
