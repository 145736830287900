import { Close } from '@rimble/icons'
import usePopupState from '@swarm/core/hooks/state/usePopupState'
import { RefetchXOffersFn } from '@swarm/core/hooks/subgraph/x-dotc/useXDotcOffers'
import { ChildrenProps } from '@swarm/types'
import { NormalizedXOffer } from '@swarm/types/normalized-entities/x-offer'
import { Drawer } from '@swarm/ui/presentational/Drawer'
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Flex, Heading } from 'rimble-ui'

import TakeOfferForm from './TakeOfferForm'

interface TakeOfferPopupProps {
  isOpen: boolean
  onClose: () => void
  children: ReactNode
}

export const TakeOfferPopup = ({
  isOpen,
  onClose,
  children,
}: TakeOfferPopupProps) => {
  const { t } = useTranslation('otc')

  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose}>
      <Flex
        p={['16px', '24px']}
        flexDirection="column"
        width="500px"
        maxWidth={['100vw', '100vw', 'calc(100vw - 304px)']}
        height="100vh"
        overflowY="auto"
      >
        <Flex alignItems="center" justifyContent="space-between" mb={3}>
          <Heading
            as="h3"
            fontSize={3}
            lineHeight="28px"
            fontWeight={5}
            color="grey"
            mt={0}
            mb={0}
          >
            {`${t('confirmation')}:`}
          </Heading>
          <Close onClick={onClose} />
        </Flex>
        {children}
      </Flex>
    </Drawer>
  )
}

export const TakeOfferPopupContext = createContext<{
  openTakeOfferPopup: (offer: NormalizedXOffer) => void
  closeTakeOfferPopup: () => void
}>({
  openTakeOfferPopup: () => {
    throw new Error(
      "Can't call openTakeOfferPopup outside of TakeOfferPopupProvider",
    )
  },
  closeTakeOfferPopup: () => {
    throw new Error(
      "Can't call closeTakeOfferPopup outside of TakeOfferPopupProvider",
    )
  },
})

interface TakeOfferPopupProviderProps extends ChildrenProps {
  reload: RefetchXOffersFn
}

export const TakeOfferPopupProvider = ({
  children,
  reload,
}: TakeOfferPopupProviderProps) => {
  const [offer, setOffer] = useState<NormalizedXOffer | null>(null)

  const { isOpen, open, close } = usePopupState(false)
  const history = useHistory()

  const openTakeOfferPopup = useCallback(
    (selectedOffer: NormalizedXOffer) => {
      setOffer(selectedOffer)
      open()
    },
    [open],
  )

  const closeTakeOfferPopup = useCallback(() => {
    setOffer(null)
    history.push({ ...history.location, hash: undefined })
    close()
  }, [close, history])

  const value = useMemo(() => {
    return { openTakeOfferPopup, closeTakeOfferPopup }
  }, [openTakeOfferPopup, closeTakeOfferPopup])

  return (
    <TakeOfferPopupContext.Provider value={value}>
      {children}
      <TakeOfferPopup isOpen={isOpen} onClose={closeTakeOfferPopup}>
        {offer ? (
          <TakeOfferForm
            offer={offer}
            reload={reload}
            closeDrawer={closeTakeOfferPopup}
          />
        ) : null}
      </TakeOfferPopup>
    </TakeOfferPopupContext.Provider>
  )
}

export const useTakeOfferPopup = () => useContext(TakeOfferPopupContext)
