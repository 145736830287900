import { NormalizedXOffer } from '@swarm/types/normalized-entities/x-offer'
import Dialog from '@swarm/ui/presentational/Dialog'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Heading } from 'rimble-ui'

import { generateShareLink } from '../utils'

import CopyInput from './CopyInput'
import ShareButtons from './ShareButtons'

interface ShareGoldOfferModalProps {
  isOpen: boolean
  offer: NormalizedXOffer
  onClose: () => void
}

const ShareGoldOfferModal = ({
  isOpen,
  onClose,
  offer,
}: ShareGoldOfferModalProps) => {
  const { t } = useTranslation('otc', { keyPrefix: 'shareModal' })
  const shareLink = useMemo(() => generateShareLink(offer.id), [offer.id])

  return (
    <Dialog isOpen={isOpen} maxWidth="min-content">
      <Button.Text
        icononly
        bg="transparent"
        mainColor="grey"
        icon="Close"
        position="absolute"
        top="34px"
        right="18px"
        height="28px"
        onClick={onClose}
        boxShadow={0}
      />
      <Heading color="text" as="h4" fontSize={4} fontWeight={5} mt={0}>
        {t('heading')}
      </Heading>
      <ShareButtons link={shareLink} />
      <CopyInput
        inputProps={{
          width: '100%',
        }}
        width="100%"
        height="40px"
        value={shareLink} // TODO: implement link generation
        mt="24px"
      />
    </Dialog>
  )
}

export default ShareGoldOfferModal
