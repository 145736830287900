import React from 'react'
import { Icon } from 'rimble-ui'
import styled from 'styled-components/macro'

const VisibilityIcon = styled(Icon)<{ visible: boolean }>`
  width: 24px;
  height: 24px;
  color: ${({ theme, visible }) =>
    visible ? theme.colors.primary : theme.colors.grey};
  &:hover {
    color: ${({ theme, visible }) =>
      !visible ? theme.colors.primary : theme.colors.grey};
    cursor: pointer;
  }
`

interface HoverableVisibilityIconProps {
  visible: boolean
  onClick: (e: React.MouseEvent) => void
}

const HoverableVisibilityIcon = ({
  visible,
  onClick,
}: HoverableVisibilityIconProps) => {
  const iconName = visible ? 'Visibility' : 'VisibilityOff'

  return (
    <VisibilityIcon
      size="24px"
      visible={visible}
      onClick={onClick}
      name={iconName}
    />
  )
}

export default HoverableVisibilityIcon
