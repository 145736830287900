import { useState } from 'react'
import { Flex } from 'rimble-ui'
import styled from 'styled-components/macro'

import Footer from './Footer'
import Header from './Header'
import Menu from './Menu'

export const StyledNavigation = styled(Flex)`
  flex-direction: column;
  min-width: 250px;
  height: ${({ open }) => (open ? '140vh' : '48px')};

  z-index: ${({ theme }) => theme.zIndices.navigation};

  @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    flex-shrink: 0;
    height: 100vh;
    max-width: 290px;
  }
`

const Navigation = () => {
  const [open, setOpen] = useState<boolean>(false)

  const toggleMenu = () => setOpen((prev: boolean) => !prev)

  return (
    <StyledNavigation open={open}>
      <Header open={open} toggleMenu={toggleMenu} networkSelect={false} />
      <Menu open={open} />
      <Footer isMobile={false} />
    </StyledNavigation>
  )
}

export default Navigation
