import useDebounce from '@swarm/core/hooks/useDebounce'
import {
  allowancesLoading,
  injectXDotcAllowance,
  useInjections,
} from '@swarm/core/shared/utils/tokens'
import { useAccount } from '@swarm/core/web3'
import { ChildrenProps, TokenPair } from '@swarm/types'
import { HasAllowance, TokenSelectorAsset } from '@swarm/types/tokens'
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import useSelectorTokens from 'src/hooks/data/token-selector/useSelectorTokens'

export interface CreateOfferContextType {
  tokens: TokenSelectorAsset[]
  loading: boolean
  allowanceLoading: boolean
  bestPrice: number
  tokenIn?: TokenSelectorAsset & HasAllowance
  tokenOut?: TokenSelectorAsset
  setTokenPair: (pair: TokenPair) => void
  setSearch: (address: string) => void
}

export const CreateOfferContext = createContext<CreateOfferContextType>({
  tokens: [],
  loading: true,
  allowanceLoading: false,
  bestPrice: 0,
  setTokenPair: () => {},
  setSearch: () => {},
})

export const CreateOfferContextProvider = ({ children }: ChildrenProps) => {
  const account = useAccount()
  const [{ tokenIn, tokenOut }, setTokens] = useState<TokenPair>({})

  const [search, setSearch] = useState<string>('')

  const debouncedSearch = useDebounce((value: string) => {
    setSearch(value)
  }, 1000)

  const { tokens, loading: tokensLoading } = useSelectorTokens(search)

  const injectedTokens = useInjections<TokenSelectorAsset & HasAllowance>(
    tokenIn ? [tokenIn].filter(Boolean) : [],
    useMemo(() => [injectXDotcAllowance(account)], [account]),
  )

  const [injectedTokenIn] = injectedTokens

  const areAllowancesLoading = allowancesLoading(injectedTokens)

  const bestPrice = 0

  const setTokenPair = useCallback((pair: TokenPair) => {
    if (pair?.tokenIn) {
      setTokens((prevPair) => ({
        tokenIn: pair.tokenIn,
        tokenOut: prevPair.tokenOut,
      }))
    }

    if (pair?.tokenOut) {
      setTokens((prevPair) => ({
        tokenIn: prevPair.tokenIn,
        tokenOut: pair.tokenOut,
      }))
    }
  }, [])

  const value = useMemo(
    () => ({
      tokens,
      bestPrice,
      tokenIn: injectedTokenIn,
      tokenOut: tokenOut,
      allowanceLoading: areAllowancesLoading,
      setTokenPair,
      setSearch: debouncedSearch,
      loading: tokensLoading,
    }),
    [
      tokens,
      injectedTokenIn,
      tokenOut,
      areAllowancesLoading,
      setTokenPair,
      debouncedSearch,
      tokensLoading,
    ],
  )

  return (
    <CreateOfferContext.Provider value={value}>
      {children}
    </CreateOfferContext.Provider>
  )
}

export const useCreateOfferContext = () => useContext(CreateOfferContext)
