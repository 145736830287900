export enum BlotoutEvent {
  MAP_ID = 'map_id',
  WALLET_CONNECTED = 'wallet_connected',
  SIGNED_MESSAGE = 'signed_message',
  KYC_START = 'kyc_start',
  EMAIL_VERIFIED = 'email_verified',
  START_ONBOARDING = 'start_onboarding',
  AGREE_TERMS = 'agree_terms',
  PDF_SIGNED = 'pdf_signed',
  ADDITIONAL_ADDRESS_ADD = 'additional_address_add',
  ADDITIONAL_ADDRESS_REMOVE = 'additional_address_remove',
  REFERENCE_PAYMENT_STATUS = 'reference_payment_status',
  ENABLE_ASSET = 'enable_asset',
  DISABLE_ASSET = 'disable_asset',
  SWAP = 'swap',
  ADD_LIQUIDITY = 'add_liquidity',
  REMOVE_LIQUIDITY = 'remove_liquidity',
  CLAIM_SMT_REWARDS = 'claim_smt_rewards',
  UNWRAP_VSMT = 'unwrap_vsmt',
  BUY_STAKING_NODE = 'buy_staking_node',
  REDEEM_STAKING_NODE = 'redeem_staking_node',
  BUY_STOCK_TOKEN = 'buy_stock_tokens',
  DOTC_OFFER_CREATED = 'dotc_offer_created',
  DOTC_OFFER_TAKEN = 'dotc_offer_taken',
  DOTC_OFFER_UPDATED = 'dotc_offer_updated',
  DOTC_OFFER_CANCELED = 'dotc_offer_canceled',
}
