import useGroupSelect from '@swarm/core/hooks/state/useGroupSelect'
import TokenActionButton from '@swarm/ui/swarm/TokenSelector/components/TokenActionButton'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Flex } from 'rimble-ui'

export enum FilterMode {
  hideTokens = 'hideTokens',
  filterTokens = 'filterTokens',
}

export enum FilterTokensType {
  ALL = 'all',
  NFTS = 'nfts',
  CRYPTO = 'crypto',
  GOLD = 'gold',
  STOCKS_BONDS = 'stocksBonds',
}

interface TokenManagerActionsProps {
  filterMode: FilterMode
  initialHiddenTokens?: string[]
  onChangeMode(mode: FilterMode): void
  onUpdateTokensType(tokensType: FilterTokensType): void
  onUpdateHiddenTokens(tokens: string[]): void
  onDeselectAll(): void
}

const TokenManagerActions = ({
  filterMode,
  initialHiddenTokens,
  onChangeMode,
  onDeselectAll,
  onUpdateHiddenTokens,
  onUpdateTokensType,
}: TokenManagerActionsProps) => {
  const { t } = useTranslation('common', { keyPrefix: 'manageTokensActions' })
  const { isSelected, toggleSelect } = useGroupSelect<FilterTokensType>([
    FilterTokensType.ALL,
  ])

  const [initialTokens, setInitialTokens] = useState(initialHiddenTokens)
  const latestTokens = useRef(false)
  useEffect(() => {
    // Keep initialTokens to view them on discardChanges
    const handleInitialTokens = () => {
      if (latestTokens.current) return
      setInitialTokens(initialHiddenTokens)
      latestTokens.current = true
    }
    handleInitialTokens()
  }, [initialHiddenTokens, filterMode])

  const handleToggleChangeMode = () => {
    const newFilterMode =
      filterMode === FilterMode.filterTokens
        ? FilterMode.hideTokens
        : FilterMode.filterTokens
    onChangeMode(newFilterMode)
    latestTokens.current = false
  }

  const handleToggleFilter = useCallback(
    (key: FilterTokensType) => {
      toggleSelect(key)

      onUpdateTokensType(key)
    },
    [onUpdateTokensType, toggleSelect],
  )

  const handleDiscardChanges = () => {
    if (!initialTokens) return
    onUpdateHiddenTokens(initialTokens)
    handleToggleChangeMode()
  }

  const options = useMemo(
    () => [
      {
        value: FilterTokensType.ALL,
        label: t('all'),
      },
      {
        value: FilterTokensType.CRYPTO,
        label: t('crypto'),
      },
      {
        value: FilterTokensType.STOCKS_BONDS,
        label: t('stocksBonds'),
      },
      {
        value: FilterTokensType.GOLD,
        label: t('gold'),
      },
      {
        value: FilterTokensType.NFTS,
        label: t('nfts'),
      },
    ],
    [t],
  )

  if (filterMode === 'filterTokens') {
    return (
      <Flex justifyContent="space-between" alignItems="center" marginTop="20px">
        <Flex alignItems="center" style={{ gap: 8 }}>
          {options.map(({ value, label }) => (
            <TokenActionButton
              key={label}
              selected={isSelected(value)}
              onClick={() => {
                handleToggleFilter(value)
              }}
              label={label}
            />
          ))}
        </Flex>
        {/* <Button.Text
          onClick={handleToggleChangeMode}
          icon="Visibility"
          fontSize="14px"
          height="20px"
        >
          {t('hideTokens')}
        </Button.Text> */}
      </Flex>
    )
  }

  return (
    <Flex justifyContent="flex-end" marginTop="20px" style={{ gap: 8 }}>
      <TokenActionButton onClick={handleDiscardChanges} label={t('cancel')} />
      <TokenActionButton onClick={onDeselectAll} label={t('deselectAll')} />
      <TokenActionButton
        selected
        onClick={handleToggleChangeMode}
        label={t('save')}
      />
    </Flex>
  )
}

export default TokenManagerActions
