import { ExtractProps } from '@swarm/types/props'
import { useSnackbar } from '@swarm/ui/swarm/Snackbar'
import { AlertVariant } from '@swarm/ui/swarm/Snackbar/types'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Flex, Input } from 'rimble-ui'

interface CopyInputProps extends ExtractProps<typeof Flex> {
  value: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  inputProps?: ExtractProps<typeof Input>
  readOnly?: boolean
}

const CopyInput = ({
  value,
  onChange,
  inputProps,
  readOnly = true,
  ...rest
}: CopyInputProps) => {
  const { t } = useTranslation('otc', { keyPrefix: 'copyInput' })
  const { addAlert } = useSnackbar()

  const copy = () => {
    navigator.clipboard.writeText(value)
    addAlert(t('copiedMessage'), {
      variant: AlertVariant.success,
    })
  }

  return (
    <Flex {...rest}>
      <Input
        readOnly={readOnly}
        onChange={onChange}
        bg="white"
        height="100%"
        borderTopRightRadius="0px !important"
        borderBottomRightRadius="0px !important"
        borderRight="none !important"
        value={value}
        {...inputProps}
      />
      <Button
        borderTopLeftRadius="0px !important"
        borderBottomLeftRadius="0px !important"
        onClick={copy}
        height="100%"
      >
        {t('copy')}
      </Button>
    </Flex>
  )
}

export default CopyInput
