import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import config from '@swarm/core/config'
import { BasicModalProps } from '@swarm/core/hooks/state/usePopupState'
import { useTermsAndAgreementsStorage } from '@swarm/core/shared/localStorage'
import Dialog from '@swarm/ui/presentational/Dialog'
import React, { useState } from 'react'
import { TFunction, Trans, useTranslation } from 'react-i18next'
import { Box, Button, Heading, Link, Text } from 'rimble-ui'
import styled from 'styled-components/macro'

const CheckboxMarkupItem = styled(FormControlLabel)`
  margin-bottom: 15px;
`

const { terms } = config.resources.swarmX

interface CheckboxLabelProps {
  i18nKey: string
  t: TFunction
}

const TermsLabel = ({ i18nKey, t }: CheckboxLabelProps) => {
  return (
    <Text color="black" fontSize={2} fontWeight={3}>
      <Trans
        t={t}
        i18nKey={i18nKey}
        components={[
          <Link
            key={1}
            href={terms.termsAndAgreements}
            color="primary"
            hoverColor="dark-gray"
            fontSize={2}
            fontWeight={3}
            style={{ textDecoration: 'underline' }}
            target="_blank"
          />,
        ]}
      />
    </Text>
  )
}

const TermsAndAgreementsModal = ({ isOpen, onClose }: BasicModalProps) => {
  const { setValue } = useTermsAndAgreementsStorage()
  const { t } = useTranslation('modals')
  const [clickCounts, setClickCounts] = useState(0)

  const requiredClicks = 3

  const changeCheckbox = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (evt.target.checked) {
      setClickCounts(clickCounts + 1)
    } else {
      setClickCounts(clickCounts - 1)
    }
  }

  return (
    <Dialog isOpen={isOpen} onClose={onClose} width={['100%', '630px']}>
      <Box m="15px">
        <Heading.h2 mt={0}>{t('termsAndAgreements.header')}</Heading.h2>

        <Text fontSize={3} color="black" mb={3}>
          {t('termsAndAgreements.agreeTitle')}
        </Text>
        <CheckboxMarkupItem
          control={<Checkbox color="primary" onChange={changeCheckbox} />}
          label={<TermsLabel t={t} i18nKey="termsAndAgreements.1" />}
        />
        <CheckboxMarkupItem
          control={<Checkbox color="primary" onChange={changeCheckbox} />}
          label={<TermsLabel t={t} i18nKey="termsAndAgreements.2" />}
        />
        <CheckboxMarkupItem
          control={<Checkbox color="primary" onChange={changeCheckbox} />}
          label={
            <Text color="black" fontSize={2} fontWeight={3}>
              {t('termsAndAgreements.3')}
            </Text>
          }
        />

        <Text color="black" fontSize={2} fontWeight={3} mb={2}>
          {t('termsAndAgreements.underterm')}
        </Text>

        <Button
          size="medium"
          px={3}
          mt="10px"
          width="100%"
          color="primary"
          onClick={() => setValue(true)}
          disabled={clickCounts < requiredClicks}
        >
          {t('termsAndAgreements.agree')}
        </Button>
      </Box>
    </Dialog>
  )
}

export default TermsAndAgreementsModal
