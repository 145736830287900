import FormControl from '@material-ui/core/FormControl'
import { useAccount } from '@swarm/core/web3'
import { AbstractAsset, TokenSelectorAsset } from '@swarm/types/tokens'
import TokenBalance from '@swarm/ui/swarm/TokenBalance'
import TokenIcon from '@swarm/ui/swarm/TokenIcon'
import TokenSelector, {
  TokenSelectorProps,
  TokenSelectorViewProps,
} from '@swarm/ui/swarm/TokenSelector'
import { TokenSelectorButton } from '@swarm/ui/swarm/TokenSelector/TokenSelectorView'
import { Loader, Text } from 'rimble-ui'
import styled from 'styled-components/macro'

const StyledFormControl = styled(FormControl)`
  /* 
    InputLabel sets a z-index on the label so we need to reset its stacking context
    so it doesn't overlap with other items in the page like the header
  */
  position: relative;
  transform: translateZ(0);
  align-self: self-start;
`

interface TokenSelectProps<T extends AbstractAsset = AbstractAsset>
  extends Pick<TokenSelectorProps<T>, 'orderBy' | 'orderDirection'> {
  selectedToken?: T
  // value to display when token is not selected
  emptyValue?: string
  tokens: T[]
  filter?: (token: T) => boolean
  onChange: (token?: T) => void
  tokensLoading?: boolean
  label?: string
  showTokenInfo?: boolean
  groupNFTsByAddress?: boolean
}

type CustomTokenSelectProps<T extends TokenSelectorAsset = TokenSelectorAsset> =
  TokenSelectorViewProps<T> & { label?: string }

const StyledTokenButton = styled(TokenSelectorButton)`
  padding-left: 14px;
  padding-right: 14px;
`

const StyledLabel = styled(Text.span)`
  position: absolute;
  top: -12px;
  left: 3px;
  color: ${({ theme }) => theme.colors.grey};
  transform: scale(0.75);
  background: ${({ theme }) => theme.colors.white};
  padding: 0 5px;
  z-index: ${({ theme }) => theme.zIndices.layerOne};
`

const TokenSelectorView = <T extends TokenSelectorAsset = TokenSelectorAsset>({
  selected,
  loading = false,
  emptyValue,
  readonly = false,
  onModalOpen,
  label,
}: CustomTokenSelectProps<T>) => {
  const account = useAccount()

  return (
    <>
      {label && <StyledLabel>{label}</StyledLabel>}

      <StyledTokenButton onClick={onModalOpen} readonly={readonly}>
        {loading && <Loader m="auto" />}
        {!loading && selected && (
          <>
            <TokenIcon
              mr={3}
              symbol={selected?.symbol}
              name={selected?.name}
              width="24px"
              height="24px"
            />
            {selected?.symbol?.toLocaleUpperCase() || '--'}
          </>
        )}
        {!loading && !selected && emptyValue}
      </StyledTokenButton>

      <Text fontSize="12px" fontWeight={2} color="grey" mx="14px" height="18px">
        <TokenBalance
          hideEmpty
          tokenAddress={selected?.id}
          account={account}
          base={4}
        />
      </Text>
    </>
  )
}

const TokenSelect = <T extends TokenSelectorAsset = TokenSelectorAsset>({
  selectedToken,
  emptyValue,
  tokens,
  tokensLoading = true,
  filter,
  onChange,
  label,
  ...props
}: TokenSelectProps<T>) => {
  return (
    <StyledFormControl variant="outlined">
      {tokensLoading ? (
        <Loader mt={3} mx={3} />
      ) : (
        <TokenSelector<T>
          {...props}
          selected={selectedToken}
          tokens={tokens}
          loading={tokensLoading}
          filter={filter}
          onChange={onChange}
          emptyValue={<Text.span color="primary">{emptyValue}</Text.span>}
          View={(viewProps) => (
            <TokenSelectorView label={label} {...viewProps} />
          )}
        />
      )}
    </StyledFormControl>
  )
}

export default TokenSelect
