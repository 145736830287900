import useDebounce from '@swarm/core/hooks/useDebounce'
import { normalizeDotcAsset } from '@swarm/core/shared/utils/subgraph/x-dotc'
import { ChildrenProps, TokenPair } from '@swarm/types'
import { NormalizedXOffer } from '@swarm/types/normalized-entities/x-offer'
import { TokenSelectorAsset } from '@swarm/types/tokens'
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import useSelectorTokens from 'src/hooks/data/token-selector/useSelectorTokens'

export interface EditOfferContextType {
  tokens: TokenSelectorAsset[]
  loading: boolean
  bestPrice: number
  tokenIn?: TokenSelectorAsset
  tokenOut?: TokenSelectorAsset
  setTokenPair: (pair: TokenPair) => void
  setSearch: (address: string) => void
}

export const EditOfferContext = createContext<EditOfferContextType>({
  tokens: [],
  loading: true,
  bestPrice: 0,
  setTokenPair: () => {},
  setSearch: () => {},
})

interface EditOfferContextProviderProps extends ChildrenProps {
  offer?: NormalizedXOffer
}

export const EditOfferContextProvider = ({
  offer,
  children,
}: EditOfferContextProviderProps) => {
  const [{ tokenIn, tokenOut }, setTokens] = useState<TokenPair>({
    tokenIn: offer?.depositAsset
      ? normalizeDotcAsset(offer?.depositAsset)
      : undefined,
    tokenOut: offer?.withdrawalAsset
      ? normalizeDotcAsset(offer?.withdrawalAsset)
      : undefined,
  })

  const [search, setSearch] = useState<string>('')

  const debouncedSearch = useDebounce((value: string) => {
    setSearch(value)
  }, 1000)

  const { tokens, loading: tokensLoading } = useSelectorTokens(search)

  const bestPrice = 0

  const setTokenPair = useCallback((pair: TokenPair) => {
    if (pair?.tokenIn) {
      setTokens((prevPair) => ({
        tokenIn: pair.tokenIn,
        tokenOut: prevPair.tokenOut,
      }))
    }

    if (pair?.tokenOut) {
      setTokens((prevPair) => ({
        tokenIn: prevPair.tokenIn,
        tokenOut: pair.tokenOut,
      }))
    }
  }, [])

  const value = useMemo(
    () => ({
      tokens,
      bestPrice,
      tokenIn,
      tokenOut,
      setTokenPair,
      setSearch: debouncedSearch,
      loading: tokensLoading,
    }),
    [tokens, tokenIn, tokenOut, setTokenPair, debouncedSearch, tokensLoading],
  )

  return (
    <EditOfferContext.Provider value={value}>
      {children}
    </EditOfferContext.Provider>
  )
}

export const useEditOfferContext = () => useContext(EditOfferContext)
