import NetworkFeature, {
  NetworkFeatureName,
} from '@swarm/core/services/networkFeatures'
import { ChildrenProps } from '@swarm/types'
import match from 'conditional-expression'
import { useParams } from 'react-router'
import { Card } from 'rimble-ui'

import { CreateOfferPopupProvider } from '../CreateOffer'
import { DotcContextProvider } from '../DotcContext'

import GoldOffersTab from './GoldOffersTab'
import MyOffersTab from './MyOffersTab'
import SharedOffersTab from './SharedOffersTab'

const TabWrapper = ({ children }: ChildrenProps) => {
  return (
    <Card
      role="tabpanel"
      width="100%"
      height="100%"
      p={4}
      borderRadius={1}
      overflow="hidden"
      display="flex"
      flexDirection="column"
      justifyContent="stretch"
    >
      {children}
    </Card>
  )
}

const DotcTabs = () => {
  const { category = 'shared' } = useParams<{ category?: string }>()

  return (
    <DotcContextProvider>
      <CreateOfferPopupProvider>
        <TabWrapper>
          {match(category)
            .equals('shared')
            .then(<SharedOffersTab />)
            .equals('my-offers')
            .then(<MyOffersTab />)
            .equals('gold')
            .then(
              NetworkFeature.ifSupported(
                NetworkFeatureName.goldNfts,
                <GoldOffersTab />,
                <SharedOffersTab />,
              ),
            )
            .else(<SharedOffersTab />)}
        </TabWrapper>
      </CreateOfferPopupProvider>
    </DotcContextProvider>
  )
}

export default DotcTabs
