import { StyledTabs } from '@swarm/ui/presentational/StyledTabs'
import StyledTab from '@swarm/ui/swarm/StyledTab'
import { useTranslation } from 'react-i18next'
import { generatePath, useHistory, useParams } from 'react-router'

import { ROUTES } from 'src/routes'

import { useInvestContext } from './InvestContext'

const categories = {
  'all-assets': 0,
  'stock-tokens': 1,
  bonds: 2,
  'index-tokens': 3,
}

const tabs: Array<keyof typeof categories> = [
  'all-assets',
  'stock-tokens',
  'bonds',
  'index-tokens',
]

const InvestSubheader = () => {
  const { t } = useTranslation('invest')
  const history = useHistory()
  const { category = 'all-assets' } = useParams<{
    category?: keyof typeof categories
  }>()

  const { stockTokens, bonds, indexTokens } = useInvestContext()

  const switchTab = (tab: keyof typeof categories) => {
    if (tab === tabs[0]) {
      history.push(ROUTES.INVEST)
    } else {
      history.push(generatePath(ROUTES.INVEST_CATEGORY, { category: tab }))
    }
  }

  const handleSwitchTab = (_: unknown, index: number) => {
    if (index !== categories[category]) {
      switchTab(tabs[index])
    }
  }

  const showBondsTab = bonds.length > 0
  const showIndexTab = indexTokens.length > 0
  const showStockTab = stockTokens.length > 0

  if (!showStockTab && !showBondsTab && !showIndexTab) {
    return null
  }

  return (
    <StyledTabs
      value={categories[category]}
      onChange={handleSwitchTab}
      aria-label="Invest tabs"
    >
      <StyledTab label={t('tabs.allAssets')} value={categories['all-assets']} />
      {showStockTab ? (
        <StyledTab
          label={t('tabs.stockTokens')}
          value={categories['stock-tokens']}
        />
      ) : null}
      {showBondsTab ? (
        <StyledTab label={t('tabs.bonds')} value={categories.bonds} />
      ) : null}
      {showIndexTab ? (
        <StyledTab
          label={t('tabs.indexTokens')}
          value={categories['index-tokens']}
        />
      ) : null}
    </StyledTabs>
  )
}

export default InvestSubheader
