import { NetworkStatus, QueryHookOptions, useQuery } from '@apollo/client'
import {
  Sx1155AssetType,
  Sx1155TokenId,
  Sx1155TokenIdFilter,
  Sx1155TokenIdsQueryVariables,
} from '@swarm/types/x-subgraph/graphql'
import isEqual from 'lodash/isEqual'
import { useCallback, useMemo, useState } from 'react'

import { Sx1155TokenIdsQuery } from '@core/queries'
import { POLL_INTERVAL } from '@core/shared/consts'
import { TokenType } from '@core/shared/enums'
import { injectNftBalance, useInjections } from '@core/shared/utils/tokens'
import { isGoldByAddress } from '@core/shared/utils/tokens/gold'
import { useAccount } from '@core/web3'

interface QueryResponse {
  sx1155TokenIds: Sx1155TokenId[]
}

export type RefetchSx1155TokenIdsFn = (
  filter?: Sx1155TokenIdFilter,
) => Promise<Sx1155TokenId[]>

export const PageLimit = 100

const useSx1155TokenIds = (
  options?: QueryHookOptions<QueryResponse, Sx1155TokenIdsQueryVariables>,
) => {
  const account = useAccount()
  const [hasMore, setHasMore] = useState(true)

  const {
    data,
    loading,
    error,
    called,
    fetchMore: nativeFetchMore,
    networkStatus,
    refetch: nativeRefetch,
  } = useQuery<QueryResponse>(Sx1155TokenIdsQuery, {
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    pollInterval: POLL_INTERVAL,
    ...options,
    variables: {
      limit: PageLimit,
      skip: 0,
      filter: options?.variables?.filter,
    },
  })

  const [prevFilter, setPrevFilter] = useState(options?.variables?.filter)

  if (!isEqual(options?.variables?.filter, prevFilter)) {
    setPrevFilter(options?.variables?.filter)
    setHasMore(true)
  }

  const fetchMore = useCallback(
    async (skip: number, limit?: number) => {
      const offersResponse = await nativeFetchMore({
        variables: {
          ...options?.variables,
          ...(limit && { limit }),
          skip,
          filter: options?.variables?.filter,
        },
      })
      if (offersResponse.data.sx1155TokenIds.length < PageLimit) {
        setHasMore(false)
      }

      return offersResponse
    },
    [nativeFetchMore, options?.variables],
  )

  const refetch = useCallback<RefetchSx1155TokenIdsFn>(
    (filter) =>
      nativeRefetch({
        filter,
        limit: PageLimit,
        skip: 0,
      }).then((res) => {
        return res.data?.sx1155TokenIds || []
      }),
    [nativeRefetch],
  )

  const sx1155TokenIds = useMemo(
    () =>
      data?.sx1155TokenIds.map((token) => ({
        id: token.id,
        name: token.parentNFT.name,
        symbol: token.parentNFT.symbol,
        tokenId: token.id.split('-')[1],
        address: token.parentNFT.id,
        kya: token.kya,
        assetType: token.assetType,
        type: TokenType.erc1155,
        rwaType: isGoldByAddress(token.parentNFT.id)
          ? Sx1155AssetType.Gold
          : Sx1155AssetType.NotSet,
      })) || [],
    [data?.sx1155TokenIds],
  )

  const fullSx1155TokenIds = useInjections(
    sx1155TokenIds,
    useMemo(() => [injectNftBalance(account)], [account]),
  )

  return useMemo(
    () => ({
      sx1155TokenIds: fullSx1155TokenIds,
      loadingOffers:
        loading ||
        ![
          NetworkStatus.ready,
          NetworkStatus.error,
          NetworkStatus.poll,
        ].includes(networkStatus),
      error,
      called,
      refetch,
      refetching: networkStatus === NetworkStatus.refetch,
      fetchMore,
      hasMore,
    }),
    [
      fullSx1155TokenIds,
      loading,
      networkStatus,
      error,
      called,
      refetch,
      fetchMore,
      hasMore,
    ],
  )
}

export default useSx1155TokenIds
