import useAllowance from '@swarm/core/hooks/web3/useAllowance'
import useBalance from '@swarm/core/hooks/web3/useBalance'
import useEnableAsset from '@swarm/core/hooks/web3/useEnableAsset'
import { getCurrentConfig } from '@swarm/core/observables/configForNetwork'
import { prettifyBalance } from '@swarm/core/shared/utils/formatting'
import { big, min, safeDiv } from '@swarm/core/shared/utils/helpers/big-helpers'
import wait from '@swarm/core/shared/utils/helpers/wait'
import { normalizeUSDCE } from '@swarm/core/shared/utils/tokens'
import { useAccount } from '@swarm/core/web3'
import { NormalizedXOffer } from '@swarm/types/normalized-entities/x-offer'
import { DotcAsset } from '@swarm/types/tokens/dotc'
import Label from '@swarm/ui/presentational/Form/Label'
import Grid from '@swarm/ui/presentational/Grid'
import SmartButton from '@swarm/ui/swarm/Buttons/SmartButton'
import MaxInput from '@swarm/ui/swarm/MaxInput'
import SvgIcon from '@swarm/ui/swarm/SvgIcon'
import TokenSelector from '@swarm/ui/swarm/TokenSelector'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Flex, Loader, Text } from 'rimble-ui'

import useTakeOffer from 'src/hooks/web3/open-otc/useTakeOffer'

import { useDotcContext } from '../DotcContext'

import TakeOfferDetails from './TakeOfferDetails'

const { xDotcAddress } = getCurrentConfig()

const TakeOfferForm = ({
  offer,
  reload,
  closeDrawer,
}: {
  offer: NormalizedXOffer
  reload: () => void
  closeDrawer: () => void
}) => {
  const { t } = useTranslation(['otc', 'errors'])
  const account = useAccount()
  const [enableAsset, { txLoading: enableTxLoading }] = useEnableAsset()
  const [takeOffer, { txLoading: takeOfferTxLoading }] = useTakeOffer(offer.id)

  const { depositAsset, withdrawalAsset } = offer

  const [amountIn, setAmountIn] = useState(
    offer.isFullType ? offer.amountIn : '0',
  )

  const amountPaid = offer.isFullType
    ? offer.amountOut
    : big(amountIn).mul(offer.price).toString()

  const { tokens, tokensDictionary, tokensLoading } = useDotcContext()

  const tokenIn = normalizeUSDCE(
    tokensDictionary.get(depositAsset.id) as DotcAsset,
  )
  const tokenOut = normalizeUSDCE(
    tokensDictionary.get(withdrawalAsset.id) as DotcAsset,
  )

  const [tokenOutAllowance, { allowanceLoading }] = useAllowance({
    account,
    spender: xDotcAddress,
    asset: tokenOut.id,
    type: tokenOut.type,
  })

  const handleAmountInChange = useCallback((_: number, value: string) => {
    if (!Number.isNaN(value)) {
      setAmountIn(value)
    }
  }, [])

  const handleTakeOffer = useCallback(async () => {
    if (!tokenOut) return
    await takeOffer(amountPaid, tokenOut)
    await wait(4000)
    await reload()
    closeDrawer()
  }, [amountPaid, closeDrawer, reload, takeOffer, tokenOut])

  const handleConfirmationButtonClick = useCallback(async () => {
    if (!tokenIn || !tokenOut || !tokenOut.type) return undefined

    if (tokenOut && tokenOutAllowance?.lt(amountPaid)) {
      return enableAsset(tokenOut, xDotcAddress, tokenOut.type)
    }

    return handleTakeOffer()
  }, [
    tokenIn,
    tokenOut,
    tokenOutAllowance,
    amountPaid,
    handleTakeOffer,
    enableAsset,
  ])

  const amountAvailable = big(amountIn).gt(offer.availableAmount)

  const [tokenOutBalance, { balanceLoading }] = useBalance(account, tokenOut)

  const insufficientBalance =
    big(offer.price)
      .times(amountIn)
      .gt(tokenOutBalance || 0) || tokenOutBalance?.eq(0)

  const errorAmountAvailable = amountAvailable && 'Amount not available'

  const errorInsufficientBalance =
    insufficientBalance &&
    t('errors:insufficientBalance', { symbol: tokenOut?.symbol })

  const maxValue =
    (tokenOutBalance &&
      min(offer.availableAmount, safeDiv(tokenOutBalance, offer.price))) ??
    0

  const disabled =
    !account ||
    takeOfferTxLoading ||
    big(amountIn).eq(0) ||
    !tokenIn ||
    !tokenOut ||
    insufficientBalance ||
    errorAmountAvailable ||
    tokensLoading ||
    enableTxLoading ||
    allowanceLoading

  const actionButtonLoading =
    balanceLoading || takeOfferTxLoading || enableTxLoading || allowanceLoading

  const amountInInputDisabled =
    offer.isFullType || tokensLoading || balanceLoading

  return (
    <>
      <Grid mt={24} gridTemplateColumns="2fr 150px" gridGap={[2, 3]}>
        <Flex width="100%" flexDirection="column" justifyContent="flex-end">
          <Label mobile> {t('iWantToBuy', { ns: 'otc' })}</Label>
          <Box position="relative">
            <MaxInput
              onChange={handleAmountInChange}
              value={amountIn}
              decimalScale={tokenIn?.decimals}
              height="48px"
              px="16px"
              max={maxValue}
              disabled={amountInInputDisabled}
            />
          </Box>
        </Flex>
        <Flex width="100%" flexDirection="column" justifyContent="flex-end">
          <TokenSelector
            selected={tokenIn}
            tokens={tokens}
            loading={tokensLoading}
            withoutPortal
            readonly
          />
        </Flex>
      </Grid>
      <Flex my={3} justifyContent="center" width="100%">
        <SvgIcon name="DownIcon" />
      </Flex>
      <Grid gridTemplateColumns="2fr 150px" gridGap={[2, 3]}>
        <Flex width="100%" flexDirection="column" justifyContent="flex-end">
          <Label mobile>{t('atPriceOf', { ns: 'otc' })}</Label>
          <MaxInput
            value={big(offer.price).toNumber()}
            decimalScale={tokenOut?.decimals}
            height="48px"
            px="16px"
            disabled
            showMax={false}
          />
        </Flex>
        <Flex width="100%" flexDirection="column" justifyContent="flex-end">
          <TokenSelector
            selected={tokenOut}
            tokens={tokens}
            loading={tokensLoading}
            readonly
            withoutPortal
            emptyValue={
              <Text.span color="grey">{t('assets.selectToken')}</Text.span>
            }
          />
        </Flex>
      </Grid>
      <Text.span color="black" mt="24px">
        {`${t('otc:youWill')} ${t('ots:pay')} ${prettifyBalance(
          big(offer.price).times(amountIn),
          6,
        )} ${tokenOut?.symbol} ${t('otc:from').toLowerCase()} ${t(
          'otc:yourAddress',
        )}.`}
      </Text.span>
      <TakeOfferDetails
        ordersDetails={[
          {
            offer: offer,
            amountIn: amountIn,
            amountPaid: amountPaid,
          },
        ]}
      />
      <Box>
        <SmartButton
          requireAccount
          type="button"
          width="100%"
          height={['40px', '52px']}
          fontWeight={4}
          fontSize={[2, 3]}
          mt="24px"
          mainColor={actionButtonLoading ? 'grey' : 'primary'}
          onClick={handleConfirmationButtonClick}
          loading={actionButtonLoading}
          disabled={disabled}
          loadingText={
            <>
              <Loader mr={2} color="white" />
              {t('assets.loading')}
            </>
          }
        >
          {errorAmountAvailable ||
            errorInsufficientBalance ||
            (tokenOutAllowance?.lt(amountPaid) &&
              t('assets.enableToken', { tokenName: tokenOut?.symbol })) ||
            (!account && t('confirm', { ns: 'otc' })) ||
            t('confirm', { ns: 'otc' })}
        </SmartButton>
      </Box>
    </>
  )
}

export default TakeOfferForm
