import { useState } from 'react'

const useGroupSelect = <T = string>(
  initialSelectedItems: T[] = [],
  multiple?: boolean,
) => {
  const [selectedItems, setSelectedItems] = useState<T[]>(initialSelectedItems)

  const selectItem = (item: T) => {
    setSelectedItems((prev) => [...prev, item])
  }

  const deselectItem = (item: T) => {
    setSelectedItems((prev) => prev.filter((i) => i !== item))
  }

  const isSelected = (item: T) => {
    return selectedItems.includes(item)
  }

  const clearSelectedItems = () => {
    setSelectedItems([])
  }

  const toggleSelect = (item: T) => {
    if (!multiple) {
      clearSelectedItems()
      selectItem(item)
      return
    }

    if (selectedItems.includes(item)) {
      deselectItem(item)
    } else {
      selectItem(item)
    }
  }

  return {
    isSelected,
    selectedItems,
    toggleSelect,
    clearSelectedItems,
  }
}

export default useGroupSelect
