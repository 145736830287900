import SvgIcon from '@swarm/ui/swarm/SvgIcon'
import { useTranslation } from 'react-i18next'
import { generatePath, NavLink } from 'react-router-dom'
import { Box } from 'rimble-ui'
import styled from 'styled-components/macro'

import { ROUTES } from 'src/routes'

interface MenuProps {
  open: boolean
}

export const StyledMenu = styled(Box)`
  background-color: ${({ theme }) => theme.colors.sidebar.background};
  flex-grow: 1;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
  transition: transform 0.3s ease-in-out;
  overflow: auto;
  max-height: 100vh;

  @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    transform: none;
    transition: none;
    overflow-y: unset;
  }
  z-index: ${({ theme }) => theme.zIndices.navigationMenu};
`
export const StyledNav = styled.nav`
  padding: 55px 28px;

  @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    padding: 36px;
  }
`

export const StyledLink = styled(NavLink)`
  text-decoration: none !important;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes[3]}px;
  margin-bottom: 36px;
  align-items: center;
  opacity: 0.5;

  &:hover,
  &:active,
  &:focus,
  &.active {
    opacity: 1;
    color: ${({ theme }) => theme.colors.white};
  }
`

const Menu = ({ open }: MenuProps) => {
  const { t } = useTranslation('navigation')

  return (
    <StyledMenu open={open}>
      <StyledNav>
        <StyledLink to={ROUTES.INVEST}>
          <SvgIcon
            height="32px"
            style={{ marginRight: '24px', marginLeft: '4px' }}
            name="AssetPageMenuIcon"
            color="white"
          />
          {t('menu.rwas')}
        </StyledLink>
        <StyledLink to={generatePath(ROUTES.DOTC)}>
          <SvgIcon
            name="DOTCPageIcon"
            width="32px"
            style={{ marginRight: '20px' }}
          />
          {t('menu.otc')}
        </StyledLink>
      </StyledNav>
    </StyledMenu>
  )
}

export default Menu
