import { InvestToken } from '@swarm/types/tokens/invest'

import AssetTokensList from '../AssetToken/AssetTokensList'
import { useInvestContext } from '../InvestContext'

import InvestAssetCard from './InvestAssetCard'

interface InvestAssetsListProps {
  tokens: InvestToken[]
}

const InvestAssetsList = ({ tokens }: InvestAssetsListProps) => {
  const { investAssetsLoading, investAssetsError } = useInvestContext()

  return (
    <AssetTokensList
      error={investAssetsError?.message}
      loading={investAssetsLoading}
      tokens={tokens}
      CardView={InvestAssetCard}
    />
  )
}

export default InvestAssetsList
