import useInvestAssets, {
  InvestAssetsData,
} from '@swarm/core/hooks/data/useInvestAssets'
import { ChildrenProps } from '@swarm/types'
import { createContext, FC, useContext } from 'react'

export const InvestContext = createContext<InvestAssetsData>({
  investAssets: [],
  stakingNodes: [],
  stockTokens: [],
  brandingTokens: [],
  bonds: [],
  indexTokens: [],
  investAssetsLoading: true,
})

export const InvestProvider: FC<ChildrenProps> = (props) => (
  <InvestContext.Provider value={useInvestAssets()} {...props} />
)

export const useInvestContext = () => useContext(InvestContext)
