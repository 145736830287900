import { BasicModalProps } from '@swarm/core/hooks/state/usePopupState'
import { truncateStringInTheMiddle } from '@swarm/core/shared/utils'
import { propEquals } from '@swarm/core/shared/utils/collection'
import { splitBy } from '@swarm/core/shared/utils/collection/splitBy'
import { InvestToken, KyaProperty } from '@swarm/types/tokens/invest'
import Collapsible from '@swarm/ui/presentational/Collapsible'
import { Drawer } from '@swarm/ui/presentational/Drawer'
import ExplorerLink from '@swarm/ui/swarm/Link/ExplorerLink'
import SvgIcon from '@swarm/ui/swarm/SvgIcon'
import TokenIcon from '@swarm/ui/swarm/TokenIcon'
import { PropsWithChildren, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Flex, Heading, Loader } from 'rimble-ui'
import styled from 'styled-components/macro'

import PopupDataBox from './PopupDataBox'
import PopupDataRow from './PopupDataRow'

interface AssetInfoPopupProps extends PropsWithChildren<BasicModalProps> {
  asset: InvestToken
}

interface Property extends KyaProperty {
  // eslint-disable-next-line camelcase
  display_type?: string
}

const StyledHeading = styled(Heading).attrs({ as: 'h4' })`
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  margin: 0;
  margin-bottom: ${({ theme }) => theme.space[1]}px;
  color: ${({ theme }) => theme.colors.primary};
`

const AssetInfoPopup = ({
  isOpen,
  onClose,
  asset,
  children,
}: AssetInfoPopupProps) => {
  const { t } = useTranslation(['popups'])

  const { name, symbol, description, properties } = asset?.kyaData ?? {
    properties: [],
  }

  const [extraEntries, documents] = useMemo(
    () =>
      properties && properties.length
        ? splitBy<Property>(
            properties,
            propEquals('display_type', 'document'),
            2,
          )
        : [[], []],
    [properties],
  )

  const loading = !isOpen || !asset.kya || asset?.kyaData === undefined

  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose} elevation={0}>
      <Flex
        flexDirection="column"
        width={['100%', '600px']}
        justifyContent={loading ? 'center' : 'flex-start'}
        alignItems="center"
        p="24px"
        py="40px"
      >
        {loading ? (
          <Loader size="30px" style={{ flexGrow: '1' }} />
        ) : (
          <>
            {asset.kyaInformation?.image ? (
              <SvgIcon
                name={asset.kyaInformation?.image}
                style={{
                  flex: '0 0 100px',
                  marginBottom: '16px',
                  maxWidth: '100px',
                }}
                height="100px"
                external
              />
            ) : (
              <TokenIcon
                symbol={asset.symbol}
                height="100px"
                maxWidth="100px"
              />
            )}

            <StyledHeading>{name}</StyledHeading>
            <StyledHeading>{symbol}</StyledHeading>
            <StyledHeading>
              <ExplorerLink
                color="primary"
                type="address"
                fontWeight="600"
                hash={asset.id}
                label={truncateStringInTheMiddle(asset.id)}
              />
            </StyledHeading>
            <PopupDataBox mt={3}>
              {children}
              <Collapsible
                title={t('assetInfo.kya')}
                dividerThickness={2}
                mt={3}
              >
                <PopupDataBox>
                  {description && (
                    <PopupDataRow
                      key="description"
                      label={t('assetInfo.description')}
                      value={description as string}
                    />
                  )}
                  {extraEntries.map((entry) => (
                    <PopupDataRow
                      key={entry.property_type}
                      label={entry.property_type}
                      value={entry.value}
                    />
                  ))}
                  {asset.kya && (
                    <PopupDataRow
                      key="kya"
                      label={t('assetInfo.kya')}
                      value={asset.kya}
                    />
                  )}
                </PopupDataBox>
              </Collapsible>

              {!!documents.length && (
                <Collapsible title={t('assetInfo.documents')} mt={3}>
                  <PopupDataBox>
                    {documents.map((document) => (
                      <PopupDataRow
                        key={document.property_type}
                        label={document.property_type}
                        value={document.value}
                        document
                        color="grey"
                      />
                    ))}
                  </PopupDataBox>
                </Collapsible>
              )}
            </PopupDataBox>
          </>
        )}
      </Flex>
    </Drawer>
  )
}

export default AssetInfoPopup

export { PopupDataRow, PopupDataBox }
