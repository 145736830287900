import { big, safeDiv } from '@swarm/core/shared/utils/helpers'
import { TokenPair } from '@swarm/types'
import { BigSource } from 'big.js'
import { ChangeEvent, useCallback, useState } from 'react'

import { minDate } from 'src/components/XDotc/CreateOffer/CreateOfferForm'

const useEditOfferForm = ({
  defaultValues,
}: {
  defaultValues: {
    amountIn: number
    tokenOut: number
    amountOut: number
    price: BigSource

    timelock: string
    terms: string
    contacts: string

    takerAddress: string
    partial: boolean

    selectedDate: Date
  }
}) => {
  const [{ tokenIn, tokenOut }, setTokens] = useState<TokenPair>({})
  const setTokenPair = useCallback((pair: TokenPair) => {
    setTokens((prevPair) => ({
      ...prevPair,
      ...pair,
    }))
  }, [])

  const [selectedDate, setSelectedDate] = useState<Date>(
    defaultValues.selectedDate || minDate,
  )

  const [advancedSettingsExpanded, setAdvancedSettingsExpanded] =
    useState(false)
  const [isPartialOrder, setIsPartialOrder] = useState(defaultValues.partial)
  const [takerAddress, setTakerAddress] = useState<string>(
    defaultValues.takerAddress || '',
  )
  const [timelockPeriod, setTimelockPeriod] = useState<string>(
    defaultValues.timelock || '',
  ) // days
  const [terms, setTerms] = useState(defaultValues.terms || '')
  const [contact, setContact] = useState(defaultValues.contacts || '')
  const [amountIn, setAmountIn] = useState(defaultValues.amountIn)
  const [amountOut, setAmountOut] = useState(defaultValues.amountOut)
  const [price, setPrice] = useState<BigSource>(defaultValues.price)
  const [isExpiration, setIsExpiration] = useState(true)

  const handleAmountOutChange = useCallback(
    (value: number) => {
      if (!Number.isNaN(value)) {
        const newValue = big(value)
        setAmountOut(value)

        const newPrice = safeDiv(newValue, amountIn)
        setPrice(newPrice.toString())
      }
    },
    [amountIn],
  )

  const handlePriceChange = useCallback(
    (value: number) => {
      const newPrice = big(value)
      setPrice(value)

      const newAmountOut = newPrice.times(amountIn)
      setAmountOut(newAmountOut.toNumber())
    },
    [amountIn],
  )

  const handleTimelockPeriodChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = Number(e.target.value)
      if (!Number.isNaN(value)) {
        const newValue =
          e.target.value.length === 0
            ? ''
            : Number.parseInt(value.toString(), 10).toString()
        setTimelockPeriod(newValue)
      }
    },
    [],
  )

  const handleDateChange = useCallback((date: Date) => {
    setSelectedDate(date)
  }, [])

  const handleTermsChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTerms(e.target.value)
  }, [])

  const handleContactChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setContact(e.target.value)
    },
    [],
  )

  const toggleAdvancedSettings = () =>
    setAdvancedSettingsExpanded((prev) => !prev)

  const toggleIsPartialOrder = () => setIsPartialOrder((prev) => !prev)

  const toggleIsExpired = () =>
    setIsExpiration((prevValue) => {
      const newValue = !prevValue
      if (!newValue) setSelectedDate(minDate)
      return newValue
    })

  const handleTakerAddressChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setTakerAddress(e.target.value)
    },
    [],
  )

  return {
    state: {
      tokenIn,
      tokenOut,
      selectedDate,
      advancedSettingsExpanded,
      isPartialOrder,
      takerAddress,
      timelockPeriod,
      terms,
      contact,
      amountIn,
      amountOut,
      price,
      isExpiration,
    },
    actions: {
      setTokenPair,
      setAmountIn,
      setAmountOut,
      handleAmountOutChange,
      handlePriceChange,
      handleTimelockPeriodChange,
      handleDateChange,
      handleTermsChange,
      handleContactChange,
      toggleAdvancedSettings,
      toggleIsPartialOrder,
      toggleIsExpired,
      handleTakerAddressChange,
    },
  }
}

export default useEditOfferForm
