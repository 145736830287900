import { Close } from '@rimble/icons'
import usePopupState from '@swarm/core/hooks/state/usePopupState'
import useXDotcOffers from '@swarm/core/hooks/subgraph/x-dotc/useXDotcOffers'
import { useAccount } from '@swarm/core/web3'
import { ChildrenProps } from '@swarm/types'
import { NormalizedXOffer } from '@swarm/types/normalized-entities/x-offer'
import { Drawer } from '@swarm/ui/presentational/Drawer'
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Flex, Heading } from 'rimble-ui'
import { useQueryParam } from 'use-query-params'

import { EditOfferContextProvider } from './EditOfferContext'
import EditOfferForm from './EditOfferForm'

const EDIT_OFFER_KEY = 'edit-offer'

interface EditOfferPopupProps {
  isOpen: boolean
  onClose: () => void
  children: ReactNode
}

const EditOfferPopup = ({ isOpen, onClose, children }: EditOfferPopupProps) => {
  const { t } = useTranslation('otc')

  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose}>
      <Flex
        p={['16px', '24px']}
        flexDirection="column"
        width="500px"
        maxWidth={['100vw', 'calc(100vw - 304px)']}
        height="100vh"
        overflowY="auto"
      >
        <Flex alignItems="center" justifyContent="space-between" mb={3}>
          <Heading
            as="h3"
            fontSize={3}
            lineHeight="28px"
            fontWeight={5}
            color="grey"
            mt={0}
            mb={0}
          >
            {t('editDOTCOffer')}:
          </Heading>
          <Close fill="red" onClick={onClose} />
        </Flex>
        {children}
      </Flex>
    </Drawer>
  )
}

export const EditOfferPopupContext = createContext<{
  openEditOfferPopup: (offer: NormalizedXOffer) => void
  closeEditOfferPopup: () => void
}>({
  openEditOfferPopup: () => {
    throw new Error(
      "Can't call openEditOfferPopup outside of EditOfferPopupProvider",
    )
  },
  closeEditOfferPopup: () => {
    throw new Error(
      "Can't call closeEditOfferPopup outside of EditOfferPopupProvider",
    )
  },
})

export const EditOfferPopupProvider = ({ children }: ChildrenProps) => {
  const { isOpen, open, close } = usePopupState(false)
  const history = useHistory()
  const [queryOfferId] = useQueryParam<string>('offerId')
  const [offer, setOffer] = useState<NormalizedXOffer | null>(null)

  const account = useAccount()
  const { refetch } = useXDotcOffers({
    variables: {
      filter: {},
    },
    skip: true,
  })

  const openEditOfferPopup = useCallback(
    async (newOffer: NormalizedXOffer) => {
      setOffer(newOffer)
      history.push({
        ...history.location,
        search: `offerId=${encodeURIComponent(newOffer.id)}`,
        hash: EDIT_OFFER_KEY,
      })
    },
    [history],
  )

  const closeEditOfferPopup = useCallback(() => {
    history.push({ ...history.location, search: '', hash: undefined })
    setOffer(null)
    close()
  }, [close, history])

  useEffect(() => {
    ;(async () => {
      const hash = history.location.hash?.substr(1)
      if (hash === EDIT_OFFER_KEY) {
        const offers = await refetch({
          maker: account,
          id: queryOfferId,
        })
        setOffer(offers[0])
        open()
      }
    })()
  }, [history.location.hash, refetch, account, queryOfferId, open])

  const value = useMemo(() => {
    return { openEditOfferPopup, closeEditOfferPopup }
  }, [openEditOfferPopup, closeEditOfferPopup])

  return (
    <EditOfferPopupContext.Provider value={value}>
      {children}
      {offer && (
        <EditOfferContextProvider offer={offer}>
          <EditOfferPopup isOpen={isOpen} onClose={closeEditOfferPopup}>
            <EditOfferForm offer={offer} />
          </EditOfferPopup>
        </EditOfferContextProvider>
      )}
    </EditOfferPopupContext.Provider>
  )
}

export const useEditOfferPopup = () => useContext(EditOfferPopupContext)
