import { getNetworkById } from '@swarm/core/shared/utils'
import { getLastUsedNetworkId } from '@swarm/core/web3'

export const generateShareLink = (offerId: string) => {
  const networkId = getLastUsedNetworkId()
  const network = getNetworkById(networkId)
  const isEmbed =
    window.top && window.document.referrer.includes('app.swarm.com')
  const shareUrl = new URL(window.location.href.split('?')[0])
  if (isEmbed) {
    shareUrl.href = shareUrl.href.replace('/#', '') // remove specific SwarmX url postfix (/#)
    shareUrl.host = new URL(window.document.referrer).host
  }

  return `${shareUrl}?id=${offerId}&network=${network?.networkName}`
}
