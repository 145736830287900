import { InvestToken } from '@swarm/types/tokens/invest'
import SvgIcon from '@swarm/ui/swarm/SvgIcon'
import TokenIcon from '@swarm/ui/swarm/TokenIcon'
import { Box } from 'rimble-ui'

interface AssetTokenIconProps {
  token: InvestToken
}

const AssetTokenIcon = ({ token }: AssetTokenIconProps) => {
  return (
    <Box sx={{ minWidth: 64 }}>
      {token.kyaInformation?.image ? (
        <SvgIcon
          name={token.kyaInformation?.image}
          height="64px"
          width="64px"
          external
        />
      ) : (
        <TokenIcon
          symbol={token.symbol}
          name={token.name}
          height="64px"
          width="64px"
          mr="10px"
        />
      )}
    </Box>
  )
}

export default AssetTokenIcon
