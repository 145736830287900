// BEWARE: this is the list of routes that are allowed
// whenever adding a new route, make sure to add it to this list!

const ROUTES = {
  HOMEPAGE: '/',
  DOTC: '/dotc',
  DOTC_CATEGORY: '/dotc/:category?',
  INVEST: '/invest',
  INVEST_CATEGORY: '/invest/:category',
}

export { ROUTES }
