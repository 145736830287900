import { SvgIcon } from '@material-ui/core'
import usePopupState from '@swarm/core/hooks/state/usePopupState'
import { NormalizedXOffer } from '@swarm/types/normalized-entities/x-offer'
import DisableWrapper from '@swarm/ui/presentational/DisableWrapper'
import { Flex, Icon } from 'rimble-ui'

import ShareGoldOfferModal from 'src/components/Gold/ShareGoldOfferModal'

import { useDotcContext } from '../DotcContext'

interface OfferActionIconsProps {
  offer: NormalizedXOffer
  showTokenInfo?: boolean
  disabled?: boolean
}

const OfferActionIcons = ({
  offer,
  showTokenInfo = false,
  disabled = false,
}: OfferActionIconsProps) => {
  const shareGoldOfferModalState = usePopupState(false)

  const { openGoldNftInfo } = useDotcContext()

  const openLink = (url: string) => {
    window.open(url, '_blank')?.focus()
  }

  return (
    <>
      <DisableWrapper disabled={disabled}>
        <Flex>
          <Icon
            name="Share"
            color="primary"
            onClick={shareGoldOfferModalState.open}
            style={{ cursor: 'pointer' }}
          />
          {showTokenInfo && (
            <Icon
              onClick={() => openGoldNftInfo(offer.depositAsset)}
              name="InfoOutline"
              color="primary"
              mx="3px"
              style={{ cursor: 'pointer' }}
            />
          )}
          {offer.commsLink && (
            <SvgIcon
              onClick={() => openLink(offer.commsLink)}
              name="ChatBabble"
              color="primary"
              style={{ cursor: 'pointer' }}
            />
          )}
        </Flex>
      </DisableWrapper>
      <ShareGoldOfferModal
        isOpen={shareGoldOfferModalState.isOpen}
        onClose={shareGoldOfferModalState.close}
        offer={offer}
      />
    </>
  )
}

export default OfferActionIcons
