import { NetworkMap } from '@core/shared/consts'
import { getLastUsedNetworkId, unifyAddress } from '@core/web3'

const getTokenLogoUrl = (address: string) => {
  const networkName = NetworkMap.get(
    getLastUsedNetworkId(),
  )?.networkName?.toLowerCase()

  return `https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/${networkName}/assets/${unifyAddress(
    address,
  )}/logo.png`
}

export default getTokenLogoUrl
