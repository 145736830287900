import SvgIcon from '@swarm/ui/swarm/SvgIcon'
import { Card, Flex } from 'rimble-ui'
import { FlexBasisProps, MaxWidthProps, WidthProps } from 'styled-system'

type CardSkeletonProps = FlexBasisProps & WidthProps & MaxWidthProps
const CardSkeleton = (props: CardSkeletonProps) => {
  return (
    <Card p={0} borderRadius={1} opacity={0.7} minHeight="200px" {...props}>
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100%"
      >
        <SvgIcon name="StakingNodeCardSkeleton" />
      </Flex>
    </Card>
  )
}

export default CardSkeleton
