import { prettifyBalance } from '@swarm/core/shared/utils'
import { normalizeUSDCE } from '@swarm/core/shared/utils/tokens'
import { NormalizedXOffer } from '@swarm/types/normalized-entities/x-offer'
import TokenIcon from '@swarm/ui/swarm/TokenIcon'
import { format, fromUnixTime } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { Box, Button, Flex, Text } from 'rimble-ui'

import { useTakeOfferPopup } from '../TakeOffer'

import OfferActionIcons from './OfferActionIcons'

interface GoldOfferRowProps {
  offer: NormalizedXOffer
}

const GoldOfferRow = ({ offer }: GoldOfferRowProps) => {
  const { t } = useTranslation('otc')
  const { openTakeOfferPopup } = useTakeOfferPopup()

  const { depositAsset, withdrawalAsset } = offer
  const tokenIn = normalizeUSDCE(depositAsset)
  const tokenOut = normalizeUSDCE(withdrawalAsset)

  const expiresAt = format(fromUnixTime(offer.expiresAt), 'MMMM dd, yyyy HH:mm')

  const handleTakeGoldOffer = () => {
    openTakeOfferPopup(offer)
  }

  return (
    <>
      <tr key={offer.id}>
        <td title={offer.id}>
          <Flex alignItems="center">
            <TokenIcon width="20px" height="20px" symbol="xgold" name="xGOLD" />
            <Text.span ml="16px" fontWeight={700}>
              {tokenIn.name}
            </Text.span>
          </Flex>
        </td>
        <td>
          <Text.span lineHeight="24px">{offer.amountIn}</Text.span>
        </td>
        <td>
          <Text.span lineHeight="24px">
            {tokenIn.assetData?.location || '--'}
          </Text.span>
        </td>
        <td>
          {prettifyBalance(offer.price, 6)} {tokenOut.symbol}
        </td>
        <td>
          <Text.span lineHeight="20px">{expiresAt}</Text.span>
        </td>
        <Box as="td">
          <Button
            onClick={handleTakeGoldOffer}
            fontWeight="600"
            lineHeight="20px"
            height="28px"
            width="120px"
            fontSize="14px"
            px={['4px', '16px']}
          >
            {t('buy')} {tokenIn.name}
          </Button>
        </Box>
        <td>
          <Flex justifyContent="flex-end">
            <OfferActionIcons offer={offer} showTokenInfo />
          </Flex>
        </td>
      </tr>
    </>
  )
}

export default GoldOfferRow
