import { QueryHookOptions, useQuery } from '@apollo/client'
import { DotcAsset } from '@swarm/types/tokens/dotc'
import {
  DotcAssetsQueryVariables,
  DotcAsset as DotcAssetSubgraph,
} from '@swarm/types/x-subgraph/graphql'
import merge from 'lodash/merge'
import { useMemo } from 'react'

import { DotcAssetsQuery } from '@core/queries'
import xClient from '@core/services/apollo/x-client'
import { POLL_INTERVAL } from '@core/shared/consts'
import { mapBy } from '@core/shared/utils/collection'
import { normalizeDotcAsset } from '@core/shared/utils/subgraph/x-dotc'
import { useReadyState } from '@core/web3'

interface QueryResponse {
  dotcAssets: Required<DotcAssetSubgraph>[]
}

const useXDotcAssets = (
  options?: QueryHookOptions<QueryResponse, DotcAssetsQueryVariables>,
) => {
  const ready = useReadyState()

  const {
    data,
    loading,
    refetch: reloadTokens,
  } = useQuery<QueryResponse>(
    DotcAssetsQuery,
    merge(
      {
        fetchPolicy: 'cache-first',
        pollInterval: POLL_INTERVAL,
        variables: {
          limit: 100,
        },
        skip: !ready && options?.skip,
        client: xClient,
      },
      options,
    ),
  )

  const normalizedAssets = useMemo(
    () => data?.dotcAssets.map(normalizeDotcAsset) || [],
    [data?.dotcAssets],
  )

  const tokensDictionary: Map<string, DotcAsset> = useMemo(
    () => mapBy(normalizedAssets, 'id'),
    [normalizedAssets],
  )

  return {
    tokens: normalizedAssets,
    tokensDictionary,
    reloadTokens,
    loading: !ready || loading,
  }
}

export default useXDotcAssets
