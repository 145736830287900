import useXOffers from '@swarm/core/hooks/subgraph/x-dotc/useXDotcOffers'
import { getAssetFilter } from '@swarm/core/shared/subgraph'
import { useAccount } from '@swarm/core/web3'
import { DotcAsset } from '@swarm/types/tokens/dotc'
import { XOfferFilter } from '@swarm/types/x-subgraph/graphql'
import { getUnixTime } from 'date-fns'
import { useCallback, useMemo, useRef } from 'react'

interface UseMyOffersOptions {
  tokenIn?: DotcAsset
  tokenOut?: DotcAsset
  skip?: boolean
  hideInactiveOffers?: boolean
}

const useMyOffers = ({
  tokenIn,
  tokenOut,
  skip = false,
  hideInactiveOffers = true,
}: UseMyOffersOptions) => {
  const account = useAccount()

  const { current: currentTimestamp } = useRef(getUnixTime(Date.now()))

  const xDotcMyOffersFilter = useMemo<XOfferFilter>(
    () => ({
      ...(hideInactiveOffers && {
        cancelled: false,
        isCompleted: false,
        // eslint-disable-next-line camelcase
        expiresAt_gt: currentTimestamp.toString(),
      }),
      maker: account,
      ...getAssetFilter(tokenIn, tokenOut),
    }),
    [hideInactiveOffers, currentTimestamp, account, tokenIn, tokenOut],
  )

  const { offers, loadingOffers, refetch, hasMore, fetchMore, refetching } =
    useXOffers({
      variables: {
        filter: xDotcMyOffersFilter,
      },
      skip,
    })

  const refreshOffers = useCallback(
    (newFilter?: Partial<XOfferFilter>) =>
      refetch({
        ...xDotcMyOffersFilter,
        ...newFilter,
      }),
    [refetch, xDotcMyOffersFilter],
  )

  const loadMore = useCallback(async () => {
    if (offers && hasMore && !skip && !loadingOffers) {
      await fetchMore(offers?.length)
    }
  }, [offers, hasMore, skip, loadingOffers, fetchMore])

  return useMemo(
    () => ({
      offers,
      loadingOffers,
      hasMore,
      loadMore,
      refetching,
      refreshOffers,
    }),
    [hasMore, loadMore, loadingOffers, offers, refetching, refreshOffers],
  )
}

export default useMyOffers
