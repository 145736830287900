import NetworkFeature, {
  NetworkFeatureName,
} from '@swarm/core/services/networkFeatures'
import { StyledTabs } from '@swarm/ui/presentational/StyledTabs'
import StyledTab from '@swarm/ui/swarm/StyledTab'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'

const categories = {
  shared: 0,
  gold: 1,
  'my-offers': 2,
}

const tabs = Object.keys(categories)

const DotcSubheader = () => {
  const { t } = useTranslation('otc')
  const history = useHistory()
  const { category = 'shared' } = useParams<{
    category?: keyof typeof categories
  }>()

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleSwitchTab = (e: React.ChangeEvent<{}>, index: number) => {
    if (index !== categories[category]) {
      history.push({
        search: history.location.search,
        pathname: `/dotc/${tabs[index]}`,
      })
    }
  }

  return (
    <StyledTabs
      value={categories[category]}
      onChange={handleSwitchTab}
      aria-label="dOTC tabs"
    >
      <StyledTab label={t('tabs.allOffers')} value={categories.shared} />
      {NetworkFeature.ifSupported(
        NetworkFeatureName.goldNfts,
        <StyledTab label={t('tabs.gold')} value={categories.gold} />,
      )}
      <StyledTab label={t('tabs.myOffers')} value={categories['my-offers']} />
    </StyledTabs>
  )
}

export default DotcSubheader
