import { useTranslation } from 'react-i18next'
import { matchPath, useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'

const RouteNotFoundContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 2em;
  height: 100vh;
`

interface RouterNotFoundProps {
  appRoutes: Record<string, string>
}

const RouteNotFound = ({ appRoutes = {} }: RouterNotFoundProps) => {
  const { t } = useTranslation(['common'])
  const location = useLocation()
  const routes = Object.values(appRoutes)

  const matches = routes.find((route) => {
    const match = matchPath(location.pathname, {
      path: route,
      exact: true,
      strict: true,
    })
    return match && match.isExact
  })

  if (matches) {
    return null
  }

  return <RouteNotFoundContainer>{t('pageNotFound')}</RouteNotFoundContainer>
}

export default RouteNotFound
