/* eslint-disable camelcase */
import useGoldBundle from '@swarm/core/hooks/data/useGoldBundle'
import useNativeSmt from '@swarm/core/hooks/data/useNativeSmt'
import useSx1155TokenIds from '@swarm/core/hooks/subgraph/sx1155/useSx1155TokenIds'
import useInvestSecurities from '@swarm/core/hooks/subgraph/useInvestSecurities'
import useXDotcAssets from '@swarm/core/hooks/subgraph/x-dotc/useXDotcAssets'
import useNftsForOwner from '@swarm/core/services/alchemy/useNftsForOwner'
import useTokensForOwner from '@swarm/core/services/alchemy/useTokensForOwner'
import NetworkFeature, {
  NetworkFeatureName,
} from '@swarm/core/services/networkFeatures'
import { mapBy } from '@swarm/core/shared/utils'
import { ZERO } from '@swarm/core/shared/utils/helpers'
import { isERC20Type } from '@swarm/core/shared/utils/tokens'
import { useAccount } from '@swarm/core/web3'
import { TokenSelectorAsset } from '@swarm/types/tokens'
import { Sx1155AssetType } from '@swarm/types/x-subgraph/graphql'
import omit from 'lodash/omit'
import uniqBy from 'lodash/uniqBy'
import { useMemo } from 'react'

const useDefaultTokens = () => {
  const account = useAccount()
  const { nativeSmt, loading: smtLoading } = useNativeSmt()

  const { securities, loading: securitiesLoading } = useInvestSecurities({
    includeBalances: true,
    account,
  })

  const { sx1155TokenIds: goldTokens } = useSx1155TokenIds({
    variables: {
      filter: {
        assetType: Sx1155AssetType.Gold,
      },
    },
  })

  const { nfts, loading: nftsLoading } = useNftsForOwner({ account })

  const { tokens, loading } = useXDotcAssets({
    variables: {
      limit: 50,
      filter: {
        tradedVolume_gt: '0',
      },
    },
    pollInterval: undefined,
  })

  const includeOwnedTokens = !loading
    ? tokens.filter(isERC20Type).map((token) => token.address)
    : []

  // Fetch tokens with balances for the user
  const {
    tokens: ownedTokens,
    loading: ownedTokensLoading,
    tokensDictionary: ownedTokensDictionary,
  } = useTokensForOwner({
    account,
    initialFilter: { includeTokens: includeOwnedTokens },
    enabled: !loading,
  })

  const normalizedSecurities = useMemo<TokenSelectorAsset[]>(
    () =>
      securities.map((token) => ({
        ...omit(token, ['kyaInformation']),
        balance:
          ownedTokensDictionary.get(token.id)?.balance || token.balance || ZERO,
      })),
    [ownedTokensDictionary, securities],
  )

  const { token: xGoldBundle, loading: xGoldBundleLoading } = useGoldBundle()

  const normalizedXGoldBundle = useMemo<TokenSelectorAsset | null>(() => {
    if (!xGoldBundle) return null

    return {
      ...xGoldBundle,
      balance:
        (xGoldBundle && ownedTokensDictionary.get(xGoldBundle.id)?.balance) ||
        xGoldBundle?.balance ||
        ZERO,
    }
  }, [ownedTokensDictionary, xGoldBundle])

  const defaultTokens = useMemo<TokenSelectorAsset[]>(
    () =>
      uniqBy(
        [
          ...normalizedSecurities,
          ...ownedTokens,
          ...(nativeSmt ? [nativeSmt] : []), // SMT and Securities should override tokens above, which is why they are latest in the list.
          ...nfts,
          ...goldTokens,
          ...(normalizedXGoldBundle &&
          NetworkFeature.checkSupported(NetworkFeatureName.xGoldToken)
            ? [normalizedXGoldBundle]
            : []),
        ],
        'id',
      ),
    [
      normalizedSecurities,
      ownedTokens,
      nativeSmt,
      nfts,
      goldTokens,
      normalizedXGoldBundle,
    ],
  )

  const defaultTokensDictionary = mapBy(defaultTokens, 'id')

  return {
    tokens: defaultTokens,
    tokensDictionary: defaultTokensDictionary,
    loading:
      loading ||
      ownedTokensLoading ||
      smtLoading ||
      securitiesLoading ||
      nftsLoading ||
      xGoldBundleLoading,
  }
}

export default useDefaultTokens
