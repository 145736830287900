import { useRef } from 'react'
import { useQueryParam } from 'use-query-params'

export type EMBED_MODES = 'none' | 'main_content'
const isIframe = window.location !== window.parent.location
const useEmbedModeDetector = (): EMBED_MODES => {
  const [embedMode = isIframe ? 'main_content' : 'none'] =
    useQueryParam<EMBED_MODES>('embed_mode')
  const storedEmbedModeRef = useRef(embedMode)

  if (embedMode !== 'none') {
    storedEmbedModeRef.current = embedMode
  }

  return storedEmbedModeRef.current
}

export default useEmbedModeDetector
