import usePopupState, {
  BasicModalProps,
} from '@swarm/core/hooks/state/usePopupState'
import { InvestToken } from '@swarm/types/tokens/invest'
import { Flex, Text } from 'rimble-ui'

import AssetInfoPopup from 'src/components/Invest/AssetInfoPopup'
import PopupActionsRow from 'src/components/Invest/AssetInfoPopup/PopupActionsRow'

import RedeemModal from './RedeemModal'

interface InvestAssetInfoPopupProps extends BasicModalProps {
  asset: InvestToken
}

const InvestAssetInfoPopup = ({
  isOpen,
  onClose,
  asset,
}: InvestAssetInfoPopupProps) => {
  const redeemModal = usePopupState()

  return (
    <AssetInfoPopup isOpen={isOpen} onClose={onClose} asset={asset}>
      <Flex mt={3} justifyContent="center">
        <Text.span color="text" fontWeight="bold" fontSize="18px">
          ${asset.exchangeRate ?? 0}
        </Text.span>
      </Flex>
      <PopupActionsRow token={asset} onRedeemClick={redeemModal.open} />
      <RedeemModal
        isOpen={redeemModal.isOpen}
        onClose={redeemModal.close}
        asset={asset}
        withoutPortal
      />
    </AssetInfoPopup>
  )
}

export default InvestAssetInfoPopup
