import {
  prettifyBalance,
  truncateStringInTheMiddle,
} from '@swarm/core/shared/utils'
import { NormalizedXOffer } from '@swarm/types/normalized-entities/x-offer'
import Grid from '@swarm/ui/presentational/Grid'
import ExplorerLink from '@swarm/ui/swarm/ExplorerLink'
import { BigSource } from 'big.js'
import { useTranslation } from 'react-i18next'
import { Box, Text } from 'rimble-ui'

interface EditOfferDetailsProps {
  ordersDetails: {
    offer: NormalizedXOffer
    amountPaid: BigSource
    amountIn: BigSource
  }[]
}

const EditOfferDetails = ({ ordersDetails }: EditOfferDetailsProps) => {
  const { t } = useTranslation('otc')

  return (
    <Box>
      <Text color="near-black" fontWeight={5} mb={2}>
        {t('details.editOffer.title')}
      </Text>
      <Grid
        flexDirection="column"
        gridTemplateColumns="repeat(auto-fill, minmax(100px, 1fr))"
      >
        {/* Header: */}
        <Text color="near-black" fontWeight={5}>
          {t('details.editOffer.amount')}
        </Text>
        <Text color="near-black" fontWeight={5} textAlign="center">
          {t('details.editOffer.price')}
        </Text>
        <Text color="near-black" fontWeight={5} textAlign="center">
          {t('details.editOffer.total')}
        </Text>
        <Text color="near-black" fontWeight={5} textAlign="right">
          {t('details.editOffer.maker')}
        </Text>
      </Grid>
      {ordersDetails.map((order) => {
        const { amountIn, amountPaid } = order
        const { depositAsset, withdrawalAsset, maker, price, id } = order.offer

        return (
          <Grid
            key={id}
            flexDirection="column"
            gridTemplateColumns="repeat(auto-fill, minmax(100px, 1fr))"
            alignItems="center"
            mt={1}
          >
            <Text color="near-black">
              {`${prettifyBalance(amountIn)} ${depositAsset.symbol}`}
            </Text>
            <Text color="near-black" textAlign="center">
              {`${prettifyBalance(price)} ${withdrawalAsset.symbol}`}
            </Text>
            <Text color="near-black" textAlign="center">
              {`${prettifyBalance(amountPaid)} ${withdrawalAsset.symbol}`}
            </Text>
            <ExplorerLink
              type="address"
              hash={maker}
              label={truncateStringInTheMiddle(maker)}
            />
          </Grid>
        )
      })}
    </Box>
  )
}

export default EditOfferDetails
