/* eslint-disable camelcase */
import useUniswapTokens from '@swarm/core/hooks/data/uniswap/useUniswapTokens'
import useTokenMetadata from '@swarm/core/services/alchemy/useTokenMetadata'
import { tokenFilter } from '@swarm/core/shared/utils'
import { ZERO } from '@swarm/core/shared/utils/helpers/big-helpers'
import { TokenSelectorAsset } from '@swarm/types/tokens'
import { isAddress } from 'ethers/lib/utils'
import uniqBy from 'lodash/uniqBy'
import { useMemo } from 'react'

import useDefaultTokens from './useDefaultTokens'

const useSelectorTokens = (searchQuery: string) => {
  const {
    tokens: defaultTokens,
    tokensDictionary: defaultTokensDictionary,
    loading: defaultTokensLoading,
  } = useDefaultTokens()

  const isSearchByAddress = isAddress(searchQuery)
  const isSearchByNameOrSymbol = !isSearchByAddress && !!searchQuery

  const { token: searchedToken, loading: searchedTokenLoading } =
    useTokenMetadata(isSearchByAddress ? searchQuery : '')

  const { tokens: uniswapSearchedTokens, loading: uniswapSearchedLoading } =
    useUniswapTokens({
      variables: {
        filter: {
          name_contains_nocase: searchQuery,
        },
        orderBy: 'volumeUSD',
        orderDirection: 'desc',
      },
      skip: isSearchByAddress,
    })

  const normalizedUniswapSearchedTokens = useMemo<TokenSelectorAsset[]>(
    () =>
      uniswapSearchedTokens.map((token) => ({
        ...token,
        balance: defaultTokensDictionary.get(token.id)?.balance || ZERO,
      })),
    [defaultTokensDictionary, uniswapSearchedTokens],
  )

  const filteredTokens = useMemo<TokenSelectorAsset[]>(() => {
    if (isSearchByAddress) return searchedToken ? [searchedToken] : []

    if (isSearchByNameOrSymbol)
      return uniqBy(
        [...defaultTokens, ...normalizedUniswapSearchedTokens],
        'id',
      ).filter(tokenFilter(searchQuery))

    return defaultTokens
  }, [
    isSearchByAddress,
    searchedToken,
    isSearchByNameOrSymbol,
    defaultTokens,
    normalizedUniswapSearchedTokens,
    searchQuery,
  ])

  return {
    tokens: filteredTokens,
    loading:
      defaultTokensLoading || uniswapSearchedLoading || searchedTokenLoading,
  }
}

export default useSelectorTokens
