import { useIdToggle } from '@swarm/core/hooks/dom/useIdToggle'
import useBreakpoints from '@swarm/core/hooks/ui/useBreakPoints'
import { InvestToken } from '@swarm/types/tokens/invest'
import Loader from '@swarm/ui/presentational/Loader'
import range from 'lodash/range'
import { ComponentType, useCallback } from 'react'
import { Flex } from 'rimble-ui'
import { MarginProps } from 'styled-system'

import CardSkeleton from '../InvestAssetsList/InvestAssetCard/CardSkeleton'

import AssetTokenCard, { AssetTokenCardProps } from './AssetTokenCard'

interface AssetTokensListProps extends MarginProps {
  error?: string
  loading?: boolean
  tokens: InvestToken[]
  CardView?: ComponentType<AssetTokenCardProps>
}

const AssetTokensList = ({
  CardView = AssetTokenCard,
  tokens = [],
  loading,
  error,
  ...props
}: AssetTokensListProps) => {
  const { isMd, isLg } = useBreakpoints()

  const { id: expandedTokenId, toggleOff, toggleOn } = useIdToggle()

  const handleOnCollapse = useCallback(() => {
    toggleOff()
  }, [toggleOff])

  return (
    <Flex
      width="100%"
      style={{ gap: '24px' }}
      flexWrap="wrap"
      justifyContent={loading ? 'center' : 'flex-start'}
      {...props}
    >
      <Loader loading={loading} error={error} size="100px">
        {tokens.length > 0 &&
          tokens.map((token) => (
            <CardView
              key={token.id}
              token={token}
              flexBasis={[
                '100%',
                '100%',
                'calc(50% - 12px)',
                'calc(33.33% - 16px)',
                'calc(33.33% - 16px)',
              ]}
              maxWidth="450px"
              expanded={expandedTokenId === token.id}
              onExpand={toggleOn}
              onCollapse={handleOnCollapse}
            />
          ))}
        {(isMd || isLg) &&
          tokens.length < 6 &&
          range(0, 6 - tokens.length).map((num) => (
            <CardSkeleton
              key={num}
              flexBasis={[
                '100%',
                '100%',
                'calc(50% - 12px)',
                'calc(33.33% - 16px)',
                'calc(33.33% - 16px)',
              ]}
              maxWidth="450px"
            />
          ))}
      </Loader>
    </Flex>
  )
}

export default AssetTokensList
