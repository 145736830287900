import { big, safeDiv } from '@swarm/core/shared/utils/helpers'
import { TokenPair } from '@swarm/types'
import { BigSource } from 'big.js'
import { ChangeEvent, useCallback, useState } from 'react'

import { minDate } from 'src/components/XDotc/CreateOffer/CreateOfferForm'

const useCreateOfferForm = () => {
  const [{ tokenIn, tokenOut }, setTokens] = useState<TokenPair>({})
  const setTokenPair = useCallback((pair: TokenPair) => {
    setTokens((prevPair) => ({
      ...prevPair,
      ...pair,
    }))
  }, [])

  const [selectedDate, setSelectedDate] = useState<Date>(minDate)

  const [advancedSettingsExpanded, setAdvancedSettingsExpanded] =
    useState(false)
  const [isPartialOrder, setIsPartialOrder] = useState(true)
  const [takerAddress, setTakerAddress] = useState<string>('')
  const [timelockPeriod, setTimelockPeriod] = useState<string>() // days
  const [terms, setTerms] = useState('')
  const [contact, setContact] = useState('')
  const [amountIn, setAmountIn] = useState(0)
  const [amountOut, setAmountOut] = useState(0)
  const [price, setPrice] = useState<BigSource>(0)
  const [isExpiration, setIsExpiration] = useState(false)

  const handleAmountInChange = useCallback(
    (value: number) => {
      if (!Number.isNaN(value)) {
        const newValue = big(value)
        setAmountIn(value)

        const newPrice = safeDiv(amountOut, newValue)
        setPrice(newPrice.toString())
      }
    },
    [amountOut],
  )

  const handleAmountOutChange = useCallback(
    (value: number) => {
      if (!Number.isNaN(value)) {
        const newValue = big(value)
        setAmountOut(value)

        const newPrice = safeDiv(newValue, amountIn)
        setPrice(newPrice.toString())
      }
    },
    [amountIn],
  )

  const handlePriceChange = useCallback(
    (value: number) => {
      const newPrice = big(value)
      setPrice(value)

      const newAmountOut = newPrice.times(amountIn)
      setAmountOut(newAmountOut.toNumber())
    },
    [amountIn],
  )

  const handleTimelockPeriodChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = Number(e.target.value)
      if (!Number.isNaN(value)) {
        const newValue =
          e.target.value.length === 0
            ? ''
            : Number.parseInt(value.toString(), 10).toString()
        setTimelockPeriod(newValue)
      }
    },
    [],
  )

  const handleDateChange = useCallback((date: Date) => {
    setSelectedDate(date)
  }, [])

  const handleTermsChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTerms(e.target.value)
  }, [])

  const handleContactChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setContact(e.target.value)
    },
    [],
  )

  const toggleAdvancedSettings = () =>
    setAdvancedSettingsExpanded((prev) => !prev)

  const toggleIsPartialOrder = () => setIsPartialOrder((prev) => !prev)

  const toggleIsExpired = () =>
    setIsExpiration((prevValue) => {
      const newValue = !prevValue
      if (!newValue) setSelectedDate(minDate)
      return newValue
    })

  const handleTakerAddressChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setTakerAddress(e.target.value)
    },
    [],
  )

  return {
    state: {
      tokenIn,
      tokenOut,
      selectedDate,
      advancedSettingsExpanded,
      isPartialOrder,
      takerAddress,
      timelockPeriod,
      terms,
      contact,
      amountIn,
      amountOut,
      price,
      isExpiration,
    },
    actions: {
      setTokenPair,
      setAmountIn,
      setAmountOut,
      handleAmountInChange,
      handleAmountOutChange,
      handlePriceChange,
      handleTimelockPeriodChange,
      handleDateChange,
      handleTermsChange,
      handleContactChange,
      toggleAdvancedSettings,
      toggleIsPartialOrder,
      toggleIsExpired,
      handleTakerAddressChange,
    },
  }
}

export default useCreateOfferForm
