import { TrendingFlat } from '@rimble/icons'
import { prettifyBalance } from '@swarm/core/shared/utils/formatting'
import { safeDiv } from '@swarm/core/shared/utils/helpers/big-helpers'
import { normalizeUSDCE } from '@swarm/core/shared/utils/tokens'
import { NormalizedXOffer } from '@swarm/types/normalized-entities/x-offer'
import TokenIcon from '@swarm/ui/swarm/TokenIcon'
import { differenceInHours, format, fromUnixTime } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { Box, Button, Flex, Text } from 'rimble-ui'

import { useTakeOfferPopup } from '../TakeOffer'

import OfferActionIcons from './OfferActionIcons'

interface OfferRowProps {
  offer: NormalizedXOffer
  isDirectMode: boolean
}

const OfferRow = ({ offer, isDirectMode }: OfferRowProps) => {
  const { t } = useTranslation('otc')
  const { depositAsset, withdrawalAsset } = offer
  const tokenIn = normalizeUSDCE(depositAsset)
  const tokenOut = normalizeUSDCE(withdrawalAsset)
  const { openTakeOfferPopup } = useTakeOfferPopup()

  const expiresAt = format(fromUnixTime(offer.expiresAt), 'MMMM dd, yyyy HH:mm')

  const timelockHours =
    differenceInHours(fromUnixTime(offer.timelockPeriod), Date.now()) || 1

  return (
    <>
      <tr key={offer.id}>
        <td title={offer.id}>
          <TokenIcon
            width="20px"
            height="20px"
            symbol={tokenIn?.symbol}
            name={tokenIn?.name}
          />
          <TrendingFlat mx="5px" />
          <TokenIcon
            width="20px"
            height="20px"
            symbol={tokenOut?.symbol}
            name={tokenOut?.name}
          />
        </td>
        <Flex as="td" flexDirection="column">
          <Box>
            <Text.span lineHeight="24px">
              {`${prettifyBalance(offer.availableAmount)} ${tokenIn?.symbol}`}
            </Text.span>
          </Box>
        </Flex>
        <td>
          <Box>
            <Text.span lineHeight="24px">
              {`${prettifyBalance(
                !isDirectMode ? offer.price : safeDiv(1, offer.price),
              )} ${!isDirectMode ? tokenOut?.symbol : tokenIn?.symbol} / ${
                !isDirectMode ? tokenIn?.symbol : tokenOut?.symbol
              }`}
            </Text.span>
          </Box>
        </td>
        <td>
          {!offer.isFullType ? (
            <Text.span lineHeight="20px">{t('noMinimum')}</Text.span>
          ) : (
            <Text.span lineHeight="24px">
              {prettifyBalance(offer.amountIn)} {tokenIn?.symbol}
            </Text.span>
          )}
        </td>
        <td>
          <Text.span lineHeight="20px">
            {fromUnixTime(offer.timelockPeriod).getTime() > Date.now()
              ? t('lockHours', { hours: timelockHours })
              : '--'}
          </Text.span>
        </td>
        <td>
          <Text.span lineHeight="20px">{expiresAt}</Text.span>
        </td>
        <Box as="td">
          <Button
            onClick={() => openTakeOfferPopup(offer)}
            fontWeight="600"
            lineHeight="20px"
            height="28px"
            width="120px"
            fontSize="14px"
            px={['4px', '16px']}
          >
            {t('buy')} {tokenIn?.symbol}
          </Button>
        </Box>
        <td>
          <Flex justifyContent="flex-end">
            <OfferActionIcons offer={offer} />
          </Flex>
        </td>
      </tr>
    </>
  )
}

export default OfferRow
