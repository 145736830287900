import AbstractContract, {
  ContractInstances,
} from '@swarm/core/contracts/AbstractContract'
import { getCurrentConfig } from '@swarm/core/observables/configForNetwork'
import { safeDiv } from '@swarm/core/shared/utils/helpers/big-helpers'
import { BigSource } from 'big.js'
import { BigNumber } from 'ethers'

import abi from 'src/contracts/abi'

import type { XDotcManager as XDotcManagerContract } from './typechain'

const { xDotcManagerAddress } = getCurrentConfig()

export class XDotcManager extends AbstractContract {
  contract: XDotcManagerContract | undefined

  static instances: ContractInstances<XDotcManager> = {}

  constructor() {
    super(xDotcManagerAddress, abi.XDotcManager)
  }

  static getInstance = async (): Promise<XDotcManager> => {
    if (!XDotcManager.instances[xDotcManagerAddress]) {
      XDotcManager.instances[xDotcManagerAddress] = new XDotcManager()
      await XDotcManager.instances[xDotcManagerAddress].init()
    }
    return XDotcManager.instances[xDotcManagerAddress]
  }

  private getFeeAmount = async (): Promise<BigSource> => {
    this.init()
    this.updateSigner()

    try {
      const fee = (await this.contract?.feeAmount()) as BigNumber
      return fee.toString()
    } catch {
      return '0'
    }
  }

  private getBpsNumber = async (): Promise<BigSource> => {
    this.init()
    this.updateSigner()

    try {
      const bps = (await this.contract?.BPS()) as BigNumber
      return bps.toString()
    } catch {
      return '0'
    }
  }

  static getFee = async () => {
    const otcContract = await XDotcManager.getInstance()

    const feeAmount = await otcContract.getFeeAmount()
    const bps = await otcContract.getBpsNumber()

    return safeDiv(feeAmount, bps).toNumber()
  }
}
