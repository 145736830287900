import { useAccount } from '@swarm/core/web3'
import { InvestToken } from '@swarm/types/tokens/invest'
import { RouterLink } from '@swarm/ui/presentational/RouterLink'
import Tooltip from '@swarm/ui/presentational/Tooltip'
import SvgIcon from '@swarm/ui/swarm/SvgIcon'
import { useTranslation } from 'react-i18next'
import { Button, Flex, Icon, Text } from 'rimble-ui'

import { ROUTES } from 'src/routes'

interface PopupActionsRowProps {
  token: InvestToken
  onRedeemClick?: () => void
  disableRedeem?: boolean
}

const PopupActionsRow = ({
  token,
  onRedeemClick,
  disableRedeem = false,
}: PopupActionsRowProps) => {
  const { t } = useTranslation(['invest', 'common'])
  const account = useAccount()

  return (
    <Flex
      width="100%"
      justifyContent="center"
      alignItems="center"
      style={{ gap: '16px' }}
      mt={2}
    >
      <RouterLink pathname={ROUTES.DOTC} queryParams={{ tokenOut: token.id }}>
        <Button height="28px" px={2} alignItems="center">
          <SvgIcon name="DOTCPageIcon" height="18px" width="18px" />

          <Text.span fontWeight={3} lineHeight="20px" fontSize={1} ml={1}>
            {t('common:token.dotc')}
          </Text.span>
        </Button>
      </RouterLink>
      <Flex justifyContent="center" alignItems="center">
        <Button
          height="28px"
          px={2}
          alignItems="center"
          disabled={
            disableRedeem || !account || !token.balance || token.balance?.eq(0)
          }
          onClick={onRedeemClick}
        >
          <SvgIcon name="RedeemIcon" height="18px" width="18px" fill="white" />
          <Text.span fontWeight={3} lineHeight="20px" fontSize={1} ml={1}>
            {t('common:token.redeem')}
          </Text.span>
        </Button>
        {!account && (
          <Tooltip
            placement="top"
            message={t('invest:liquidStaking.redeemDisabledTooltip')}
          >
            <Icon
              size="16px"
              name="Help"
              color="grey"
              ml={1}
              style={{ cursor: 'pointer' }}
            />
          </Tooltip>
        )}
      </Flex>
    </Flex>
  )
}

export default PopupActionsRow
