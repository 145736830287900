import { getNetworkIdByName, isPolygon } from '@swarm/core/shared/utils/config'
import { switchNetwork, useNetwork } from '@swarm/core/web3'
import { Trans, useTranslation } from 'react-i18next'
import { Button, Flex, Text } from 'rimble-ui'

const ComingSoonPlaceholder = () => {
  const { t } = useTranslation('invest')

  const network = useNetwork()

  const handleSwitchNetwork = async () => {
    const networkId = getNetworkIdByName(
      isPolygon(network.networkId) ? 'Ethereum' : 'Polygon',
    )
    if (networkId) {
      switchNetwork(networkId)
    }
  }
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      direction="column"
      width="100%"
      height="100%"
    >
      <Text fontWeight={500}>
        <Trans
          t={t}
          i18nKey="comingSoon"
          values={{
            currentNetwork: network.networkName,
            otherNetwork: isPolygon(network.networkId) ? 'Ethereum' : 'Polygon',
          }}
        >
          <Text fontWeight={500}>Coming soon</Text>
          <Button.Text
            onClick={handleSwitchNetwork}
            p={0}
            height="inherit"
            mainColor="text"
            fontWeight={600}
            style={{ textDecoration: 'underline' }}
          >
            switchTo
          </Button.Text>
          to see active offerings
        </Trans>
      </Text>
    </Flex>
  )
}

export default ComingSoonPlaceholder
