import { ArrowForward } from '@rimble/icons'
import { getCurrentConfig } from '@swarm/core/observables/configForNetwork'
import { useAccount } from '@swarm/core/web3'
import { DotcAsset } from '@swarm/types/tokens/dotc'
import AngledSwitch from '@swarm/ui/presentational/AngledSwitch'
import InfiniteTable from '@swarm/ui/presentational/InfiniteTable'
import ExplorerLink from '@swarm/ui/swarm/ExplorerLink'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Flex, Text } from 'rimble-ui'
import styled from 'styled-components/macro'
import {
  BooleanParam,
  QueryParamConfig,
  StringParam,
  useQueryParams,
} from 'use-query-params'

import useGoldOffers from 'src/hooks/data/useGoldOffers'

import { useCreateOfferPopup } from '../CreateOffer'
import { useDotcContext } from '../DotcContext'
import { TakeOfferPopupProvider } from '../TakeOffer'

import GoldOfferRow from './GoldOfferRow'
import TokenSelect from './StyledSelect'

const StyledInfiniteTable = styled(InfiniteTable)`
  col {
    width: 130px;
  }
  /* th {
    &:nth-child(4) {
      font-weight: bold;
      color: black;
    }
  } */
  th,
  td {
    &:nth-child(1) {
      text-align: left;
    }
    /* &:nth-child(4) {
      background-color: #e8f2fc;
    } */
    text-align: center;
  }
  td {
    color: ${(props) => props.theme.colors['near-black']};
  }

  colgroup.actionIcons {
    col {
      width: 40px !important;
    }
  }
`

type GoldQueryStringValues = {
  id: QueryParamConfig<string | null | undefined>
  tokenIn: QueryParamConfig<string | null | undefined>
  tokenOut: QueryParamConfig<string | null | undefined>
  showPrivate: QueryParamConfig<boolean | null | undefined>
}

const { xDotcAddress } = getCurrentConfig()

const GoldOffersTab = () => {
  const { openCreateOfferPopup } = useCreateOfferPopup()
  const { t } = useTranslation('otc')
  const account = useAccount()

  const [isPrivateOffers, setIsPrivateOffers] = useState(false)
  const togglePrivateOffers = () => setIsPrivateOffers((prev) => !prev)

  const { tokens, tokensLoading, tokensDictionary } = useDotcContext()
  const [tokenIn, setTokenIn] = useState<DotcAsset | undefined>()
  const [tokenOut, setTokenOut] = useState<DotcAsset | undefined>()

  const [queryParams, setQueryParams] = useQueryParams<GoldQueryStringValues>({
    id: StringParam,
    tokenIn: StringParam,
    tokenOut: StringParam,
    showPrivate: BooleanParam,
  })

  const [initiated, setInitiated] = useState(false)

  const {
    offers,
    loadingOffers,
    hasMore,
    loadMore,
    refetching,
    refreshOffers,
  } = useGoldOffers({
    tokenIn,
    tokenOut,
    isPrivateOffers,
    skip: tokensLoading,
  })

  useEffect(() => {
    if (!tokensLoading && !initiated) {
      const newTokenIn = queryParams.tokenIn
        ? tokensDictionary.get(queryParams.tokenIn)
        : undefined

      const newTokenOut = queryParams.tokenOut
        ? tokensDictionary.get(queryParams.tokenOut)
        : undefined

      setTokenIn(newTokenIn)
      setTokenOut(newTokenOut)

      const showPrivate = Boolean(queryParams.showPrivate)
      if (account) {
        setIsPrivateOffers(showPrivate)
      } else {
        setQueryParams({ showPrivate: undefined })
      }

      setInitiated(true)
    }
  }, [
    queryParams,
    tokensLoading,
    initiated,
    setInitiated,
    tokensDictionary,
    account,
    setQueryParams,
  ])

  useEffect(() => {
    if (initiated) {
      setQueryParams({
        tokenIn: tokenIn?.id,
        tokenOut: tokenOut?.id,
        showPrivate: isPrivateOffers || undefined,
      })
    }
  }, [initiated, isPrivateOffers, setQueryParams, tokenIn?.id, tokenOut?.id])

  useEffect(() => {
    if (!account) {
      setIsPrivateOffers(false)
    }
  }, [account])

  const resetFilters = () => {
    setTokenIn(undefined)
    setTokenOut(undefined)
    setIsPrivateOffers(false)
    setQueryParams({ id: undefined }, 'replaceIn')
  }

  const head = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    return (
      <tr>
        <th>{t(`th.asset`)}</th>
        <th scope="colgroup">{t(`th.offering`)}</th>
        <th scope="colgroup">{t(`th.location`)}</th>
        <th>
          {/* <Flex justifyContent="center" alignItems="center"> */}
          {t(`th.atPrice`)}
          {/* <SwapHoriz color="primary" /> */}
          {/* </Flex> */}
        </th>
        <th scope="colgroup">{t(`th.expiresOn`, { timezone })}</th>
        <th> </th>
        <th> </th>
      </tr>
    )
  }

  const handleCreateOffer = () => {
    openCreateOfferPopup()
  }

  return (
    <>
      <Flex mb={3} alignItems="baseline" flexWrap="wrap">
        <Text.span ml="10px" mr="20px">
          {t('showMeOffers')}
        </Text.span>
        <TokenSelect
          label={t('for')}
          emptyValue={t('any')}
          onChange={setTokenIn}
          tokens={tokens}
          selectedToken={tokenIn}
          tokensLoading={tokensLoading}
          groupNFTsByAddress
        />
        <ArrowForward color="primary" mt="6px" />
        <TokenSelect
          label={t('in')}
          emptyValue={t('any')}
          onChange={setTokenOut}
          tokens={tokens}
          selectedToken={tokenOut}
          tokensLoading={tokensLoading}
          groupNFTsByAddress
        />
        <Flex ml={10}>
          <Text.span fontSize={1}>{t('privateOffers')}</Text.span>
          &nbsp;
          <AngledSwitch
            disabled={!account}
            checked={isPrivateOffers}
            onChange={togglePrivateOffers}
          />
        </Flex>
        {Object.values(queryParams).some(Boolean) && (
          <Button.Text fontWeight="300" ml="10px" onClick={resetFilters}>
            {t('resetFilters')}
          </Button.Text>
        )}
        <Button
          onClick={handleCreateOffer}
          color="primary"
          fontWeight="600"
          lineHeight="20px"
          height="40px"
          fontSize="14px"
          ml="auto"
          mr="16px"
          px={['4px', '16px']}
        >
          {t('createOffer')}
        </Button>
        <ExplorerLink type="address" hash={xDotcAddress} iconOnly />
      </Flex>
      <TakeOfferPopupProvider reload={refreshOffers}>
        <StyledInfiniteTable
          colgroup={
            <>
              <colgroup>
                <col />
              </colgroup>
              <colgroup>
                <col />
              </colgroup>
              <colgroup>
                <col />
              </colgroup>
              <colgroup>
                <col />
              </colgroup>
              <colgroup>
                <col />
              </colgroup>
              <colgroup>
                <col />
              </colgroup>
              <colgroup className="actionIcons">
                <col />
              </colgroup>
            </>
          }
          head={head()}
          loading={loadingOffers}
          hasMore={hasMore}
          loadMore={loadMore}
          totalCols={7}
          noResults={
            <tr>
              <td colSpan={5}>
                <Text.p color="grey" textAlign="center" width="100%">
                  {t('noOffers')}
                </Text.p>
              </td>
            </tr>
          }
        >
          {refetching
            ? []
            : offers.map((offer) => (
                <GoldOfferRow key={offer.id} offer={offer} />
              ))}
        </StyledInfiniteTable>
      </TakeOfferPopupProvider>
    </>
  )
}

export default GoldOffersTab
