import { Button } from 'rimble-ui'
import styled from 'styled-components/macro'

export const FlipButton = styled(Button.Text)`
  padding: 0;
  height: auto;
  width: auto;
  min-width: auto;
  border-radius: 50%;
`
