import { useHiddenTokensLocalStorage } from '@swarm/core/shared/localStorage/hiddenTokensLocalStorage'
import { unifyAddressToId } from '@swarm/core/web3'

const useHiddenTokens = () => {
  const hiddenTokensStorage = useHiddenTokensLocalStorage()

  const hideToken = (address: string) => {
    hiddenTokensStorage.setValue((prevValue: string[]) => [
      ...prevValue,
      unifyAddressToId(address),
    ])
  }

  const setHiddenTokens = (addresses: string[]) => {
    hiddenTokensStorage.setValue(addresses.map(unifyAddressToId))
  }

  const showToken = (address: string) => {
    const hiddenTokens = hiddenTokensStorage.value
    const index = hiddenTokens.indexOf(unifyAddressToId(address))
    if (index > -1) {
      hiddenTokens.splice(index, 1)
    }
    hiddenTokensStorage.setValue([...hiddenTokens])
  }

  const clearHiddenTokens = () => {
    hiddenTokensStorage.removeValue()
  }

  const checkTokenHidden = (address: string) => {
    return hiddenTokensStorage.value.includes(unifyAddressToId(address))
  }

  const toggleToken = (address: string) => {
    if (checkTokenHidden(address)) {
      showToken(address)
    } else {
      hideToken(address)
    }
  }

  return {
    hiddenTokens: hiddenTokensStorage.value as string[],
    hideToken,
    showToken,
    checkTokenHidden,
    clearHiddenTokens,
    setHiddenTokens,
    toggleToken,
  }
}

export default useHiddenTokens
