import Content from '@swarm/ui/presentational/Content'
import Grid from '@swarm/ui/presentational/Grid'
import Header from '@swarm/ui/swarm/Header'
import { useTheme } from '@swarm/ui/theme/useTheme'
import React from 'react'

import useEmbedModeDetector from 'src/hooks/useEmbedModeDetector'

import Navigation from './Navigation'
import Footer from './Navigation/Footer'

interface LayoutProps {
  children?: React.ReactNode
  subheader?: React.ReactNode
  CustomNavigation?: React.ReactNode
  header?: React.ReactNode
  scrollable?: boolean
  headerActions?: React.ReactNode
}

const Layout = ({
  children,
  CustomNavigation,
  header,
  subheader,
  scrollable = false,
  headerActions,
}: LayoutProps) => {
  const theme = useTheme()
  const embedMode = useEmbedModeDetector()
  const isMainContentMode = embedMode === 'main_content'

  return (
    <>
      <Grid
        width="100%"
        height="100vh"
        gridTemplateColumns={isMainContentMode ? 'auto' : ['auto', 'auto 1fr']}
        gridTemplateRows={isMainContentMode ? 'auto' : '50px 1fr 50px'}
      >
        {isMainContentMode ? undefined : CustomNavigation === undefined ? (
          <Navigation />
        ) : (
          CustomNavigation
        )}

        <Content
          noPadding
          fullScreen={!scrollable}
          height={['auto', 'unset']}
          minHeight={['unset', '100vh']}
          maxHeight={['unset', '100vh']}
          overflow="auto"
        >
          {header !== undefined ? (
            <Header
              title={header}
              subheader={subheader}
              position={'sticky'}
              top={0}
              zIndex={theme.zIndices.layerOne}
              shadowOnScroll={scrollable}
            >
              {headerActions}
            </Header>
          ) : null}

          {children}
        </Content>

        {!isMainContentMode && <Footer isMobile />}
      </Grid>
      {/* <Flex
        width="100%"
        height="100vh"
        flexDirection={['column', 'row']}
        justifyContent="flex-start"
        transform="translateZ(0)"
      >
        {CustomNavigation === undefined ? <Navigation /> : CustomNavigation}

        <Content
          noPadding
          fullScreen={!scrollable}
          width={contentWidth}
          minHeight="100vh"
          marginTop={[mobileTopFixedHeight, desktopTopFixedHeight]}
          marginLeft={contentMargintLeft}
        >
          {header !== undefined ? (
            <Header
              title={header}
              subheader={subheader}
              position={scrollable ? 'sticky' : 'relative'}
              top={
                scrollable ? [mobileTopFixedHeight, desktopTopFixedHeight] : 0
              }
              zIndex={theme.zIndices.layerOne}
              shadowOnScroll={scrollable}
            >
              {headerActions}
            </Header>
          ) : null}

          {children}

          <Box
            display={['block', 'none']}
            flexShrink={0}
            width="100%"
            height={theme.layout.footer.heightPixels}
          />
        </Content>

        <Footer isMobile />
      </Flex> */}
    </>
  )
}

export default Layout
