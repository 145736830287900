import { useCpk } from '@swarm/core/contracts/cpk'
import useXDotcOffers from '@swarm/core/hooks/subgraph/x-dotc/useXDotcOffers'
import blotout from '@swarm/core/services/blotout'
import { big, safeDiv } from '@swarm/core/shared/utils/helpers/big-helpers'
import {
  injectCpkAllowance,
  injectCpkTokenBalance,
  useInjections,
} from '@swarm/core/shared/utils/tokens/injectors'
import { useAccount } from '@swarm/core/web3'
import { NormalizedXOffer } from '@swarm/types/normalized-entities/x-offer'
import {
  AbstractToken,
  HasCpkAllowance,
  HasCpkBalance,
} from '@swarm/types/tokens'
import { BigSource } from 'big.js'
import { getUnixTime } from 'date-fns'
import { useCallback, useMemo, useRef, useState } from 'react'

import { useDotcContext } from 'src/components/XDotc/DotcContext'
import { XDotcContract } from 'src/contracts/XDotcContract'

const offsetPageLimit = 10

export type Order = {
  offer: NormalizedXOffer
  amount: BigSource // It's a price that can be paid for offer (above) available amount
}

type FillInAmountOffersReturn = {
  orders: Order[]
  amountIn: BigSource
  amountOut: BigSource
}

export type BatchToken = AbstractToken & HasCpkBalance & HasCpkAllowance

interface UseFullFillOrderProps {
  tokenIn: BatchToken
  tokenOut: BatchToken
  amountPaid: BigSource
}

const useFullFillOrder = ({
  tokenIn,
  tokenOut,
  amountPaid,
}: UseFullFillOrderProps) => {
  const cpk = useCpk()
  const { current: currentTimestamp } = useRef(getUnixTime(Date.now()))
  const account = useAccount()
  const { tokensLoading } = useDotcContext()
  const { offers, loadingOffers, fetchMore } = useXDotcOffers({
    variables: {
      filter: {
        isCompleted: false,
        cancelled: false,
        isPrivate: false,
        // eslint-disable-next-line camelcase
        expiresAt_gt: currentTimestamp.toString(),
        withdrawalAsset: tokenIn?.id, // Tokens reverted because tokenIn is token that you will pay but in dOTC it's vise-versa (due to order during offer creation)
        depositAsset: tokenOut?.id,
      },
      limit: 20,
    },
    skip: tokensLoading,
  })

  const [averagePrice, setAveragePrice] = useState<BigSource | undefined>(0)
  const [tokenAmountOut, setTokenAmountOut] = useState<BigSource | undefined>(0)
  const [tokenAmountIn, setTokenAmountIn] = useState<BigSource | undefined>(0)
  const [isAllFetched, setAllFetched] = useState<boolean>(false)
  const [amountOverflowed, setAmountOverflowed] = useState<boolean>(false)

  const [tokenInInjected] = useInjections<BatchToken>(
    [tokenIn],
    useMemo(
      () => [injectCpkTokenBalance(cpk?.address), injectCpkAllowance(account)],
      [account, cpk?.address],
    ),
  )

  const loadingCalculation =
    tokenAmountOut === undefined || averagePrice === undefined

  const loading =
    loadingOffers ||
    !cpk ||
    !tokenIn ||
    !account ||
    !tokenInInjected?.cpkBalance ||
    !tokenOut?.cpkBalance

  const createOffersBatch = useCallback((): FillInAmountOffersReturn => {
    // TODO: Calculations should be moved to separate file
    let amountIn = big(0)
    let amountOut = big(0)
    let amountOver = big(0)

    const orders: Order[] = []

    if (!amountPaid) {
      setTokenAmountOut(0)
      setAveragePrice(0)
      setAmountOverflowed(false)

      return {
        amountIn: amountIn.toString(),
        amountOut: amountOut.toString(),
        orders,
      }
    }

    setAmountOverflowed(false)
    setTokenAmountOut(undefined)
    setAveragePrice(undefined)

    async function calculateOffersAmount(): Promise<void> {
      const offersCopy = [...offers].sort((a, b) =>
        a.price > b.price ? 1 : -1,
      )
      const restOffers =
        offersCopy.length > offsetPageLimit
          ? offersCopy.slice(
              offersCopy.length,
              offersCopy.length - offsetPageLimit,
            )
          : offersCopy

      restOffers.forEach((offer) => {
        if (amountOver.gt(0)) return

        const { price, availableAmount } = offer
        const fullOfferAmount = big(price)
          .mul(availableAmount)
          .round(tokenIn?.decimals, 2)

        if (
          amountIn.lt(amountPaid) &&
          amountIn.plus(fullOfferAmount).gt(amountPaid) &&
          !offer.isFullType
        ) {
          const amount = big(amountPaid).minus(amountIn)

          orders.push({
            offer,
            amount: amount.toString(),
          })

          amountIn = amountIn.plus(amount)
          amountOut = amountOut.plus(safeDiv(amount, price))
          return
        }

        if (amountIn.plus(fullOfferAmount).gt(amountPaid)) {
          amountOver = amountIn.plus(fullOfferAmount)

          const diffMinPrice = big(amountPaid).minus(amountIn).abs()
          const diffMaxPrice = big(amountPaid).minus(amountOver).abs()
          if (diffMinPrice.gt(diffMaxPrice)) {
            const amount = big(amountOver).minus(amountIn)
            amountIn = amountOver

            orders.push({
              offer,
              amount: amount.toString(),
            })

            amountOut = amountOut.plus(safeDiv(amount, price))
          }
          return
        }

        if (amountIn.lt(amountPaid)) {
          orders.push({
            offer,
            amount: fullOfferAmount.toString(),
          })

          amountIn = amountIn.plus(fullOfferAmount)
          amountOut = amountOut.plus(safeDiv(fullOfferAmount.toString(), price))
        }
      })

      if (amountIn.lt(amountPaid) && !isAllFetched && amountOver.eq(0)) {
        const {
          data: { xOffers: fetchedOffers },
        } = await fetchMore(0)

        const lastFetchedOffer = fetchedOffers[fetchedOffers.length - 1]
        const lastCurrOffer = offers[offers.length - 1]
        if (lastFetchedOffer?.id === lastCurrOffer?.id) {
          setAllFetched(true)
          return
        }
        calculateOffersAmount()
      }
    }

    calculateOffersAmount()

    setAmountOverflowed(amountOver.gt(amountPaid))
    setTokenAmountIn(amountIn.toString())
    setTokenAmountOut(amountOut.toString())
    setAveragePrice(safeDiv(amountIn, amountOut).toString())

    return {
      amountIn: amountIn.toString(),
      amountOut: amountOut.toString(),
      orders,
    }
  }, [offers, amountPaid, isAllFetched, tokenIn?.decimals, fetchMore])

  const takeOffersBatch = useCallback(async () => {
    if (loading || !cpk.address) {
      return undefined
    }

    const { orders, amountIn, amountOut } = createOffersBatch()

    const res = await XDotcContract.takeOffersBatch(
      orders,
      tokenInInjected,
      tokenOut,
      amountIn,
      amountOut,
      account,
    )

    orders.forEach((order) => {
      const { offer } = order
      blotout.captureTakenDotcOffer(offer.id, order.amount.toString())
    })

    return res
  }, [
    account,
    cpk?.address,
    createOffersBatch,
    loading,
    tokenInInjected,
    tokenOut,
  ])

  const noMatching = useMemo(() => {
    const noOffers = offers.length === 0
    return noOffers
  }, [offers])

  const noExactOffer = useMemo(() => {
    if (!tokenAmountIn) return false
    const tooHighAmountPaid = big(amountPaid).gt(tokenAmountIn)
    const tooHighAmountIn =
      big(tokenAmountIn).gt(amountPaid) && amountOverflowed
    return tooHighAmountPaid || tooHighAmountIn
  }, [amountOverflowed, amountPaid, tokenAmountIn])

  return useMemo(
    () => ({
      loadingOffersBatch: Boolean(loading),
      loadingCalculation,
      takeOffersBatch,
      createOffersBatch,
      averagePrice,
      amountIn: tokenAmountIn,
      amountOut: tokenAmountOut,
      amountOverflowed,
      noMatching,
      noExactOffer,
    }),
    [
      loading,
      takeOffersBatch,
      createOffersBatch,
      averagePrice,
      tokenAmountIn,
      tokenAmountOut,
      loadingCalculation,
      amountOverflowed,
      noMatching,
      noExactOffer,
    ],
  )
}

export default useFullFillOrder
