import { useWindowStorage } from '@core/hooks/useWindowStorage'
import {
  arrayDecoder,
  arrayEncoder,
  LocalStorageService,
} from '@core/services/window-storage/local-storage'

export const hiddenTokensLocalStorage = new LocalStorageService<string[]>(
  'hidden-tokens',
  arrayEncoder(),
  arrayDecoder([]),
)
export const useHiddenTokensLocalStorage = () =>
  useWindowStorage(hiddenTokensLocalStorage)
