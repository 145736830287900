import { useCpk } from '@swarm/core/contracts/cpk'
import useAsyncState from '@swarm/core/hooks/async/useAsyncState'
import useEffectCompare from '@swarm/core/hooks/effects/useEffectCompare'
import useTransactionAlerts from '@swarm/core/hooks/i18n/useTransactionAlerts'
import usePopupState from '@swarm/core/hooks/state/usePopupState'
import { useToggle } from '@swarm/core/hooks/state/useToggle'
import useEnableAsset from '@swarm/core/hooks/web3/useEnableAsset'
import { useAllowanceOf } from '@swarm/core/observables/allowanceOf'
import { TokenType } from '@swarm/core/shared/enums'
import { isSecurityToken } from '@swarm/core/shared/utils'
import { propEquals } from '@swarm/core/shared/utils/collection'
import { big } from '@swarm/core/shared/utils/helpers/big-helpers'
import autoRound from '@swarm/core/shared/utils/math/autoRound'
import {
  injectTokenBalance,
  normalizeUSDCE,
  useInjections,
} from '@swarm/core/shared/utils/tokens'
import { useAccount } from '@swarm/core/web3/account'
import { AbstractAsset } from '@swarm/types/tokens'
import { InvestToken } from '@swarm/types/tokens/invest'
import TokenAmountInput from '@swarm/ui/swarm/Input/TokenAmountInput/TokenAmountInput'
import { useSnackbar } from '@swarm/ui/swarm/Snackbar'
import TokenBalance from '@swarm/ui/swarm/TokenBalance'
import { providers } from 'ethers'
import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'
import omit from 'lodash/omit'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Flex, Text } from 'rimble-ui'

import useFullFillOrder, { BatchToken, Order } from 'src/hooks/useFullFillOrder'

import AssetTokenDOTCInfo from '../../AssetToken/AssetTokenDOTCInfo'

import InvestActionButton from './InvestActionButton'
import PurchaseConfirmationModal, {
  PurchaseConfirmationModalProps,
} from './PurchaseConfirmationModal'
import TakeBatchConfirmationModal from './TakeBatchConfirmationModal'
import useCardText from './useCardText'

interface ExpandedCardProps {
  token: InvestToken
  onClose?: () => void
}

const ExpandedCard = ({ token, onClose }: ExpandedCardProps) => {
  const { t } = useTranslation(['invest', 'otc'])
  const account = useAccount()
  const [txLoading, setTxLoading] = useAsyncState(false)
  const { track } = useTransactionAlerts()
  const cpk = useCpk()
  const [orders, setOrders] = useState<Order[]>([])
  const { addError } = useSnackbar()

  const {
    on: openTakeConfirmationModal,
    off: closeTakeConfirmationModal,
    isOn: isOpenTakeConfirmationModal,
  } = useToggle()

  const text = useCardText(token.assetType)
  const [enableAsset, { txLoading: enableTxLoading }] = useEnableAsset()

  const confirmationModal = usePopupState<PurchaseConfirmationModalProps>(
    false,
    PurchaseConfirmationModal,
  )

  const authorizedAssets = useMemo(() => {
    return token.authorizedAssets.map(normalizeUSDCE)
  }, [token.authorizedAssets])

  const [selectedTokenId, setSelectedTokenId] = useState<string>(
    authorizedAssets?.[0]?.id,
  )

  const selectedToken = useMemo(
    () => authorizedAssets.find(propEquals('id', selectedTokenId)),
    [authorizedAssets, selectedTokenId],
  )
  const [selectedTokenWithBalance] = useInjections(
    selectedToken ? [omit(selectedToken, ['xToken', 'tvl'])] : [],
    useMemo(() => [injectTokenBalance(account)], [account]),
  )
  // const selectedTokenWithBalance = useMemo<ExtendedNativeToken>(
  //   () => (selectedTokenWithBalance),
  //   [selectedToken, selectedTokenWithBalance],
  // )

  const [amount, setAmount] = useState(0)

  const {
    createOffersBatch,
    takeOffersBatch,
    averagePrice,
    amountIn,
    amountOut,
    noExactOffer,
    noMatching,
    loadingOffersBatch,
    loadingCalculation,
    amountOverflowed,
  } = useFullFillOrder({
    tokenIn: selectedTokenWithBalance as BatchToken,
    tokenOut: token,
    amountPaid: amount,
  })

  const handleAmountChange = useCallback((value: number) => {
    if (!Number.isNaN(value)) {
      setAmount(value)
    }
  }, [])

  useEffectCompare(() => {
    if (!loadingOffersBatch && !loadingCalculation && big(amount).gt(0)) {
      const { orders: calculatedOrders } = createOffersBatch()
      if (!isEqual(calculatedOrders, orders)) {
        setOrders(calculatedOrders)
      }
    }
  }, [
    amount,
    orders,
    loadingOffersBatch,
    createOffersBatch,
    loadingCalculation,
  ])

  const allowanceAddress = isSecurityToken(token)
    ? cpk?.address
    : token.issuer?.id
  const allowance = useAllowanceOf(account, allowanceAddress, selectedTokenId)

  const paymentTokenExchangeRate = useMemo(() => {
    if (
      isNil(token.exchangeRate) ||
      isNil(selectedTokenWithBalance?.exchangeRate) ||
      big(selectedTokenWithBalance?.exchangeRate ?? 0).eq(0)
    ) {
      return null
    }

    return big(token.exchangeRate).div(
      selectedTokenWithBalance?.exchangeRate ?? 1,
    )
  }, [selectedTokenWithBalance?.exchangeRate, token.exchangeRate])

  const amountExceedsBalance =
    selectedTokenWithBalance?.balance &&
    selectedTokenWithBalance?.balance?.lt(amount)
      ? t('errors:amountExceedsBalance')
      : undefined

  const showCreateOffer = noExactOffer || noMatching

  const loading =
    txLoading || isNil(allowance) || loadingOffersBatch || enableTxLoading

  const buttonDisabled =
    amount === 0 || big(amountOut)?.eq(0) || amountExceedsBalance

  const handleTokenChange = (newToken: AbstractAsset) =>
    setSelectedTokenId(newToken.id)

  const handleEnableToken = useCallback(async () => {
    if (isSecurityToken(token) && selectedToken && cpk && cpk.address) {
      return enableAsset(selectedToken, cpk.address, TokenType.erc20)
    }
  }, [token, selectedToken, cpk, enableAsset])

  const handleBuyClick = useCallback(async () => {
    setTxLoading(true)
    try {
      const tx = await takeOffersBatch()
      if (!tx) return

      await track(tx)

      closeTakeConfirmationModal()

      confirmationModal.open({
        tx: tx as providers.TransactionResponse,
        asset: token,
        amount: big(amountOut),
      })
    } catch (e) {
      addError(e)
    } finally {
      setTxLoading(false)
    }
  }, [
    addError,
    amountOut,
    closeTakeConfirmationModal,
    confirmationModal,
    setTxLoading,
    takeOffersBatch,
    token,
    track,
  ])

  const handleButtonClick = useCallback(async () => {
    if (!account) return

    if (selectedTokenWithBalance && allowance?.lt(amount)) {
      await handleEnableToken()
    } else {
      openTakeConfirmationModal()
    }
  }, [
    account,
    selectedTokenWithBalance,
    allowance,
    amount,
    handleEnableToken,
    openTakeConfirmationModal,
  ])

  return (
    <Box p={3}>
      <Flex justifyContent="space-between" alignItems="center" mb={3}>
        <Text.span fontSize={1} color="grey" fontWeight="500">
          {t('stockTokens.expandedCardDescription', { token: token.symbol })}
        </Text.span>
        <Button.Text
          icononly
          bg="transparent"
          mainColor="grey"
          icon="Close"
          height="28px"
          onClick={onClose}
          boxShadow={0}
          minWidth="0"
        />
      </Flex>

      <Flex justifyContent="space-between" alignItems="center" mb={2}>
        <Text.span color="grey">{t('stockTokens.payWith')}</Text.span>
        {selectedTokenWithBalance && (
          <TokenAmountInput
            selected={selectedTokenWithBalance}
            tokens={authorizedAssets}
            amount={amount}
            max={selectedTokenWithBalance?.balance?.toNumber() || 0}
            onTokenChange={handleTokenChange}
            onAmountChange={handleAmountChange}
            readonly={authorizedAssets.length === 1}
            error={amountExceedsBalance}
            disabled={txLoading || enableTxLoading}
          />
        )}
      </Flex>
      <AssetTokenDOTCInfo
        tokenIn={selectedTokenWithBalance}
        tokenOut={token}
        loading={loadingOffersBatch || loadingCalculation}
        averagePrice={averagePrice ?? 0}
        amountIn={amountIn ?? 0}
        amountOut={amountOut ?? 0}
        onAmountChange={handleAmountChange}
        amountOverflowed={amountOverflowed}
        noExactOffer={noExactOffer}
        noMatching={noMatching}
      />

      <InvestActionButton
        width="100%"
        height="32px"
        fontSize={1}
        my={3}
        onClick={handleButtonClick}
        disabled={buttonDisabled}
        loading={loading}
        showCreateOffer={showCreateOffer}
        allowance={allowance}
        amount={amount}
        tokenIn={selectedTokenWithBalance}
        tokenOut={token}
      />

      <Flex justifyContent="space-between" alignItems="center" mb={2}>
        <Text.span color="grey" fontSize="14px">
          {t('stockTokens.yourBalance')}
        </Text.span>
        <TokenBalance
          tokenAddress={selectedTokenWithBalance?.id}
          account={account}
        />
      </Flex>
      <Flex justifyContent="space-between" alignItems="center" mb={2}>
        <Text.span color="grey" fontSize="14px">
          {text.currentPrice}
        </Text.span>
        <Text.span
          title={
            !isNil(paymentTokenExchangeRate)
              ? `1 ${token.symbol} ≈ ${autoRound(paymentTokenExchangeRate, {
                  accuracy: selectedTokenWithBalance?.decimals,
                })} ${selectedTokenWithBalance?.symbol}`
              : '--'
          }
          fontSize="14px"
        >
          {!isNil(paymentTokenExchangeRate)
            ? `1 ${token.symbol} ≈ $${autoRound(paymentTokenExchangeRate)}`
            : '--'}
        </Text.span>
      </Flex>

      <TakeBatchConfirmationModal
        amountIn={amountIn}
        amountOut={amountOut}
        tokenIn={selectedToken}
        tokenOut={token}
        isOpen={isOpenTakeConfirmationModal}
        onClose={closeTakeConfirmationModal}
        orders={orders}
        onConfirm={handleBuyClick}
      />

      {confirmationModal.modal}
    </Box>
  )
}

export default ExpandedCard
