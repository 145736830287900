import { ApolloProvider } from '@apollo/client'
import {
  createTheme,
  ThemeProvider as MaterialUIThemeProvider,
} from '@material-ui/core/styles'
import { BreakpointsOptions } from '@material-ui/core/styles/createBreakpoints'
import { useWindowStorageSync } from '@swarm/core/hooks/useWindowStorageSync'
import xClient from '@swarm/core/services/apollo/x-client'
import { RequestCacheProvider } from '@swarm/core/services/cache/useRequestCache'
import { useTermsAndAgreementsStorage } from '@swarm/core/shared/localStorage'
import { AppContextProvider } from '@swarm/core/state/AppContext'
import { FeatureFlagsContextProvider } from '@swarm/core/state/FeatureFlagsContext'
import { FireworksProvider } from '@swarm/ui/presentational/Fireworks'
import RouteNotFound from '@swarm/ui/presentational/RouteNotFound'
import { SnackbarProvider } from '@swarm/ui/swarm/Snackbar'
import theme from '@swarm/ui/theme'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import {
  generatePath,
  Redirect,
  Route,
  HashRouter as Router,
} from 'react-router-dom'
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components/macro'
import { QueryParamProvider } from 'use-query-params'

import TermsAndAgreementsModal from 'src/components/TermsAndAgreementsModal'
import { ROUTES } from 'src/routes'

import useEmbedModeDetector from './hooks/useEmbedModeDetector'
import InvestPage from './pages/InvestPage'
import XDotcPage from './pages/XDotcPage'

const queryClient = new QueryClient()

function BaseApp() {
  const { value: termsAccepted } = useTermsAndAgreementsStorage()
  const embedMode = useEmbedModeDetector()
  const isMainContentMode = embedMode === 'main_content'

  const breakpoints = {
    xs: 0,
    sm: 640,
    md: 832,
    lg: 1024,
    xl: 1920,
  } as BreakpointsOptions
  const mainContentBreakpoints = {
    xs: 0,
    sm: 440,
    md: 632,
    lg: 724,
    xl: 1620,
  } as BreakpointsOptions

  useWindowStorageSync()

  return (
    <AppContextProvider>
      <QueryClientProvider client={queryClient}>
        <ApolloProvider client={xClient}>
          <RequestCacheProvider>
            <MaterialUIThemeProvider
              theme={createTheme({
                ...(theme as Record<string, unknown>),
                breakpoints: isMainContentMode
                  ? mainContentBreakpoints
                  : breakpoints,
              })}
            >
              <StyledComponentsThemeProvider theme={theme}>
                <SnackbarProvider>
                  <FireworksProvider>
                    <TermsAndAgreementsModal isOpen={!termsAccepted} />
                    <FeatureFlagsContextProvider>
                      <Route
                        exact
                        path={ROUTES.HOMEPAGE}
                        component={() => (
                          <Redirect to={generatePath(ROUTES.DOTC)} />
                        )}
                      />
                      <Route
                        exact
                        path={ROUTES.DOTC_CATEGORY}
                        component={XDotcPage}
                      />
                      <Route path="*">
                        <RouteNotFound appRoutes={ROUTES} />
                      </Route>
                      <Route
                        exact
                        path={[ROUTES.INVEST, ROUTES.INVEST_CATEGORY]}
                        component={InvestPage}
                      />
                    </FeatureFlagsContextProvider>
                  </FireworksProvider>
                </SnackbarProvider>
              </StyledComponentsThemeProvider>
            </MaterialUIThemeProvider>
          </RequestCacheProvider>
        </ApolloProvider>
      </QueryClientProvider>
    </AppContextProvider>
  )
}

function App() {
  return (
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <BaseApp />
      </QueryParamProvider>
    </Router>
  )
}

export default App
