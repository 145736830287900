/* eslint-disable camelcase */
import useXDotcOffers from '@swarm/core/hooks/subgraph/x-dotc/useXDotcOffers'
import { getAssetFilter } from '@swarm/core/shared/subgraph'
import { unifyAddressToId, useAccount } from '@swarm/core/web3'
import { DotcAsset } from '@swarm/types/tokens/dotc'
import { XOfferFilter } from '@swarm/types/x-subgraph/graphql'
import { getUnixTime } from 'date-fns'
import { useCallback, useMemo, useRef } from 'react'
import { BooleanParam, StringParam, useQueryParams } from 'use-query-params'

interface UseSharedOffersOptions {
  tokenIn?: DotcAsset
  tokenOut?: DotcAsset
  qualifiedMakers?: string[]
  isPrivateOffers?: boolean
  skip?: boolean
}

const useSharedOffers = ({
  tokenIn,
  tokenOut,
  isPrivateOffers = false,
  qualifiedMakers = [],
  skip = false,
}: UseSharedOffersOptions) => {
  const account = useAccount()
  const [queryParams] = useQueryParams({
    id: StringParam,
    tokenIn: StringParam,
    tokenOut: StringParam,
    showPrivate: BooleanParam,
  })

  const { current: currentTimestamp } = useRef(getUnixTime(Date.now()))

  const xDotcOffersFilter = useMemo<XOfferFilter>(
    () => ({
      ...(queryParams.id && {
        id: queryParams.id,
      }),
      isCompleted: false,
      cancelled: false,
      expiresAt_gt: currentTimestamp.toString(),
      isPrivate: isPrivateOffers,
      ...(qualifiedMakers.length > 0 && {
        maker_in: qualifiedMakers.map(unifyAddressToId),
      }),
      ...(isPrivateOffers &&
        account && {
          specialAddresses_contains_nocase: [account],
        }),
      ...getAssetFilter(tokenIn, tokenOut),
    }),
    [
      account,
      currentTimestamp,
      isPrivateOffers,
      qualifiedMakers,
      queryParams.id,
      tokenIn,
      tokenOut,
    ],
  )

  const { offers, loadingOffers, hasMore, fetchMore, refetching, refetch } =
    useXDotcOffers({
      variables: {
        filter: xDotcOffersFilter,
      },
      skip,
    })

  const refreshOffers = useCallback(
    (newFilter?: Partial<XOfferFilter>) =>
      refetch({
        ...xDotcOffersFilter,
        ...newFilter,
      }),
    [refetch, xDotcOffersFilter],
  )

  const loadMore = useCallback(async () => {
    if (offers && hasMore && !skip && !loadingOffers) {
      await fetchMore(offers?.length)
    }
  }, [offers, hasMore, skip, loadingOffers, fetchMore])

  return useMemo(
    () => ({
      offers,
      loadingOffers,
      hasMore,
      loadMore,
      refetching,
      refreshOffers,
    }),
    [hasMore, loadMore, loadingOffers, offers, refetching, refreshOffers],
  )
}

export default useSharedOffers
