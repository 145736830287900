import React from 'react'

interface ReturnValue<T> {
  id: T | undefined
  isOn: boolean
  toggleOn: (id: T) => void
  toggleOff: () => void
  reset: () => void
}

export function useIdToggle<T>(
  initialId: T | undefined = undefined,
): ReturnValue<T> {
  const [id, setId] = React.useState<T | undefined>(initialId)

  return React.useMemo<ReturnValue<T>>(() => {
    return {
      id,
      isOn: id !== undefined,
      toggleOn: (activeId: T) => {
        setId(activeId)
      },
      toggleOff: () => {
        setId(undefined)
      },
      reset: () => {
        setId(initialId)
      },
    }
  }, [id, initialId])
}
