import { StringParam, useQueryParams } from 'use-query-params'

import { DotcContextProvider } from 'src/components/XDotc/DotcContext'

import AssetTokenCard, {
  AssetTokenCardProps,
} from '../../AssetToken/AssetTokenCard'
import { InvestSecurityProvider } from '../../InvestSecurityContext'

import CollapsedCard from './CollapsedCard'
import ExpandedCard from './ExpandedCard'

const InvestAssetCard = ({
  token,
  expanded = false,
  onExpand,
  onCollapse,
  ...props
}: AssetTokenCardProps) => {
  const [queryParams, setQueryParams] = useQueryParams({ token: StringParam })
  const isExpanded =
    expanded || queryParams.token?.toLowerCase() === token.symbol.toLowerCase()

  const handleExpand = () => {
    onExpand?.(token.id)
    setQueryParams({ token: token.symbol }, 'replaceIn')
  }

  const handleCollapse = () => {
    onCollapse?.()
    setQueryParams({ token: undefined }, 'replaceIn')
  }

  return (
    <AssetTokenCard
      expanded={isExpanded}
      token={token}
      ExpandedCard={
        <DotcContextProvider>
          <InvestSecurityProvider>
            <ExpandedCard token={token} onClose={handleCollapse} />
          </InvestSecurityProvider>
        </DotcContextProvider>
      }
      CollapsedCard={<CollapsedCard token={token} onExpand={handleExpand} />}
      {...props}
    />
  )
}

export default InvestAssetCard
