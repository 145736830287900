import { getInputPrecision } from '@swarm/core/shared/utils/tokens/precision'
import { TokenSelectorAsset } from '@swarm/types/tokens'
import { Flex } from 'rimble-ui'
import styled from 'styled-components/macro'

import { TokenSelectorProps } from '../../TokenSelector'

import AmountInput from './AmountInput'
import TokenInput from './TokenInput'

const TokenAmountInputWrapper = styled(Flex)`
  border: 1px solid ${({ theme }) => theme.colors['light-gray']};
  border-radius: 4px;
  max-width: 70%;
  overflow: hidden;

  &:hover {
    box-shadow: 0px 1px 3px rgb(0 0 0 / 30%);
  }

  &:focus-within {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary};
  }

  ${({ error, theme }) =>
    error &&
    `
  border-color: ${theme.colors.danger};

  &:focus, &:focus-within {
    border-color: ${theme.colors.danger};
  } 
`}
`

interface TokenAmountInputProps<
  T extends TokenSelectorAsset = TokenSelectorAsset,
> extends Omit<TokenSelectorProps<T>, 'onChange'> {
  amount: number
  max?: number
  min?: number
  onTokenChange?: TokenSelectorProps['onChange']
  onAmountChange?: (amount: number) => void
  error?: string
  disabled?: boolean
}
const TokenAmountInput = ({
  tokens,
  selected,
  amount,
  max = Number.MAX_SAFE_INTEGER,
  min = 0,
  onTokenChange,
  onAmountChange,
  loading,
  readonly,
  filter,
  error,
  disabled = false,
}: TokenAmountInputProps) => {
  return (
    <TokenAmountInputWrapper error={error}>
      <TokenInput
        onChange={onTokenChange}
        selected={selected}
        tokens={tokens}
        loading={loading}
        readonly={loading || readonly || disabled}
        orderBy="balance"
        orderDirection="desc"
        filter={filter}
      />
      <AmountInput
        value={amount}
        onChange={onAmountChange}
        max={max}
        min={min}
        error={error}
        disabled={disabled}
        decimalScale={selected && getInputPrecision(selected)}
        reportError
      />
    </TokenAmountInputWrapper>
  )
}

export default TokenAmountInput
