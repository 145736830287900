import { TransactionResult } from 'contract-proxy-kit'

import AbstractContract, {
  ContractInstances,
} from '@core/contracts/AbstractContract'
import abi from '@core/contracts/abi'
import { getCurrentConfig } from '@core/observables/configForNetwork'
import { verify } from '@core/shared/utils/crypto'

import { getCpk } from './cpk'
import type { SwarmBuyerBurner as SwarmBuyerBurnerContract } from './typechain/SwarmBuyerBurner'

const { swarmBuyerBurnerAddress } = getCurrentConfig()

export class SwarmBuyerBurner extends AbstractContract {
  contract: SwarmBuyerBurnerContract | undefined

  static instances: ContractInstances<SwarmBuyerBurner> = {}

  constructor() {
    super(swarmBuyerBurnerAddress, abi.SwarmBuyerBurner)
  }

  static getInstance = async (): Promise<SwarmBuyerBurner> => {
    if (!SwarmBuyerBurner.instances[swarmBuyerBurnerAddress]) {
      SwarmBuyerBurner.instances[
        swarmBuyerBurnerAddress
      ] = new SwarmBuyerBurner()
      await SwarmBuyerBurner.instances[swarmBuyerBurnerAddress].init()
    }
    return SwarmBuyerBurner.instances[swarmBuyerBurnerAddress]
  }

  private swapAndBurnSMT = async (): Promise<TransactionResult> => {
    this.init()
    this.updateSigner()

    // Verify the CPK is defined
    const cpk = getCpk()
    verify(!!cpk, 'Could not obtain CPK')

    if (this.contract === undefined) {
      throw new Error('SwarmBuyerBurner contract is not initialized')
    }

    return this.contract.swapExactInputMultihop()
  }

  static swapAndBurnSMT = async () => {
    const instance = await SwarmBuyerBurner.getInstance()
    return instance.swapAndBurnSMT()
  }
}
