import { TrendingFlat } from '@rimble/icons'
import useAsyncState from '@swarm/core/hooks/async/useAsyncState'
import useTransactionAlerts from '@swarm/core/hooks/i18n/useTransactionAlerts'
import usePopupState from '@swarm/core/hooks/state/usePopupState'
import { RefetchXOffersFn } from '@swarm/core/hooks/subgraph/x-dotc/useXDotcOffers'
import { prettifyBalance } from '@swarm/core/shared/utils/formatting'
import { big, safeDiv } from '@swarm/core/shared/utils/helpers/big-helpers'
import wait from '@swarm/core/shared/utils/helpers/wait'
import { normalizeUSDCE } from '@swarm/core/shared/utils/tokens'
import { useAccount } from '@swarm/core/web3'
import { NormalizedXOffer } from '@swarm/types/normalized-entities/x-offer'
import Dialog from '@swarm/ui/presentational/Dialog'
import Tooltip from '@swarm/ui/presentational/Tooltip'
import SmartButton from '@swarm/ui/swarm/Buttons/SmartButton'
import { useSnackbar } from '@swarm/ui/swarm/Snackbar'
import TokenIcon from '@swarm/ui/swarm/TokenIcon'
import { format, fromUnixTime, getUnixTime } from 'date-fns'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Flex, Heading, Loader, Text } from 'rimble-ui'

import { XDotcContract } from 'src/contracts/XDotcContract'
import { OfferStatus } from 'src/shared/enums/offer-status'

import { useEditOfferPopup } from '../EditOffer'

import OfferActionIcons from './OfferActionIcons'

interface OfferRowProps {
  offer: NormalizedXOffer
  isDirectMode: boolean
  reload: RefetchXOffersFn
}

const MyOfferRow = ({ offer, isDirectMode, reload }: OfferRowProps) => {
  const { t } = useTranslation('otc')
  const [txLoading, setTxLoading] = useAsyncState<boolean>(false)
  const account = useAccount()
  const { addError } = useSnackbar()
  const { track } = useTransactionAlerts()
  const {
    open: openConfirmCancelOfferModal,
    close: closeConfirmCancelOfferModal,
    isOpen: isConfirmCancelOfferModalOpen,
  } = usePopupState(false)
  const { openEditOfferPopup } = useEditOfferPopup()
  const { depositAsset, withdrawalAsset } = offer
  const tokenIn = normalizeUSDCE(depositAsset)
  const tokenOut = normalizeUSDCE(withdrawalAsset)

  const handleCancelOffer = useCallback(async () => {
    closeConfirmCancelOfferModal()
    if (!account) {
      return
    }
    setTxLoading(true)
    try {
      const tx = await XDotcContract.cancelOffer(offer.id)

      await track(tx, {
        skipSubmit: true,
        confirm: { message: `Transaction completed successfully` },
      })

      await wait(4000)
      await reload()
    } catch (e) {
      addError(e as Error)
    } finally {
      setTxLoading(false)
    }
  }, [
    account,
    addError,
    closeConfirmCancelOfferModal,
    offer.id,
    reload,
    setTxLoading,
    track,
  ])

  const detectStatusOffer = () => {
    if (offer.isCompleted) {
      return OfferStatus.completed
    }

    if (offer.cancelled) {
      return OfferStatus.cancelled
    }

    const currentTimestamp = getUnixTime(Date.now())

    const isExpires = currentTimestamp > offer.expiresAt

    if (isExpires) {
      return OfferStatus.expired
    }

    const bigAmountIn = big(offer.amountIn)

    if (!bigAmountIn.eq(offer.availableAmount)) {
      return OfferStatus.partiallyFilled
    }

    if (bigAmountIn.eq(offer.availableAmount)) {
      return OfferStatus.notFilled
    }
    return ''
  }

  const expiresAt = format(fromUnixTime(offer.expiresAt), 'MMMM dd, yyyy HH:mm')
  const timelock = format(
    fromUnixTime(offer.timelockPeriod),
    'MMMM dd, yyyy HH:mm',
  )

  const timelocked = fromUnixTime(offer.timelockPeriod).getTime() > Date.now()
  const offerActionDisabled =
    txLoading || offer.isCompleted || offer.cancelled || timelocked

  return (
    <>
      <tr key={offer.id}>
        <td title={offer.id}>
          <TokenIcon
            width="20px"
            height="20px"
            symbol={tokenIn?.symbol}
            name={tokenIn?.name}
          />
          <TrendingFlat mx="5px" />
          <TokenIcon
            width="20px"
            height="20px"
            symbol={tokenOut?.symbol}
            name={tokenOut?.name}
          />
        </td>
        <Flex as="td" flexDirection="column">
          <Box>
            <Text.span lineHeight="24px">
              {prettifyBalance(offer.availableAmount)} {tokenIn.symbol}
            </Text.span>
          </Box>
        </Flex>
        <td>
          <Box>
            <Text.span lineHeight="24px">
              {`${prettifyBalance(
                !isDirectMode ? offer.price : safeDiv(1, offer.price),
              )} ${!isDirectMode ? tokenOut.symbol : tokenIn.symbol} / ${
                !isDirectMode ? tokenIn.symbol : tokenOut.symbol
              }`}
            </Text.span>
          </Box>
        </td>
        <td>
          {!offer.isFullType ? (
            <Text.span lineHeight="20px">{t('noMinimum')}</Text.span>
          ) : (
            <Text.span lineHeight="24px">
              {prettifyBalance(offer.amountIn)} {tokenIn.symbol}
            </Text.span>
          )}
        </td>
        {/* <td>
          {offer.isPrivate ? (
            <Text.span
              title={offer.specialAddresses.toString()}
              color="near-black"
            >
              {truncateStringInTheMiddle(offer.specialAddress)}
            </Text.span>
          ) : (
            t('anyone')
          )}
        </td> */}
        <td>
          <Text.span lineHeight="20px">
            {t(`statuses.${detectStatusOffer()}`)}
          </Text.span>
        </td>
        <td>
          <Text.span lineHeight="20px">{expiresAt}</Text.span>
        </td>
        <td>
          <Tooltip
            placement="top"
            message={
              timelocked ? t('timelockUntil', { timelockEnd: timelock }) : ''
            }
          >
            <Flex justifyContent="flex-end" marginLeft="auto">
              <SmartButton
                type="button"
                width="80px"
                height="28px"
                fontWeight="600"
                fontSize="14px"
                px={['4px', '16px']}
                mainColor={txLoading ? 'grey' : 'primary'}
                disabled={offerActionDisabled}
                onClick={() => openEditOfferPopup(offer)}
              >
                {t('edit')}
              </SmartButton>
            </Flex>
          </Tooltip>
        </td>
        <td>
          <Tooltip
            placement="top"
            message={
              timelocked ? t('timelockUntil', { timelockEnd: timelock }) : ''
            }
          >
            <Flex justifyContent="flex-end">
              <SmartButton
                type="button"
                height="28px"
                fontWeight="600"
                fontSize="14px"
                mr="16px"
                px={['4px', '16px']}
                mainColor={txLoading ? 'grey' : 'primary'}
                disabled={offerActionDisabled}
                onClick={openConfirmCancelOfferModal}
                loadingText={
                  <>
                    <Loader mr={2} color="white" />
                  </>
                }
              >
                {(txLoading && (
                  <>
                    <Loader mr={2} color="white" />
                    {t('loading')}
                  </>
                )) ||
                  t('cancel')}
              </SmartButton>
            </Flex>
          </Tooltip>
        </td>
        <td>
          <Flex justifyContent="flex-end">
            <OfferActionIcons disabled={offerActionDisabled} offer={offer} />
          </Flex>
        </td>
      </tr>
      {/* <Drawer
        anchor="right"
        open={isEditOfferPopupOpen}
        onClose={closeEditOfferPopup}
      >
        <EditOfferPopup
          closeDrawer={closeEditOfferPopup}
          reload={reload}
          offer={offer}
        />
      </Drawer> */}
      <Dialog
        isOpen={isConfirmCancelOfferModalOpen}
        onClose={closeConfirmCancelOfferModal}
        width={['100%', '600px']}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Box>
          <Heading as="h4" color="grey" fontSize={4} fontWeight={5} mt={0}>
            {t('cancelOffer')}
          </Heading>
          <Box mt={4} display="flex" flexDirection="row">
            <Box>
              <Text.span>{t('cancelOfferConfirmation')}</Text.span>
            </Box>
          </Box>
          <Flex mt={4}>
            <Button onClick={handleCancelOffer} fontWeight={4} mr={3}>
              {t('confirm')}
            </Button>
            <Button.Outline
              color="primary"
              onClick={closeConfirmCancelOfferModal}
              fontWeight={4}
              border="1.5px solid"
              borderColor="primary"
            >
              {t('back')}
            </Button.Outline>
          </Flex>
        </Box>
      </Dialog>
    </>
  )
}

export default MyOfferRow
