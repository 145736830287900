import usePopupState from '@swarm/core/hooks/state/usePopupState'
import { TokenType } from '@swarm/core/shared/enums'
import { compareTokensBy } from '@swarm/core/shared/utils/filters'
import { isNFT } from '@swarm/core/shared/utils/tokens'
import {
  balanceLoading,
  balancesLoading,
} from '@swarm/core/shared/utils/tokens/balance'
import { useAccount } from '@swarm/core/web3'
import { TokenSelectorAsset } from '@swarm/types/tokens'
import { Sx1155AssetType } from '@swarm/types/x-subgraph/graphql'
import Balance from '@swarm/ui/swarm/Balance'
import TokenSelectorModal from '@swarm/ui/swarm/TokenSelector/TokenSelectorModal'
import TokenSelectorView from '@swarm/ui/swarm/TokenSelector/TokenSelectorView'
import { TokenSelectorProps } from '@swarm/ui/swarm/TokenSelector/types'
import uniqBy from 'lodash/uniqBy'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Flex, Text } from 'rimble-ui'

import useHiddenTokens from 'src/hooks/useHiddenTokens'

import HoverableVisibilityIcon from './HoverableVisibilityIcon'
import TokenManagerActions, {
  FilterMode,
  FilterTokensType,
} from './TokenManagerActions'

const TokenSelectorManager = <
  T extends TokenSelectorAsset = TokenSelectorAsset,
>({
  tokens,
  onChange,
  selected,
  filter,
  loading = false,
  orderBy,
  orderDirection = 'asc',
  emptyValue,
  readonly = false,
  showTokenInfo = false,
  withoutPortal,
  View = TokenSelectorView,
  onSearch,
}: TokenSelectorProps<T>) => {
  const account = useAccount()
  const { t } = useTranslation('common', { keyPrefix: 'manageTokensActions' })
  const { isOpen, open, close } = usePopupState(false)
  const {
    hiddenTokens,
    toggleToken,
    clearHiddenTokens,
    checkTokenHidden,
    setHiddenTokens,
  } = useHiddenTokens()
  const [filterMode, setFilterMode] = useState<FilterMode>(
    FilterMode.filterTokens,
  )
  const [filterTokensType, setFilterTokensType] = useState<FilterTokensType>(
    FilterTokensType.ALL,
  )

  const isHidingMode = filterMode === FilterMode.hideTokens

  const toggleManageTokens = (mode: FilterMode) => {
    setFilterMode(mode)
  }

  const handleModalClose = () => {
    setFilterMode(FilterMode.filterTokens)
    onSearch?.('')
    close()
  }

  useEffect(() => {
    if (!isOpen) {
      setFilterMode(FilterMode.filterTokens)
    }
  }, [isOpen])

  const handleTokenSelection = useCallback(
    (token: T) => {
      close()
      onChange?.(token)
      onSearch?.('')
    },
    [close, onChange, onSearch],
  )

  const areBalancesLoading = useMemo(
    () => balancesLoading(tokens, account),
    [account, tokens],
  )

  const filteredVisibleTokens = useMemo(() => {
    const filterTokens = () => {
      if (filterTokensType === FilterTokensType.ALL) return tokens

      if (filterTokensType === FilterTokensType.CRYPTO) {
        return tokens.filter((token) => token.type === TokenType.erc20)
      }

      if (filterTokensType === FilterTokensType.STOCKS_BONDS) {
        return tokens.filter(
          (token) => token.rwaType === 'bond' || token.rwaType === 'stock',
        )
      }

      if (filterTokensType === FilterTokensType.GOLD) {
        return tokens.filter((token) => token.rwaType === Sx1155AssetType.Gold)
      }

      if (filterTokensType === FilterTokensType.NFTS) {
        return tokens.filter((token) => isNFT(token.type))
      }
    }
    const filteredTokens = filterTokens()

    const filterHiddenTokens = (tokensToFilter: T[]) =>
      tokensToFilter.filter((token) => !checkTokenHidden(token.id))

    if (isHidingMode) {
      const exceptHiddenTokens = filterHiddenTokens(tokens)
      return uniqBy([...exceptHiddenTokens, ...tokens], 'id') // Visible tokens first in the list
    }

    const exceptHiddenTokens = uniqBy(
      filterHiddenTokens(filteredTokens || tokens),
      'id',
    )

    if (orderBy) {
      return exceptHiddenTokens?.sort(
        compareTokensBy([orderBy, orderDirection]),
      )
    }

    return exceptHiddenTokens
  }, [
    isHidingMode,
    tokens,
    orderBy,
    filterTokensType,
    checkTokenHidden,
    orderDirection,
  ])

  const getTokenBadge = (token: T) =>
    isHidingMode ? (
      <HoverableVisibilityIcon
        visible={!checkTokenHidden(token.id)}
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation()
          toggleToken(token.id)
        }}
      />
    ) : (
      <Text.span
        fontWeight={2}
        color="grey"
        flexGrow={1}
        flexBasis="auto"
        textAlign="right"
      >
        <Balance
          wrapper={undefined}
          balance={token.balance ?? undefined}
          base={6}
          loading={balanceLoading({ balance: token.balance }, account)}
        />
      </Text.span>
    )

  return (
    <>
      <View
        tokens={tokens}
        onChange={onChange}
        selected={selected}
        loading={!selected && loading}
        emptyValue={emptyValue}
        readonly={readonly}
        onModalOpen={open}
        onTokenSelection={handleTokenSelection}
      />

      {isOpen && (
        <TokenSelectorModal
          badge={getTokenBadge}
          ActionComponent={
            <TokenManagerActions
              filterMode={filterMode}
              initialHiddenTokens={hiddenTokens}
              onChangeMode={toggleManageTokens}
              onUpdateHiddenTokens={setHiddenTokens}
              onUpdateTokensType={setFilterTokensType}
              onDeselectAll={clearHiddenTokens}
            />
          }
          BottomActionComponent={
            filterMode === 'filterTokens' ? (
              <Flex justifyContent="flex-end" width="100%">
                <Button.Text
                  onClick={() => toggleManageTokens(FilterMode.hideTokens)}
                  icon="Visibility"
                  fontSize="14px"
                  height="20px"
                >
                  {t('hideTokens')}
                </Button.Text>
              </Flex>
            ) : null
          }
          tokens={filteredVisibleTokens}
          isOpen={isOpen}
          onClose={handleModalClose}
          selected={selected}
          onSearch={onSearch}
          onSelection={handleTokenSelection}
          filter={filter}
          withoutPortal={withoutPortal}
          showTokenInfo={showTokenInfo}
          loading={loading || areBalancesLoading}
          disableSearching
        />
      )}
    </>
  )
}

export default memo(TokenSelectorManager)
