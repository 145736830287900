import { Box } from 'rimble-ui'
import styled from 'styled-components/macro'

export const StyledMenu = styled(Box)`
  position: absolute;
  top: 100%;
  left: -100%;
  display: block;
  width: 200px;
  border: solid 1px #e9eaed;
  border-radius: 10px;
  overflow: hidden;
  z-index: 100;
  background-color: white;
`

export const StyledOption = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 100%;
  left: 0;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors['near-black']};
  cursor: pointer;

  &:hover {
    background-color: #e8f2fc;
  }
`
