export const spamKeywords = [
  'visit',
  'claim',
  'official website',
  'swap',
  'airdrop',
  'voucher',
  'rewards',
  'reward',
  'invite link',
]

export const spamPatterns = [
  /http[s]?:\/\//,
  /www\./,
  /\.com/,
  /\.net/,
  /\.org/,
]
