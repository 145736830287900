import * as blotoutEdgeTagSdk from '@blotoutio/edgetag-sdk-js'
import capitalize from 'lodash/capitalize'

import config from '@core/config/index'
import { NetworkMap } from '@core/shared/consts'
import { BlotoutEvent, KycProvider } from '@core/shared/enums'
import { blotoutLocalStorage } from '@core/shared/localStorage/edgetagLocalStorage'
import { verify } from '@core/shared/utils/crypto'
import { getLastUsedNetworkId } from '@core/web3'

const { edgeTagPreferences } = config

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace blotout {
  const beforeCapturing = () =>
    verify(Boolean(blotoutLocalStorage.get()), 'Blotout is not enabled')

  const afterCapturing = (
    eventName: BlotoutEvent,
    _eventData?: Record<string, unknown>,
    options?: blotoutEdgeTagSdk.SendTag['options'],
  ) => {
    /**
     * You can opt-in during development using: REACT_APP_BLOTOUT_DEBUG
     * only if it is set `true` you will able to see the logs
     */
    if (edgeTagPreferences.debug) {
      // eslint-disable-next-line no-console
      console.warn(
        capitalize(eventName.replaceAll('_', ' ')),
        Object.fromEntries(
          Object.entries({ eventName, _eventData, options }).filter(
            ([, v]) => !!v,
          ),
        ),
      )
    }
  }

  /**
   * General method to emit an event to the blotout
   * @param eventName - name of the event, that will be used for filtering in the admin panel
   * @param _eventData - key-value pairs for any additional data
   * @param options - If you are capturing an event when page navigation is triggered, you should add the method beacon in options so that you will not lose data.
   */
  const capture = (
    eventName: BlotoutEvent,
    _eventData?: Record<string, unknown>,
    options?: blotoutEdgeTagSdk.SendTag['options'],
  ): void => {
    const networkName = NetworkMap.get(getLastUsedNetworkId())?.networkName
    const eventData = { ..._eventData, ...(networkName && { networkName }) }
    const providers = {
      blotoutCloud: true,
    }

    try {
      beforeCapturing()
      blotoutEdgeTagSdk.tag(eventName, eventData, providers, options)
      afterCapturing(eventName, eventData, options)
    } catch {
      // Ignore
    }
  }

  /**
   * @notice To send unique events we save the userId that was used for the previous mapId event
   */
  let previousUserId: string | null = null

  /**
   * The mapId method allows you to map external services to Blotout ID.
   * @param userId - user id from the Swarm API
   */
  export function mapId(userId: string): void {
    try {
      const providers = {
        blotoutCloud: true,
      }
      beforeCapturing()
      verify(Boolean(userId), 'User ID is not valid')
      verify(previousUserId !== userId, 'This userId is already mapped')

      // eslint-disable-next-line camelcase
      const eventData = { map_id: userId, map_provider: 'SWARM' }

      blotoutEdgeTagSdk.tag(BlotoutEvent.MAP_ID, eventData, providers)

      previousUserId = userId
    } catch {
      // Ignore
    }
  }

  export const captureWalletConnected = (
    walletLabel: string,
    address: string,
  ) => capture(BlotoutEvent.WALLET_CONNECTED, { walletLabel, address })

  export const captureSignMessage = (address: string) =>
    capture(BlotoutEvent.SIGNED_MESSAGE, { address })

  export const captureKYCStart = (kycProvider: KycProvider) =>
    capture(BlotoutEvent.KYC_START, { kycProvider })

  export const captureEmailVerified = () => capture(BlotoutEvent.EMAIL_VERIFIED)

  export const captureStartOnboarding = () =>
    capture(BlotoutEvent.START_ONBOARDING)

  export const captureAgreeTerms = (address: string) =>
    capture(BlotoutEvent.AGREE_TERMS, { address })

  export const capturePDFSigned = () => capture(BlotoutEvent.PDF_SIGNED)

  export const captureAdditionalAddressAdd = (additionalAddress: string) =>
    capture(BlotoutEvent.ADDITIONAL_ADDRESS_ADD, { additionalAddress })

  export const captureAdditionalAddressRemove = (additionalAddress: string) =>
    capture(BlotoutEvent.ADDITIONAL_ADDRESS_REMOVE, { additionalAddress })

  export const capturePaymentDetails = (confirmation: boolean) =>
    capture(BlotoutEvent.REFERENCE_PAYMENT_STATUS, { confirmation })

  export const captureEnableAsset = (assetSymbol: string) =>
    capture(BlotoutEvent.ENABLE_ASSET, { assetSymbol })

  export const captureDisableAsset = (assetSymbol: string) =>
    capture(BlotoutEvent.DISABLE_ASSET, { assetSymbol })

  export const captureSwap = (
    assetSymbolFrom: string,
    assetSymbolTo: string,
    valueFrom: number,
    valueTo: number,
  ) =>
    capture(BlotoutEvent.SWAP, {
      assetSymbolFrom,
      assetSymbolTo,
      valueFrom,
      valueTo,
    })

  export const captureAddLiquidity = (
    poolAddress: string,
    multiple: boolean,
    sptAmountOut: string,
    assetValues: Record<string, string | number>,
  ) =>
    capture(BlotoutEvent.ADD_LIQUIDITY, {
      poolAddress,
      multiple,
      sptAmountOut,
      ...assetValues,
    })

  export const captureRemoveLiquidity = (
    poolAddress: string,
    multiple: boolean,
    sptAmountIn: string,
    assetValues: Record<string, string | number>,
  ) =>
    capture(BlotoutEvent.REMOVE_LIQUIDITY, {
      poolAddress,
      multiple,
      sptAmountIn,
      ...assetValues,
    })

  export const captureClaimSmtRewards = (amount: number) =>
    capture(BlotoutEvent.CLAIM_SMT_REWARDS, { amount })

  export const captureUnwrapVSmt = (unwrappedAmount: number) =>
    capture(BlotoutEvent.UNWRAP_VSMT, { unwrappedAmount })

  export const captureBuyStakingNode = (
    stakingAsset: string,
    depositedAsset: string,
    amountIn: number,
    amountReceived: number,
  ) =>
    capture(BlotoutEvent.BUY_STAKING_NODE, {
      stakingAsset,
      depositedAsset,
      amountIn,
      amountReceived,
    })

  export const captureRedeemStakingNode = (
    stakingAsset: string,
    redeemAsset: string,
    amountOut: number,
    amountToReceive: number,
  ) =>
    capture(BlotoutEvent.REDEEM_STAKING_NODE, {
      stakingAsset,
      redeemAsset,
      amountOut,
      amountToReceive,
    })

  export const captureBuyStockToken = (
    stockToken: string,
    depositedAsset: string,
    amountIn: number,
    amountReceived: number,
  ) =>
    capture(BlotoutEvent.BUY_STOCK_TOKEN, {
      stockToken,
      depositedAsset,
      amountIn,
      amountReceived,
    })

  export const captureCreatedDotcOffer = (
    tokenInSymbol: string,
    tokenOutSymbol: string,
    offerType: 'PARTIAL' | 'FULL',
    isPrivate: boolean,
  ) =>
    capture(BlotoutEvent.DOTC_OFFER_CREATED, {
      tokenInSymbol,
      tokenOutSymbol,
      offerType,
      isPrivate,
    })

  export const captureTakenDotcOffer = (offerId: string, amountPaid: string) =>
    capture(BlotoutEvent.DOTC_OFFER_TAKEN, { offerId, amountPaid })

  export const captureUpdatedDotcOffer = (
    offerId: string,
    newAmount: string,
    newExpiresAt: number,
  ) =>
    capture(BlotoutEvent.DOTC_OFFER_UPDATED, {
      offerId,
      newAmount,
      newExpiresAt,
    })

  export const captureCanceledDotcOffer = (offerId: string) =>
    capture(BlotoutEvent.DOTC_OFFER_CANCELED, { offerId })
}

export default blotout
