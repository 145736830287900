import { DotcAsset } from '@swarm/types/tokens/dotc'
import { Sx1155AssetType, XOfferFilter } from '@swarm/types/x-subgraph/graphql'

import { isErc1155 } from '@core/shared/utils/tokens/filters'
import { unifyAddressToId } from '@core/web3/utils'

const getDepositAssetFilter = (token?: DotcAsset) =>
  token &&
  (isErc1155(token.type)
    ? {
        depositAsset_: { address: token.address },
      }
    : {
        depositAsset: unifyAddressToId(token.id),
      })

const getWithdrawalAssetFilter = (token?: DotcAsset) =>
  token &&
  (isErc1155(token.type)
    ? {
        withdrawalAsset_: { address: token.address },
      }
    : {
        withdrawalAsset: unifyAddressToId(token.id),
      })

const getGoldDepositAssetFilter = (token?: DotcAsset) => {
  if (!token)
    return {
      depositAsset_: {
        assetType: Sx1155AssetType.Gold,
      },
    }

  if (isErc1155(token.type)) {
    return {
      depositAsset_: {
        address: token.address,
        assetType: Sx1155AssetType.Gold,
      },
    }
  } else {
    return {
      depositAsset_: {
        id: unifyAddressToId(token.id),
        assetType: Sx1155AssetType.Gold,
      },
    }
  }
}

export const getAssetFilter = (
  tokenIn?: DotcAsset,
  tokenOut?: DotcAsset,
): XOfferFilter => {
  const depositAssetFilter = getDepositAssetFilter(tokenIn)

  const withdrawalAssetFilter = getWithdrawalAssetFilter(tokenOut)

  return { ...depositAssetFilter, ...withdrawalAssetFilter }
}

export const getGoldAssetFilter = (
  tokenIn?: DotcAsset,
  tokenOut?: DotcAsset,
): XOfferFilter => {
  const depositAssetFilter = getGoldDepositAssetFilter(tokenIn)

  const withdrawalAssetFilter = getWithdrawalAssetFilter(tokenOut)

  return { ...depositAssetFilter, ...withdrawalAssetFilter }
}
