import { BasicModalProps } from '@swarm/core/hooks/state/usePopupState'
import { generateExplorerUrl } from '@swarm/core/shared/utils'
import { InvestToken } from '@swarm/types/tokens/invest'
import Dialog from '@swarm/ui/presentational/Dialog/Dialog'
import Balance from '@swarm/ui/swarm/Balance'
import AddToMetaMask from '@swarm/ui/swarm/Buttons/AddToMetamask'
import Chip from '@swarm/ui/swarm/Buttons/Chip'
import SvgIcon from '@swarm/ui/swarm/SvgIcon'
import TokenIcon from '@swarm/ui/swarm/TokenIcon'
import Big from 'big.js'
import { providers } from 'ethers'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Button, Flex, Heading, Text } from 'rimble-ui'

export interface PurchaseConfirmationModalProps extends BasicModalProps {
  asset?: InvestToken
  tx?: providers.TransactionResponse
  amount?: Big
}

const PurchaseConfirmationModal = ({
  isOpen,
  onClose,
  asset,
  tx,
  amount,
}: PurchaseConfirmationModalProps) => {
  const { t } = useTranslation(['invest', 'popups', 'common', 'errors'])

  const explorerUrl = useMemo(
    () => tx && generateExplorerUrl({ type: 'tx', hash: tx.hash }),
    [tx],
  )

  if (!isOpen) {
    return null
  }

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      width={['100%', '550px']}
      minHeight="450px"
      maxHeight={['90%', '80%']}
      height="auto"
    >
      <Flex justifyContent="center" mb={3}>
        {asset?.kyaInformation?.image ? (
          <SvgIcon
            name={asset?.kyaInformation?.image}
            style={{
              flex: '0 0 70px',
              maxWidth: '70px',
            }}
            height="70px"
            external
          />
        ) : (
          <TokenIcon symbol={asset?.symbol} height="70px" maxWidth="70px" />
        )}
      </Flex>
      <Heading
        as="h4"
        fontSize={4}
        fontWeight={5}
        mt={0}
        mb="20px"
        color="primary"
        textAlign="center"
      >
        {t('invest:stockTokens.buyConfirmation.title', {
          token: asset?.symbol,
        })}
      </Heading>
      <Text textAlign="center" fontSize={3}>
        <Trans ns="invest" i18nKey="stockTokens.buyConfirmation.description">
          <Balance
            balance={amount}
            symbol={asset?.symbol}
            wrapper={Text.span}
            fontSize={3}
          />{' '}
          has been deposited to your address
        </Trans>
      </Text>
      <Flex justifyContent="center" style={{ gap: '8px' }} mt={2}>
        <Chip.Link href={explorerUrl} target="_blank">
          {t('invest:stockTokens.buyConfirmation.viewTx')}
        </Chip.Link>
        {asset && (
          <AddToMetaMask token={asset} logo={asset.kyaInformation?.image} />
        )}
      </Flex>
      <Text textAlign="center" fontSize={2} fontWeight={500} mt={2}>
        {t('invest:stockTokens.buyConfirmation.whatElse', {
          token: asset?.symbol,
        })}
      </Text>
      <ul>
        <li>
          <Trans
            ns="invest"
            i18nKey="stakeConfirmation.hold"
            values={{ token: asset?.symbol }}
          >
            <Text.span color="primary">Hold</Text.span>
            Earn yield by simply keeping SOLsn in your wallet.
          </Trans>
        </li>
        <li>
          <Trans ns="invest" i18nKey="stakeConfirmation.trade">
            <Text.span color="primary">Trade</Text.span>
            Swap or sell using our AMM or dOTC markets
          </Trans>
        </li>
      </ul>
      <Button.Outline
        onClick={onClose}
        color="primary"
        borderColor="primary"
        border="1.5px solid"
        alignSelf="center"
        width="230px"
        fontWeight={600}
        borderWidth="2px"
        flexShrink={0}
      >
        {t('invest:stockTokens.buyConfirmation.gotIt')}
      </Button.Outline>
    </Dialog>
  )
}

export default PurchaseConfirmationModal
