import { AbstractAsset, HasType } from '@swarm/types/tokens'

import { useNFTBalanceOf } from '@core/observables/nftBalanceOf'
import { useTokenBalanceOf } from '@core/observables/tokenBalanceOf'
import { isNFT as checkNFT, isLoading } from '@core/shared/utils/tokens'

type UseBalanceState = {
  balanceLoading: boolean
}

type UseBalanceReturn = [Big.Big | null | undefined, UseBalanceState]

const useBalance = (
  account?: string | null,
  asset?: AbstractAsset & HasType,
): UseBalanceReturn => {
  const isNFT = checkNFT(asset?.type)

  const tokenBalance = useTokenBalanceOf(isNFT ? null : account, asset?.id)
  const nftBalance = useNFTBalanceOf(
    !isNFT ? null : account,
    asset?.address,
    asset?.tokenId,
  )

  const loading = isNFT
    ? isLoading(nftBalance, account)
    : isLoading(tokenBalance, account)

  const balance = isNFT ? nftBalance : tokenBalance

  return [balance, { balanceLoading: loading }]
}

export default useBalance
