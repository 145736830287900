import HeaderActions from '@swarm/ui/swarm/HeaderActions'
import { useTranslation } from 'react-i18next'

import Invest from 'src/components/Invest'
import { InvestProvider } from 'src/components/Invest/InvestContext'
import InvestSubheader from 'src/components/Invest/InvestSubheader'
import Layout from 'src/components/Layout'

const InvestPage = () => {
  const { t } = useTranslation('invest')

  return (
    <InvestProvider>
      <Layout
        header={t('howToInvest')}
        subheader={<InvestSubheader />}
        scrollable
        headerActions={<HeaderActions />}
      >
        <Invest />
      </Layout>
    </InvestProvider>
  )
}

export default InvestPage
