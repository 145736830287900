import { ExtractProps } from '@swarm/types/props'
import { ExtendedNativeToken } from '@swarm/types/tokens'
import { InvestToken } from '@swarm/types/tokens/invest'
import SmartButton, {
  SmartButtonProps,
} from '@swarm/ui/swarm/Buttons/SmartButton'
import Big from 'big.js'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'rimble-ui'

import {
  CreateOfferPopupProvider,
  useCreateOfferPopup,
} from 'src/components/XDotc/CreateOffer'
import { useCreateOfferContext } from 'src/components/XDotc/CreateOffer/CreateOfferContext'

import { useInvestSecurityContext } from '../../InvestSecurityContext'

interface ExpandedInvestTokenActionsProps extends SmartButtonProps {
  showCreateOffer: boolean
  amount: number
  allowance?: Big | null
  tokenIn?: ExtendedNativeToken
  tokenOut?: InvestToken
}

const InvestActionButton = ({
  showCreateOffer,
  allowance,
  amount,
  tokenIn,
  tokenOut,
  onClick,
  ...props
}: ExpandedInvestTokenActionsProps & ExtractProps<typeof Button>) => {
  const { t } = useTranslation(['invest', 'common'])
  const { checkSecurityPermission } = useInvestSecurityContext()

  const { openCreateOfferPopup } = useCreateOfferPopup()
  const { setTokenPair } = useCreateOfferContext()

  const handleOpenPopup = useCallback(() => {
    setTokenPair({
      tokenIn,
      tokenOut,
    })
    openCreateOfferPopup()
  }, [openCreateOfferPopup, setTokenPair, tokenIn, tokenOut])

  const handleButtonClick = async () => {
    if (showCreateOffer) {
      handleOpenPopup()
    } else {
      const securityGranted = await checkSecurityPermission(tokenIn, tokenOut)
      if (!securityGranted) {
        return
      }

      await onClick?.()
    }
  }

  return showCreateOffer ? (
    <SmartButton {...props} onClick={handleButtonClick} disabled={false}>
      {t('otc:createOffer')}
    </SmartButton>
  ) : (
    <SmartButton {...props} onClick={handleButtonClick}>
      {allowance?.gte(amount)
        ? t('stockTokens.actions.own', {
            token: tokenOut?.symbol,
          })
        : t('common:token.enable', {
            token: tokenIn?.symbol,
          })}
    </SmartButton>
  )
}

const InvestActionButtonPopup = (
  props: ExpandedInvestTokenActionsProps & ExtractProps<typeof Button>,
) => {
  return (
    <CreateOfferPopupProvider>
      <InvestActionButton {...props} />
    </CreateOfferPopupProvider>
  )
}

export default InvestActionButtonPopup
