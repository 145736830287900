import useAsyncState from '@swarm/core/hooks/async/useAsyncState'
import useTransactionAlerts from '@swarm/core/hooks/i18n/useTransactionAlerts'
import { useSnackbar } from '@swarm/ui/swarm/Snackbar'
import { Address } from '@web3-onboard/core/dist/types'
import { TransactionReceipt } from 'alchemy-sdk'
import { BigSource } from 'big.js'
import { useCallback } from 'react'
import 'react-datepicker/dist/react-datepicker.css'

import { XDotcContract, XDotcContractToken } from 'src/contracts/XDotcContract'

type UseMakeOfferState = {
  txLoading: boolean
}

type MakeOfferFunction = (
  tokenIn: XDotcContractToken,
  tokenOut: XDotcContractToken,
  amountIn: BigSource,
  amountOut: BigSource,
  expiresAt: number,
  isFullType: boolean,
  specialAddresses: Address[],
  timelock: number,
  terms: string,
  commsLink: string,
) => Promise<TransactionReceipt | undefined>

type UseMakeOfferTokenReturn = [MakeOfferFunction, UseMakeOfferState]

const useMakeOffer = (): UseMakeOfferTokenReturn => {
  const { track } = useTransactionAlerts()
  const { addError } = useSnackbar()
  const [txLoading, setTxLoading] = useAsyncState(false)

  const makeOffer: MakeOfferFunction = useCallback(
    async (
      tokenIn,
      tokenOut,
      amountIn,
      amountOut,
      expiresAt,
      isFullType,
      specialAddresses,
      timelock,
      terms,
      commsLink,
    ) => {
      setTxLoading(true)
      try {
        const tx = await XDotcContract.makeOffer(
          tokenIn,
          tokenOut,
          amountIn,
          amountOut,
          expiresAt,
          isFullType,
          specialAddresses,
          timelock,
          terms,
          commsLink,
        )

        const receipt = await track(tx, {
          confirm: {
            secondaryMessage: 'Refresh shortly to see the latest offers.',
          },
        })

        return receipt
      } catch (e) {
        console.log(e)
        addError(e as Error)
      } finally {
        setTxLoading(false)
      }
    },
    [setTxLoading, track, addError],
  )

  return [makeOffer, { txLoading }]
}

export default useMakeOffer
