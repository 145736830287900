import { useEffect, useState } from 'react'

import { XGoldBundleContract } from '@core/contracts/XGoldBundle'
import { getCurrentConfig } from '@core/observables/configForNetwork'
import useTokenMetadata from '@core/services/alchemy/useTokenMetadata'
import NetworkFeature, {
  NetworkFeatureName,
} from '@core/services/networkFeatures'

const { xGoldBundleAddress } = getCurrentConfig()

const useGoldBundle = () => {
  const { token, loading: tokenLoading } = useTokenMetadata(xGoldBundleAddress)
  const [kya, setKya] = useState<string>()
  const [kyaLoading, setKyaLoading] = useState(false)

  useEffect(() => {
    const fetchKya = async () => {
      if (!NetworkFeature.checkSupported(NetworkFeatureName.xGoldToken)) return
      try {
        setKyaLoading(true)
        const instance = await XGoldBundleContract.getInstance()

        const tokenKya = await instance.contract?.tokenKya()
        setKya(tokenKya)
      } catch (e) {
        console.log(e)
      } finally {
        setKyaLoading(false)
      }
    }
    fetchKya()
  }, [])

  const loading = tokenLoading || kyaLoading

  return {
    token: loading ? null : { ...token, kya },
    loading,
  }
}

export default useGoldBundle
