import { getCurrentConfig } from '@core/observables/configForNetwork'
import { isSameEthereumAddress } from '@core/web3'

const { xGoldKgAddress, xGoldOzAddress } = getCurrentConfig()

export const isGoldKgByAddress = (address: string) =>
  isSameEthereumAddress(xGoldKgAddress, address)

export const isGoldOzByAddress = (address: string) =>
  isSameEthereumAddress(xGoldOzAddress, address)

export const isGoldByAddress = (address: string) =>
  isGoldKgByAddress(address) || isGoldOzByAddress(address)
