import AlertPanel from '@swarm/ui/swarm/AlertPanel'
import HeaderActions from '@swarm/ui/swarm/HeaderActions'
import { useTranslation } from 'react-i18next'
import { Flex } from 'rimble-ui'

import Layout from 'src/components/Layout'
import DotcSubheader from 'src/components/XDotc/DotcSubheader'
import DotcTabs from 'src/components/XDotc/DotcTabs'
import useSwitchNetworkBySearchParam from 'src/hooks/useSwitchNetworkBySearchParam'

const XDotcPage = () => {
  const { t } = useTranslation('otc')

  useSwitchNetworkBySearchParam()

  return (
    <Layout
      header={t('headerOffers')}
      subheader={<DotcSubheader />}
      scrollable
      headerActions={<HeaderActions smtWidget={false} burnSmt />}
    >
      <Flex
        width="100%"
        bg="background"
        flexDirection="column"
        p={[3, 3, 4]}
        flexGrow={1}
      >
        <AlertPanel promptPassport={false} />
        <DotcTabs />
      </Flex>
    </Layout>
  )
}

export default XDotcPage
