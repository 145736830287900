import React from 'react'
import { createRoot } from 'react-dom/client'

import App from './App'

import '@swarm/core/shared/i18n'
import '@swarm/ui/theme/fonts.css'
import '@swarm/ui/theme/styles.css'
import './config-overrides'

const domNode = document.getElementById('root') as HTMLElement
const root = createRoot(domNode)

root.render(
  <React.Suspense fallback={<div />}>
    <App />
  </React.Suspense>,
)
