import { Close } from '@rimble/icons'
import { SwarmBuyerBurner } from '@swarm/core/contracts/SwarmBuyerBurner'
import { useErc20 } from '@swarm/core/hooks/data/useErc20'
import { useSmt } from '@swarm/core/hooks/data/useSmt'
import useTransactionAlerts from '@swarm/core/hooks/i18n/useTransactionAlerts'
import { BasicModalProps } from '@swarm/core/hooks/state/usePopupState'
import { getCurrentConfig } from '@swarm/core/observables/configForNetwork'
import { useTokenBalanceOf } from '@swarm/core/observables/tokenBalanceOf'
import { prettifyBalance } from '@swarm/core/shared/utils'
import { useTranslation } from 'react-i18next'
import { Box, Flex, Heading, Text } from 'rimble-ui'

import Divider from '@ui/presentational/Divider'
import { Drawer } from '@ui/presentational/Drawer'
import SmartButton from '@ui/swarm/Buttons/SmartButton'
import RewardsIcon from '@ui/swarm/Icons/RewardsIcon'

const { swarmBuyerBurnerAddress, usdcAddress } = getCurrentConfig()

const BurnSmtPopup = ({ isOpen, onClose }: BasicModalProps) => {
  const { t } = useTranslation('popups')
  const { track } = useTransactionAlerts()

  const smt = useSmt()

  const usdcBalance = useTokenBalanceOf(swarmBuyerBurnerAddress, usdcAddress) // Big
  const usdc = useErc20(usdcAddress)

  const approxAmountToBurn = usdcBalance?.div(smt.exchangeRate ?? 1)

  const handleBurnSMT = async () => {
    if (!smt.address || !approxAmountToBurn) return

    try {
      const tx = await SwarmBuyerBurner.swapAndBurnSMT()

      await track(tx, {
        confirm: {
          secondaryMessage: 'You succesfully burned SMT tokens.',
        },
      })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('An error occurred while burning the smt token', e)
    }
  }

  const disabled = usdc.loading || smt.loading || !usdcBalance

  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose} elevation={0}>
      <Flex
        flexDirection="column"
        width={['100%', '500px']}
        justifyContent="flex-start"
        alignItems="center"
        p="28px"
      >
        <Box alignSelf="flex-end">
          <Close onClick={onClose} />
        </Box>

        <RewardsIcon />

        <Heading as="h4" fontSize="20px" fontWeight={700} m={0} my={2}>
          {t('burnSmtInfo.title')}
        </Heading>

        <Text.span fontSize="16px" color="grey" mb={3}>
          {t('burnSmtInfo.subtitle')}
        </Text.span>

        <Flex width="100%" justifyContent="space-between" my={2}>
          <Text.span fontSize="16px" color="grey">
            {t('burnSmtInfo.fees')}
          </Text.span>
          <Text.span fontWeight={700} fontSize="16px" color="black">
            {prettifyBalance(usdcBalance ?? 0)}
            {` ${usdc.symbol}`}
          </Text.span>
        </Flex>
        <Divider my={2} />

        <Flex width="100%" justifyContent="space-between" my={2}>
          <Text.span fontSize="16px" color="grey">
            {t('burnSmtInfo.approximateBurn')}
          </Text.span>
          <Text.span fontWeight={700} fontSize="16px" color="black">
            {prettifyBalance(approxAmountToBurn ?? 0)}
            {` ${smt.symbol}`}
          </Text.span>
        </Flex>
        <Divider my={2} />

        <Text.span alignSelf="flex-start" fontSize="16px" color="grey" my={2}>
          {t('burnSmtInfo.gasFees')}
        </Text.span>
        <Divider my={2} />

        <SmartButton
          loading={disabled}
          disabled={disabled}
          requireAccount
          width="100%"
          mt={3}
          onClick={handleBurnSMT}
        >
          {t('burnSmtInfo.burn')}
        </SmartButton>
      </Flex>
    </Drawer>
  )
}

export default BurnSmtPopup
