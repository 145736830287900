import useCombinedRefs from '@swarm/core/hooks/dom/useCombinedRefs'
import useInputValidity from '@swarm/core/hooks/dom/useInputValidity'
import { forwardRef, useMemo, useRef } from 'react'
import { Button, Flex } from 'rimble-ui'
import styled from 'styled-components/macro'

import MaxInput, {
  CustomNumericFormat,
  MaxInputProps,
  MaxInputViewProps,
} from '../../MaxInput'

const InputWrapper = styled(Flex)`
  flex: 1 1 180px;
  min-width: 80px;

  & > input {
    border: none;
    text-align: right;
    flex-grow: 1;
    min-width: 0;
    -moz-appearance: textfield;
    outline: none;
    width: 100%;
  }
`

const StyledAmountInput = styled.input`
  border: none;
  text-align: right;
  width: 50%;
  flex-grow: 1;

  &:focus {
    outline: none;
  }
`

const AmountInputView = forwardRef<HTMLInputElement, MaxInputViewProps>(
  (
    {
      onFocus,
      onBlur,
      onChange,
      disabled,
      onMaxClick,
      reportError,
      showMax = true,
      min,
      error,
      value,
      ...props
    }: MaxInputViewProps,
    forwardedRef,
  ) => {
    const innerRef = useRef(null)
    const ref = useCombinedRefs<HTMLInputElement>(innerRef, forwardedRef)

    const handleOnFocus = useInputValidity(
      ref,
      useMemo(
        () =>
          reportError
            ? {
                error,
                value: value?.toString(),
                onFocus,
              }
            : {},
        [error, onFocus, reportError, value],
      ),
    )

    return (
      <InputWrapper>
        <CustomNumericFormat
          customInput={StyledAmountInput}
          defaultValue={min}
          onFocus={handleOnFocus}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          disabled={disabled}
          ref={ref}
          {...props}
        />
        {showMax && (
          <Button.Text size="small" p={0} mx={2} onClick={onMaxClick}>
            MAX
          </Button.Text>
        )}
      </InputWrapper>
    )
  },
)

AmountInputView.displayName = 'StakingCardAmountInputView'

const AmountInput = ({ ...props }: Omit<MaxInputProps, 'View'>) => {
  return <MaxInput {...props} View={AmountInputView} />
}

export default AmountInput
