import { OwnedNft, OwnedToken } from 'alchemy-sdk'

import { spamKeywords, spamPatterns } from '@core/shared/consts/spam-tokens'

export const isAlchemyNFTType = (
  asset: Partial<OwnedNft>,
): asset is OwnedNft => {
  return (
    asset.contract !== undefined &&
    'address' in asset.contract &&
    'tokenId' in asset
  )
}

export const isAlchemyTokenType = (
  asset: Partial<OwnedToken>,
): asset is OwnedToken => {
  return 'decimals' in asset
}

export const testIsSpamAsset = (name?: string, symbol?: string) => {
  if (!name || !symbol) return true
  const _name = name.toLowerCase()
  const _symbol = symbol.toLowerCase()
  for (const keyword of spamKeywords) {
    if (_name.includes(keyword) || _symbol.includes(keyword)) {
      return true
    }
  }
  for (const pattern of spamPatterns) {
    if (pattern.test(_name) || pattern.test(_symbol)) {
      return true
    }
  }
  return false
}
