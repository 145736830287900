import { getNetworkIdByName } from '@swarm/core/shared/utils'
import {
  isNetworkSupported as checkNetworkSupported,
  getLastUsedNetworkId,
  switchNetwork,
  useReadyState,
} from '@swarm/core/web3'
import { useEffect } from 'react'
import { StringParam, useQueryParams } from 'use-query-params'

const useSwitchNetworkBySearchParam = () => {
  const [queryParams, setQueryParams] = useQueryParams({
    network: StringParam,
  })
  const networkSearchParam = queryParams.network
  const ready = useReadyState()

  useEffect(() => {
    const resetNetwork = () =>
      setQueryParams({ network: undefined }, 'replaceIn')

    const changeNetwork = async () => {
      if (ready && networkSearchParam) {
        const currentNetworkId = getLastUsedNetworkId()
        const desiredNetworkId = getNetworkIdByName(networkSearchParam)

        if (currentNetworkId === desiredNetworkId || !desiredNetworkId) {
          resetNetwork()
          return
        }

        const isNetworkSupported = checkNetworkSupported(desiredNetworkId)

        if (isNetworkSupported) {
          const success = await switchNetwork(desiredNetworkId)
          if (success) {
            resetNetwork()
          }
        }
      }
    }

    changeNetwork()
  }, [networkSearchParam, ready, setQueryParams])
}

export default useSwitchNetworkBySearchParam
