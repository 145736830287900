import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from 'react-share'
import { Box, Flex } from 'rimble-ui'

interface ShareButtonsProps {
  link: string
}

const ShareButtons = ({ link }: ShareButtonsProps) => {
  return (
    <Flex>
      <Box>
        <EmailShareButton url={link} subject={link}>
          <EmailIcon size={48} round />
        </EmailShareButton>
      </Box>
      <Box ml="16px">
        <FacebookShareButton url={link}>
          <FacebookIcon size={48} round />
        </FacebookShareButton>
      </Box>
      <Box ml="16px">
        <TwitterShareButton url={link}>
          <XIcon size={48} round />
        </TwitterShareButton>
      </Box>
      <Box ml="16px">
        <RedditShareButton url={link}>
          <RedditIcon size={48} round />
        </RedditShareButton>
      </Box>
      <Box ml="16px">
        <WhatsappShareButton url={link}>
          <WhatsappIcon size={48} round />
        </WhatsappShareButton>
      </Box>
    </Flex>
  )
}

export default ShareButtons
