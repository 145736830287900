import { Close } from '@rimble/icons'
import usePopupState from '@swarm/core/hooks/state/usePopupState'
import { ChildrenProps } from '@swarm/types'
import { Drawer } from '@swarm/ui/presentational/Drawer'
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Flex, Heading } from 'rimble-ui'

import { CreateOfferContextProvider } from './CreateOfferContext'
import CreateOfferForm from './CreateOfferForm'

const CREATE_OFFER_KEY = 'create-offer'

interface CreateOfferPopupProps {
  isOpen: boolean
  onClose: () => void
  children: ReactNode
}

const CreateOfferPopup = ({
  isOpen,
  onClose,
  children,
}: CreateOfferPopupProps) => {
  const { t } = useTranslation('otc')

  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose}>
      <Flex
        p={['16px', '24px']}
        flexDirection="column"
        width="500px"
        maxWidth={['100vw', 'calc(100vw - 304px)']}
        height="100vh"
        overflowY="auto"
      >
        <Flex alignItems="center" justifyContent="space-between" mb={3}>
          <Heading
            as="h3"
            fontSize={3}
            lineHeight="28px"
            fontWeight={5}
            color="grey"
            mt={0}
            mb={0}
          >
            {t('createNewOffer')}:
          </Heading>
          <Close fill="red" onClick={onClose} />
        </Flex>
        {children}
      </Flex>
    </Drawer>
  )
}

export const CreateOfferPopupContext = createContext<{
  openCreateOfferPopup: () => void
  closeCreateOfferPopup: () => void
}>({
  openCreateOfferPopup: () => {
    throw new Error(
      "Can't call openCreateOfferPopup outside of CreateOfferPopupProvider",
    )
  },
  closeCreateOfferPopup: () => {
    throw new Error(
      "Can't call closeCreateOfferPopup outside of CreateOfferPopupProvider",
    )
  },
})

export const CreateOfferPopupProvider = ({ children }: ChildrenProps) => {
  const { isOpen, open, close } = usePopupState(false)
  const history = useHistory()

  const openCreateOfferPopup = useCallback(() => {
    history.push({ ...history.location, hash: CREATE_OFFER_KEY })
  }, [history])

  const closeCreateOfferPopup = useCallback(() => {
    history.push({ ...history.location, hash: undefined })
    close()
  }, [close, history])

  useEffect(() => {
    const hash = history.location.hash?.substr(1)
    if (hash === CREATE_OFFER_KEY) {
      open()
    }
  }, [history.location.hash, open])

  const value = useMemo(() => {
    return { openCreateOfferPopup, closeCreateOfferPopup }
  }, [openCreateOfferPopup, closeCreateOfferPopup])

  return (
    <CreateOfferPopupContext.Provider value={value}>
      <CreateOfferContextProvider>
        {children}
        <CreateOfferPopup isOpen={isOpen} onClose={closeCreateOfferPopup}>
          <CreateOfferForm />
        </CreateOfferPopup>
      </CreateOfferContextProvider>
    </CreateOfferPopupContext.Provider>
  )
}

export const useCreateOfferPopup = () => useContext(CreateOfferPopupContext)
