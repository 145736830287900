import { InvestToken } from '@swarm/types/tokens/invest'
import Divider from '@swarm/ui/presentational/Divider'
import Loader from '@swarm/ui/presentational/Loader'
import { Box, Card, Flex, Heading, Text } from 'rimble-ui'
import {
  FlexBasisProps,
  FlexProps,
  MaxWidthProps,
  MinWidthProps,
  WidthProps,
} from 'styled-system'

import AssetTokenIcon from './AssetTokenIcon'

export interface AssetTokenCardProps
  extends FlexProps,
    WidthProps,
    MaxWidthProps,
    MinWidthProps,
    FlexBasisProps {
  token: InvestToken
  expanded?: boolean
  onExpand?: (tokenId: string) => void
  onCollapse?: () => void
  ExpandedCard?: JSX.Element
  CollapsedCard?: JSX.Element
}

const AssetTokenCard = ({
  token,
  expanded = false,
  ExpandedCard,
  CollapsedCard,
  ...props
}: AssetTokenCardProps) => {
  return (
    <Card p={0} borderRadius={1} {...props}>
      <Flex flexDirection="column" height="100%">
        <Flex p={3} justifyContent="space-between">
          <Box mr={3}>
            <Heading fontSize={5} my={0}>
              {token.symbol}
            </Heading>
            <Text.span color="dark-gray">{token.name}</Text.span>
          </Box>
          <Loader loading={token.kyaInformation === undefined} size="64px">
            <AssetTokenIcon token={token} />
          </Loader>
        </Flex>

        <Divider my={0} />
        {expanded ? ExpandedCard : CollapsedCard}
      </Flex>
    </Card>
  )
}

export default AssetTokenCard
