import { BasicModalProps } from '@swarm/core/hooks/state/usePopupState'
import { prettifyBalance } from '@swarm/core/shared/utils'
import { big } from '@swarm/core/shared/utils/helpers'
import { AbstractAsset } from '@swarm/types/tokens'
import Dialog from '@swarm/ui/presentational/Dialog/Dialog'
import LoaderButton from '@swarm/ui/swarm/Buttons/LoaderButton'
import TokenIcon from '@swarm/ui/swarm/TokenIcon'
import { BigSource } from 'big.js'
import { useTranslation } from 'react-i18next'
import { Box, Flex, Heading, Text } from 'rimble-ui'

import TakeOfferDetails from 'src/components/XDotc/TakeOffer/TakeOfferDetails'
import { Order } from 'src/hooks/useFullFillOrder'

export interface TakeBatchConfirmationModalProps extends BasicModalProps {
  amountIn?: BigSource
  amountOut?: BigSource
  tokenIn?: AbstractAsset
  tokenOut: AbstractAsset
  orders: Order[]
  onConfirm: () => Promise<void>
}

const TakeBatchConfirmationModal = ({
  amountIn,
  amountOut,
  tokenIn,
  tokenOut,
  isOpen,
  onClose,
  onConfirm,
  orders,
}: TakeBatchConfirmationModalProps) => {
  const { t } = useTranslation(['invest', 'popups', 'common', 'errors'])

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      width={['100%', '580px']}
      minHeight="350px"
      maxHeight={['90%', '80%']}
      height="auto"
    >
      <Flex justifyContent="center" mb={3}>
        <TokenIcon symbol={tokenOut?.symbol} height="70px" maxWidth="70px" />
      </Flex>
      <Heading
        as="h4"
        fontSize={4}
        fontWeight={5}
        maxWidth={380}
        mx="auto"
        mt={0}
        mb="20px"
        color="primary"
        textAlign="center"
      >
        {t('invest:takeBatchConfirmationModal.title', {
          amount1: prettifyBalance(amountOut || 0),
          amount2: prettifyBalance(amountIn || 0),
          symbol1: tokenOut?.symbol,
          symbol2: tokenIn?.symbol,
        })}
      </Heading>

      <Text fontWeight={5} mb={1}>
        {t('invest:takeBatchConfirmationModal.tableTitle', {
          token: tokenOut?.symbol,
        })}
      </Text>
      <Box
        border="solid 1px"
        borderColor="near-black"
        borderRadius="5px"
        px={3}
        py={2}
      >
        <TakeOfferDetails
          ordersDetails={orders.map((order) => {
            return {
              offer: order.offer,
              amountPaid: order.amount,
              amountIn: big(order.amount).div(order.offer.price),
            }
          })}
          hideExpandButton
        />
      </Box>
      <LoaderButton
        onClick={onConfirm}
        color="primary"
        alignSelf="center"
        width="230px"
        fontWeight={600}
        borderWidth="2px"
        flexShrink={0}
        mt={4}
      >
        {t('invest:takeBatchConfirmationModal.confirmation')}
      </LoaderButton>
    </Dialog>
  )
}

export default TakeBatchConfirmationModal
