import NetworkFeature, {
  NetworkFeatureName,
} from '@swarm/core/services/networkFeatures'
import Content from '@swarm/ui/presentational/Content'
import AlertPanel from '@swarm/ui/swarm/AlertPanel'
import match from 'conditional-expression'
import { useParams } from 'react-router'

import ComingSoonPlaceholder from './ComingSoonPlaceholder'
import InvestAssetsList from './InvestAssetsList'
import { useInvestContext } from './InvestContext'

const Invest = () => {
  const { category } = useParams<{
    category?: string
  }>()

  const { investAssets, stockTokens, bonds, indexTokens } = useInvestContext()

  const isInvestAssetsSupported = NetworkFeature.checkSupported(
    NetworkFeatureName.investAssets,
  )

  return (
    <Content bg="background">
      <AlertPanel promptPassport={false} />
      {!isInvestAssetsSupported && <ComingSoonPlaceholder />}
      {isInvestAssetsSupported &&
        match(category)
          .equals('stock-tokens')
          .then(<InvestAssetsList tokens={stockTokens} />)
          .equals('bonds')
          .then(<InvestAssetsList tokens={bonds} />)
          .equals('index-tokens')
          .then(<InvestAssetsList tokens={indexTokens} />)
          .else(<InvestAssetsList tokens={investAssets} />)}
    </Content>
  )
}

export default Invest
